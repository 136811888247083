import React from "react";
import {
  updateCompanyLS,
  updateActiveCompanyLS,
  platformNotifMessages,
  CustomField
} from "tools/commonFunctions";

import { dataService } from "tools/dataService/dataService";
import UserContextInfo from "tools/userContextInfo";
import * as BackEndConsts from "tools/constants/backEndConstants";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Label,
  Button,
  Form
} from "reactstrap";

import { trackPromise } from "react-promise-tracker";
import { Formik } from 'formik';
import * as Yup from 'yup';

class CompanyData extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.i18n.t;

    this.state = {
      userRolePlatCreation: UserContextInfo.userRolePlatformCreation(),
      companyActivationStatus: UserContextInfo.activeCompanyActivation(),
      deleteUserModal: false,
      disableRequestActButton: true,
      companyId: this.props.companyId,
      companyName: this.props.companyName,
      companyAddress: this.props.companyAddress,
      companyZipCode: this.props.companyZipCode,
      companyCity: this.props.companyCity,
      companyCountryName: this.props.companyCountryName,
      companyCountryId: this.props.companyCountryId
    }
  };

  componentDidMount() {
    this.handleCountries();
  }

  handleCountries() {
    trackPromise(
      dataService.queries.getCountries().then(
        (countriesresponse) => {
          this.setState({ countryIds: countriesresponse });
        }
      )
    );
  };

  handleCountryDropdown = (selectedOption) => {
    this.formik.setFieldValue("companyCountryId", selectedOption.id);
    this.formik.setFieldValue("companyCountryName", selectedOption.name);
  };

  handleCompanyCreation(
    companyName,
    companyAddress,
    companyZipCode,
    companyCity,
    companyCountryId) {
    trackPromise(
      dataService.mutations.createCompany(
        companyName,
        companyAddress,
        companyZipCode,
        companyCity,
        companyCountryId
      ).then(
        (backEndResponseData) => {
          if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            updateCompanyLS(true, backEndResponseData.company, backEndResponseData.company.id)
            updateActiveCompanyLS(backEndResponseData.company)
            platformNotifMessages(
              backEndResponseData.response.messageinfo.messagelevel,
              backEndResponseData.response.messageinfo.messagetitle,
              backEndResponseData.response.messageinfo.message,
              backEndResponseData.company.name
            );
          } else return null;
        }
      ).then(
        () => {
          this.props.handleUpdateCompanyList(true);
        }
      )
    )
  };

  handleFormSubmit = (values, formikBag) => {
    let company = [];

    company.push({
      companyName: values.companyName ? values.companyName : '-',
      companyAddress: values.companyAddress ? values.companyAddress : '-',
      companyZipCode: values.companyZipCode ? values.companyZipCode : '-',
      companyCity: values.companyCity ? values.companyCity : '-'
    });

    this.setState({
      company
    });

    (this.state.companyId === null || typeof this.state.companyId !== 'string') ?
      this.handleCompanyCreation(
        values.companyName,
        values.companyAddress,
        values.companyZipCode,
        values.companyCity,
        values.companyCountryId,
      )
      : this.updateData(
        this.state.companyId,
        values.companyName,
        values.companyAddress,
        values.companyZipCode,
        values.companyCity,
        values.companyCountryId,
        formikBag
      )
  };

  updateData(
    companyId,
    companyName,
    companyAddress,
    companyZipCode,
    companyCity,
    companyCountryId,
    formikBag) {
    trackPromise(
      dataService.mutations.updateCompany(
        companyId,
        companyName,
        companyAddress,
        companyZipCode,
        companyCity,
        companyCountryId
      ).then(
        (backEndResponseData) => {
          if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            // Update Formik form values
            formikBag.setValues({
              companyName: backEndResponseData.company.name,
              companyAddress: backEndResponseData.company.address,
              companyZipCode: backEndResponseData.company.zipcode,
              companyCity: backEndResponseData.company.city,
              companyCountryId: backEndResponseData.company.country.id
            });
            this.setState({
              userRolePlatCreation: backEndResponseData.company.security.k8create,
              companyActivationStatus: backEndResponseData.company.activation
            });
  
            updateActiveCompanyLS(backEndResponseData.company)
            platformNotifMessages(
              backEndResponseData.response.messageinfo.messagelevel,
              backEndResponseData.response.messageinfo.messagetitle,
              backEndResponseData.response.messageinfo.message,
              this.state.companyName
            );
          } else return null;
        }
      )
    )
  };

  getValidationSchema = () => {
    return Yup.object().shape({
      companyName: Yup.string().required(this.t("signup.signupRegisterForm.formCompany.requiredMessage")),
      companyAddress: Yup.string().required(this.t("userProfile.companyData.companyForm.address.requiredMessage")),
      companyZipCode: Yup.string().required(this.t("userProfile.companyData.companyForm.zipCode.requiredMessage")),
      companyCity: Yup.string().required(this.t("userProfile.companyData.companyForm.city.requiredMessage")),
      companyCountryId: Yup.number().required(this.t("userProfile.companyData.companyForm.country.requiredMessage"))
    });
  }

  render() {
    const t = this.props.i18n.t;
    const initialValues = {
      companyName: this.state.companyName || '',
      companyAddress: this.state.companyAddress || '',
      companyZipCode: this.state.companyZipCode || '',
      companyCity: this.state.companyCity || '',
      companyCountryId: this.state.companyCountryId || ''
    };

    return (
      <>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                <i className={this.props.cardIcon} />
                {this.props.cardTitle}
              </CardTitle>
            </CardHeader>
            <CardBody className="author">
              <div className="block block-one" />
              <Formik
                initialValues={initialValues}
                validationSchema={this.getValidationSchema()}
                onSubmit={(values, formikBag) => {
                  this.handleFormSubmit(values, formikBag);
                  formikBag.resetForm(); // To clean up the form values after submitting
                }}
                enableReinitialize
                innerRef={(formik) => (this.formik = formik)} >
                {({ handleSubmit, ...formikProps }) => (
                  <Form onSubmit={handleSubmit}>
                    <Col md="12">
                      <Row>
                        <Col md="6">
                          <Label htmlFor={'companyname-' + this.state.companyId}>{t("kubernetesPlatform.tableCreation.name")}</Label>
                          <CustomField
                            type="text"
                            id={'companyname-' + this.state.companyId}
                            name="companyName"
                            className="form-control"
                            placeholder={t("signup.signupRegisterForm.formCompany.placeHolder")} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Label htmlFor={'companyaddress-' + this.state.companyId}>{t("userProfile.companyData.companyForm.address.label")}</Label>
                          <CustomField
                            type="text"
                            id={'companyaddress-' + this.state.companyId}
                            name="companyAddress"
                            className="form-control"
                            placeholder={t("userProfile.companyData.companyForm.address.placeHolder")} />
                        </Col>
                        <Col md="6">
                          <Label htmlFor={'companyzipcode-' + this.state.companyId}>{t("userProfile.companyData.companyForm.zipCode.label")}</Label>
                          <CustomField
                            type="text"
                            id={'companyzipcode-' + this.state.companyId}
                            name="companyZipCode"
                            className="form-control"
                            placeholder={t("userProfile.companyData.companyForm.zipCode.placeHolder")} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Label htmlFor={'companycity-' + this.state.companyId}>{t("userProfile.companyData.companyForm.city.label")}</Label>
                          <CustomField
                            type="text"
                            id={'companycity-' + this.state.companyId}
                            name="companyCity"
                            className="form-control"
                            placeholder={t("userProfile.companyData.companyForm.city.placeHolder")} />
                        </Col>
                        <Col md="6">
                          <Label htmlFor={'companycountry-' + this.state.companyId}>{t("userProfile.companyData.companyForm.country.label")}</Label>
                          <CustomField
                            as="select"
                            id={'companycountry-' + this.state.companyId}
                            name="companyCountryId"
                            className="dropdown-wrapper form-control"
                            onChange={(e) => this.handleCountryDropdown({
                              id: e.target.value,
                              name: e.target.options[e.target.selectedIndex].text
                            })}>
                            <option value="" disabled>
                              {t("userProfile.companyData.companyForm.country.placeHolder")}
                            </option>
                            {this.state.countryIds && this.state.countryIds.map(country => (
                              <option key={country.id} value={country.id}>{country.name}</option>
                            ))}
                          </CustomField>
                        </Col>
                      </Row>
                      {UserContextInfo.userRoleAccountCreation() === true &&
                        <Col md="auto" className="elem-submit-btn">
                          <Button
                            disabled={!formikProps.isValid || !formikProps.dirty}
                            className="form-btn align-right"
                            type="submit">
                            {this.props.submitButtonCaption
                              ? t("common.update")
                              : t("userProfile.companyData.create")}
                          </Button>
                        </Col>
                      }
                    </Col>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default CompanyData;