import React from "react";
import UserContextInfo from "tools/userContextInfo";
import GenericModal from "components/Modal/GenericModal";
import * as BackEndConsts from "tools/constants/backEndConstants";
import {
  handleDate,
  handleInputChange,
  CustomField,
  handleCopyToClipboard
} from "tools/commonFunctions";
import { Row, Col, Label, Button, Collapse } from "reactstrap";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

export default class AccessSetup extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      accordionOpened: false,
      tlsVerify: !this.props.skipTLSVerify,
      kubeAccessToken: "",
      clientCRT: "",
      caCRT: "",
      buttonDisabled: false,
      stepOk: this.props.kubeConnectStatus === BackEndConsts.PLATFORM_CONNECTION_STATUS_CONNECTED
        ? true
        : false,
      isMinikube: this.props.kubernetesPlatform.infraprovider.id === BackEndConsts.INFRAPROVIDER_MINIKUBE
        ? true
        : false,
      authMode: this.props.authMode
    }
  };

  async componentDidMount() {
    const t = this.t;

    this.setState({
      clientCRT: this.props.kubernetesPlatform.k8certs[0].clientcrt,
      caCRT: this.props.kubernetesPlatform.k8certs[0].cacrt,
      certExpirationDate: handleDate(this.props.kubernetesPlatform.k8certs[0].expirationdate)
        ? handleDate(this.props.kubernetesPlatform.k8certs[0].expirationdate).dateFormatted
        : "",
      daysToExpire: handleDate(this.props.kubernetesPlatform.k8certs[0].expirationdate)
        ? handleDate(this.props.kubernetesPlatform.k8certs[0].expirationdate).periodDiff
        : "",
      certExpirationStatus: handleDate(this.props.kubernetesPlatform.k8certs[0].expirationdate)
        ? handleDate(this.props.kubernetesPlatform.k8certs[0].expirationdate).expirationStatus
        : t("common.unknown")
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.kubeConnectStatus !== prevProps.kubeConnectStatus) {
      this.setState({
        stepOk: !this.state.stepOk
      });
    } else return null
  }

  csrRequest = () => {
    const t = this.t;

    this.setState(prevState => ({
      modalTitleText: t("kubernetesSettings.accessSetup.authenticationSettings.csrModal.csrTitle"),
      modalBodyText: t("kubernetesSettings.accessSetup.authenticationSettings.csrModal.copyUseCSR"),
      // modalBodyLink: (<a target="_blank" rel="noopener noreferrer" href={t("kubernetesSettings.accessSetup.authenticationSettings.csrModal.linkDocumentation.linkURL")}>{t("common.documentation")}</a>),
      modalAcceptBtn: t("common.accept"),
      modalCancelBtn: t("common.close"),
      csrModal: !prevState.csrModal
    }));
  }

  toggleAccessSection = (e) => {
    this.setState({
      accordionOpened: !this.state.accordionOpened
    })
  }

  toggleModal = (e) => {
    if (e) {
      e.persist();

      let name = e.target.name;
      let state = e.target.value;

      this.setState({
        [name]: state === "true" ? false : true,
        csrModal: !this.state.csrModal
      });
    } else {
      this.setState({
        csrModal: !this.state.csrModal
      });
    }
  }

  handleInputChangeLoc = (e) => {
    let inputResponse = handleInputChange(e)

    this.setState({
      [inputResponse.name]: inputResponse.value
    })
  };

  static ipToNumber(ip) {
    return ip.split('.').reduce((ipNumber, octet) => {
      return (ipNumber << 8) + parseInt(octet, 10);
    }, 0);
  }

  getValidationSchema = (isMinikube, authMode, isTLSVerify) => {
    const validations = {
      kubernetesClusterAddress: Yup.string()
        .required(this.t("kubernetesSettings.accessSetup.connectionSettings.kubernetesCluster.requiredMessage"))
    };

    if (isTLSVerify) {
      validations.caCRT = Yup.string()
        .matches(/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/, this.t("kubernetesSettings.accessSetup.authenticationSettings.caCertificate.errorMessage"))
        .required(this.t("kubernetesSettings.accessSetup.authenticationSettings.caCertificate.requiredMessage"))
    };
    
    if (authMode === "CERTIFICATE") {
      validations.clientCRT = Yup.string()
        .matches(/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/, this.t("kubernetesSettings.accessSetup.authenticationSettings.lainotikCertificate.requiredMessage"))
        .required(this.t("kubernetesSettings.accessSetup.authenticationSettings.lainotikCertificate.requiredMessage"))
    };

    if (authMode === "TOKEN") {
      validations.kubeAccessToken = Yup.string()
        .required(this.t("kubernetesSettings.accessSetup.authenticationSettings.kubeAccessToken.requiredMessage"))
    };

    if (isMinikube) {
      validations.extIPRangeStart = Yup.string()
        .matches(
          /\b(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b/,
          this.t("kubernetesSettings.accessSetup.connectionSettings.extIPRangeStart.errorMessage")
        )
        .required(this.t("kubernetesSettings.accessSetup.connectionSettings.extIPRangeStart.requiredMessage"));
      validations.extIPRangeEnd = Yup.string()
        .matches(
          /\b(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b/,
          this.t("kubernetesSettings.accessSetup.connectionSettings.extIPRangeEnd.errorMessage")
        )
        .required(this.t("kubernetesSettings.accessSetup.connectionSettings.extIPRangeEnd.requiredMessage"))
        .test('is-greater', this.t("kubernetesSettings.accessSetup.connectionSettings.extIPRangeEnd.greaterMessage"), function (value) {
          const { extIPRangeStart } = this.parent;
          return AccessSetup.ipToNumber(value) > AccessSetup.ipToNumber(extIPRangeStart);
        });
    }
    return Yup.object().shape(validations);
  }

  handleAccessMethod = () => {
    if (this.state.isCertificate) {
      return (
        console.log("Access Method: ", "Certificate")
      )
    } else return (
      console.log("Access Method: ", "Token")
    )
  }

  renderAccessMethod = (formikProps) => {
    const { authMode } = this.state;
    const t = this.props.t;
    if (authMode === "CERTIFICATE") {
      return (
        <>
          <Row>
            <Col sm="12">
              <div className="wrapper-subsection-control">
                <Button
                  name="csrModal"
                  className="info-wrapper elem-submit-btn"
                  disabled={this.props.fieldDisabled && this.props.healthCheckClass !== "notrunning"}
                  onClick={(e) => { this.csrRequest(e) }}>
                  <i name="csrModal" className="icon-info" />
                  {t("kubernetesSettings.accessSetup.authenticationSettings.csrLinkBtn")}
                </Button>
                {this.state.csrModal ?
                  <GenericModal
                    t={t}
                    id={this.props.kubernetesPlatform.id}
                    name="csrModal"
                    toggleModal={(e) => { this.toggleModal(e) }}
                    acceptOnClick={this.state.toggleModal}
                    modalTitleText={this.state.modalTitleText}
                    modalBodyText={this.state.modalBodyText}
                    modalBodyLink={this.state.modalBodyLink}
                    modalExtraText={this.props.clientCSR}
                    modalAcceptBtn={this.state.modalAcceptBtn} /> :
                  null
                }
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Row className="no-gutters">
                <Col sm="10">
                  <Label htmlFor={'clientcrt-' + this.props.kubernetesPlatform.id} sm="auto">{t("kubernetesSettings.accessSetup.authenticationSettings.lainotikCertificate.label")}</Label>
                  <CustomField
                    type="text"
                    id={'clientcrt-' + this.props.kubernetesPlatform.id}
                    name="clientCRT"
                    className="form-control"
                    disabled={this.props.fieldDisabled && this.props.healthCheckClass !== "notrunning"}
                    onChange={(e) => { this.handleInputChangeLoc(e) }}
                    placeholder={t("kubernetesSettings.accessSetup.authenticationSettings.lainotikCertificate.placeholder")} />
                </Col>
                <Col sm="2" className="wrapper-subsection-control">
                  <i className="icon-copy"
                    onClick={() => handleCopyToClipboard('clientcrt-' + this.props.kubernetesPlatform.id)}></i>
                </Col>
              </Row>
            </Col>
            {formikProps.values.tlsVerify &&
              this.rendercaCRT()
            }
          </Row>
          {this.props.kubeDemo === false
            && UserContextInfo.userRolePlatformCreation()
            && UserContextInfo.activeCompanyActivation() === "ACTIVATED" &&
            <Row>
              <Col sm="6">
                <div className={`cert-info-wrapper ${this.state.certExpirationStatus}`}>
                  <p className="cert-info-date">
                    <i name="certInfoIcon"
                      className={this.state.certExpirationStatus === "success"
                        ? "icon-info"
                        : "icon-alert"} /> {t("kubernetesSettings.accessSetup.authenticationSettings.certificateExpDate.label")} {
                      this.state.certExpirationDate
                        ? this.state.certExpirationDate
                        : " " + t("kubernetesSettings.accessSetup.authenticationSettings.certificateExpDate.noCertDetected")}</p>
                  <p className="cert-info-message">
                    {handleDate(this.props.kubernetesPlatform.k8certs[0].expirationdate)
                      ? handleDate(this.props.kubernetesPlatform.k8certs[0].expirationdate).expirationMessage
                      : ""}
                  </p>
                </div>
              </Col>
            </Row>
          }
        </>
      );
    } else if (authMode === "TOKEN") {
      return (
        <Row>
          <Col sm="6">
            <Row className="no-gutters">
              <Col sm="10">
                <Label htmlFor={'kubeaccesstoken-' + this.props.kubernetesPlatform.id} sm="auto">{t("kubernetesSettings.accessSetup.authenticationSettings.kubeAccessToken.label")}</Label>
                <CustomField
                  type="text"
                  id={'kubeaccesstoken-' + this.props.kubernetesPlatform.id}
                  name="kubeAccessToken"
                  sm="12"
                  className="form-control"
                  disabled={this.props.fieldDisabled && this.props.healthCheckClass !== "notrunning"}
                  onChange={(e) => { this.handleInputChangeLoc(e) }}
                  placeholder={t("kubernetesSettings.accessSetup.authenticationSettings.kubeAccessToken.placeholder")} />
              </Col>
              <Col sm="2" className="wrapper-subsection-control">
                <i className="icon-copy"
                  onClick={() => handleCopyToClipboard('kubeaccesstoken-' + this.props.kubernetesPlatform.id)}></i>
              </Col>
            </Row>
          </Col>
          {formikProps.values.tlsVerify &&
            this.rendercaCRT()
          }
        </Row>
      );
    }
    return null;
  }

  handleIPRangeRender = () => {
    return (
      <>
        <Col sm="6">
          <Col sm="12">
            <Row>
              <Col sm="6">
                <Label htmlFor={'extiprangestart-' + this.props.kubernetesPlatform.id} sm="auto">{this.t("kubernetesSettings.accessSetup.connectionSettings.extIPRangeStart.label")}</Label>
                <CustomField
                  type="text"
                  id={'extiprangestart-' + this.props.kubernetesPlatform.id}
                  name="extIPRangeStart"
                  className="form-control"
                  disabled={this.props.fieldDisabled}
                  onChange={(e) => { this.handleInputChangeLoc(e) }}
                  placeholder={this.t("kubernetesSettings.accessSetup.connectionSettings.extIPRangeStart.placeHolder")} />
              </Col>
              <Col sm="6">
                <Label htmlFor={'extiprangeend-' + this.props.kubernetesPlatform.id} sm="auto">{this.t("kubernetesSettings.accessSetup.connectionSettings.extIPRangeEnd.label")}</Label>
                <CustomField
                  type="text"
                  id={'extiprangeend-' + this.props.kubernetesPlatform.id}
                  name="extIPRangeEnd"
                  className="form-control"
                  disabled={this.props.fieldDisabled}
                  onChange={(e) => { this.handleInputChangeLoc(e) }}
                  placeholder={this.t("kubernetesSettings.accessSetup.connectionSettings.extIPRangeEnd.placeHolder")} />
              </Col>
            </Row>
          </Col>
        </Col>
      </>
    )
  }

  rendercaCRT = () => {
    return (
      <>
        <Col sm="6">
          <Row className="no-gutters">
            <Col sm="10">
              <Label htmlFor={'cacrt-' + this.props.kubernetesPlatform.id} sm="auto">{this.t("kubernetesSettings.accessSetup.authenticationSettings.caCertificate.label")}</Label>
              <CustomField
                type="text"
                id={'cacrt-' + this.props.kubernetesPlatform.id}
                name="caCRT"
                className="form-control"
                disabled={this.props.fieldDisabled && this.props.healthCheckClass !== "notrunning"}
                onChange={(e) => { this.handleInputChangeLoc(e) }}
                placeholder={this.t("kubernetesSettings.accessSetup.authenticationSettings.caCertificate.placeholder")} />
            </Col>
            <Col sm="2" className="wrapper-subsection-control">
              <i className="icon-copy"
                onClick={() => handleCopyToClipboard('cacrt-' + this.props.kubernetesPlatform.id)}></i>
            </Col>
          </Row>
        </Col>
      </>
    )
  }

  render() {
    const t = this.t;

    return (
      <Formik
        initialValues={{
          kubernetesClusterAddress: this.props.kubernetesClusterAddress || '',
          kubernetesClusterAddress: this.props.kubernetesClusterAddress,
          extIPRangeStart: this.props.kubernetesPlatform.externaliprangestart,
          extIPRangeEnd: this.props.kubernetesPlatform.externaliprangeend,
          authMode: this.props.authMode || "TOKEN",
          clientCRT: this.props.clientCRT || '',
          caCRT: this.props.caCRT || '',
          kubeAccessToken: this.props.kubeAccessToken || '',
          tlsVerify: this.state.tlsVerify || false
        }}
        validationSchema={this.getValidationSchema(
          this.state.isMinikube,
          this.state.authMode,
          this.state.tlsVerify)}
        onSubmit={(values) => {
          this.props.handlePlatformUpdate(
            false,
            values.kubernetesClusterAddress,
            values.clientCRT,
            values.caCRT,
            values.extIPRangeStart,
            values.extIPRangeEnd,
            values.kubeAccessToken,
            values.authMode,
            !values.tlsVerify
          )
        }}>
        {(formikProps) => (
          <Form>
            <Row className="sub-section-header"
              id={'access-' + this.props.kubernetesPlatform.id}
              onClick={(e) => {
                this.toggleAccessSection(e)
              }}>
              <Col sm="6">
                <h4>{t("kubernetesSettings.overviewSettings.access")}</h4>
              </Col>
              <Col sm="6" className="sub-section-btn">
                <i className={`elem-settings-btn ${this.state.accordionOpened ? "icon-up-pike expanded" : "icon-down-pike"}`}></i>
              </Col>
            </Row>
            <Collapse
              isOpen={this.state.accordionOpened}
              className="sub-section-body">
              <Row>
                <Col sm="12">
                  <div className="highlighted-section">
                    <section className="highlighted-sub-section">
                      <h5>{t("kubernetesSettings.accessSetup.connectionSettings.title")}</h5>
                      <Row>
                        <Col sm="6">
                          <Row>
                            <Col sm="12">
                              <Label htmlFor={'kubernetesclusteraddress-' + this.props.kubernetesPlatform.id} sm="auto">{t("kubernetesSettings.accessSetup.connectionSettings.kubernetesCluster.label")}</Label>
                              <CustomField
                                type="text"
                                id={'kubernetesclusteraddress-' + this.props.kubernetesPlatform.id}
                                name="kubernetesClusterAddress"
                                sm="auto"
                                className="form-control"
                                onChange={(e) => { this.handleInputChangeLoc(e) }}
                                disabled={this.props.fieldDisabled && this.props.healthCheckClass !== "notrunning"}
                                placeholder={t("kubernetesSettings.accessSetup.connectionSettings.kubernetesCluster.placeholder")} />
                            </Col>
                          </Row>
                        </Col>
                        {this.state.isMinikube &&
                          this.handleIPRangeRender()
                        }
                      </Row>
                    </section>
                    <section className="highlighted-sub-section">
                      <h5>{t("kubernetesSettings.accessSetup.authenticationSettings.title")}</h5>
                      <Row>
                        <Col sm="6">
                          <Row>
                            <Col sm="10">
                              <Label htmlFor={'authmodedropdown-' + this.props.kubernetesPlatform.id} sm="auto">{t("kubernetesSettings.accessSetup.authenticationSettings.authModeDropDown.label")}</Label>
                              <CustomField
                                as="select"
                                id={'authmodedropdown-' + this.props.kubernetesPlatform.id}
                                name="authMode"
                                className="form-control"
                                onChange={(e) => this.setState({ authMode: e.target.value })}>
                                <option value="TOKEN">{t("kubernetesSettings.accessSetup.authenticationSettings.authModeDropDown.values.valueToken")}</option>
                                <option value="CERTIFICATE">{t("kubernetesSettings.accessSetup.authenticationSettings.authModeDropDown.values.valueCertificate")}</option>
                              </CustomField>
                            </Col>
                          </Row>
                        </Col>
                        <div className="wrapper-subsection-control">
                          <Col sm="6">
                            <div className="custom-checkbox custom-control custom-control-inline">
                              <CustomField
                                type="checkbox"
                                id={'tlsverify-' + this.props.kubernetesPlatform.id}
                                name="tlsVerify"
                                className="custom-control-input"
                                checked={this.state.tlsVerify}
                                onChange={this.handleInputChangeLoc} />
                              <label htmlFor={'tlsverify-' + this.props.kubernetesPlatform.id} className="custom-control-label">
                                {t("kubernetesSettings.accessSetup.authenticationSettings.TLSVerification.label")}
                              </label>
                            </div>
                          </Col>
                        </div>
                      </Row>
                      {this.renderAccessMethod(formikProps)}
                    </section>
                    <section>
                      {this.props.kubeDemo === false
                        && UserContextInfo.userRolePlatformCreation()
                        && UserContextInfo.activeCompanyActivation() === "ACTIVATED" &&
                        <Row>
                          <Col sm="12">
                            <div className="section-submit-wrapper">
                              <Button
                                type="submit"
                                className="form-btn align-right"
                                disabled={this.props.fieldDisabled && this.props.healthCheckClass !== "notrunning"}>
                                {this.props.kubernetesStateCode !== BackEndConsts.DEPLOYMENT_PLATFORM_STATE_READY
                                  ? this.t("kubernetesSettings.accessSetup.connect")
                                  : this.t("kubernetesSettings.accessSetup.reconnect")}</Button>
                            </div>
                          </Col>
                        </Row>
                      }
                    </section>
                  </div>
                </Col>
              </Row>
            </Collapse>
          </Form>
        )}
      </Formik>
    )
  };
};