import React from "react";
import { Container } from "reactstrap";
import i18n from "localization/i18n";

export default class SimpleFooter extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  getLocalizedUrl = () => {
    const language = i18n.language;
    let url = "https://lainotik.com";

    if (language === "es") {
      url += "/inicio/";
    } else if (language === "eus") {
      url += "/hasiera/";
    }

    return url;
  };

  render() {
    const formattedDate = new Date().getFullYear();
    const localizedUrl = this.getLocalizedUrl();

    return (
      <footer className="footer">
        <Container fluid>
          <section className="original-footer">
            <div className="copyright">
              © {formattedDate}{" "}
              <a href={localizedUrl} target="_blank" rel="noopener noreferrer">
                Datagara
              </a>
            </div>
          </section>
          <section className="extended-footer"></section>
        </Container>
      </footer>
    );
  }
}