import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from "localization/en/en.json";
import es from "localization/es/es.json";
import eus from "localization/eus/eus.json";

const resources = {
  en: {
    translation: en
  },
  es: {
    translation: es
  },
  eus: {
    translation: eus
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;