import React, { useState, useContext, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import ThemeContext from 'contexts/ThemeContext';
import i18n from "localization/i18n";
import { Formik, Form } from 'formik';
import { CustomField } from 'tools/commonFunctions';
import * as Yup from 'yup';

const FormModal = (props) => {
  const t = i18n.t;
  const themeContext = useContext(ThemeContext);
  const { lang, className, modalType, modalFormat, label, id, name, value, defValue, toggleModal, acceptOnClick, handleInputChange, auxBtnFunction } = props;
  const [modal, setModal] = useState(true);
  const [paramValue, setParamValue] = useState(value);
  const [passView, setPassView] = useState(false);

  useEffect(() => {
    setParamValue(value);
  }, [value]);

  const toggle = (e) => {
    setModal(!modal);
    if (toggleModal) {
      toggleModal(e);
    }
  };

  const recoverDefaultValue = (e) => {
    setParamValue(defValue);
    if (auxBtnFunction) {
      auxBtnFunction(e);
    }
  };

  let inputType;
  switch (modalType) {
    case "TEXTLINE":
      inputType = "text";
      break;
    case "TEXTBOX":
    case "KEYVALUE":
      inputType = "textarea";
      break;
    case "SECRET":
      inputType = passView ? "text" : "password";
      break;
    default:
      inputType = "text";
      break;
  }

  let validationSchema = Yup.object().shape({
    [name]: Yup.string().required(t('formModal.requiredMessage'))
  });

  if (modalFormat === 'TEXT') {
    validationSchema = validationSchema.shape({
      [name]: Yup.string().matches(/^[a-zA-Z\s]*$/, t('formModal.notMatch')).required(t('formModal.requiredMessage'))
    });
  } else if (modalFormat === 'LIMITED_TEXT') {
    validationSchema = validationSchema.shape({
      [name]: Yup.string()
        .required(t('formModal.requiredMessage'))
        .test('validate-each-line', t('formModal.notMatch'), function (value) {
          const lines = value.split('\n');

          // Helper function for creating errors with the line number
          const createErrorWithLine = (lineNumber, errorKey) => {
            const errorMessage = `${t('formModal.notMatch')} ${lang === 'eus' ? t('formModal.errorLine', { errorLine: lineNumber + 1 }) : t('formModal.errorLine') + (lineNumber + 1)}: ${t(`formModal.${errorKey}`)}`;
            return this.createError({
              path: name,
              message: errorMessage
            });
          };

          for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            if (!line) {
              continue; // Ignore empty lines
            }

            const parts = line.split(':');
            if (parts.length < 2) {
              return createErrorWithLine(i, 'errorNoColon');
            }

            const key = parts[0].trim();
            const value = parts.slice(1).join(':').trimStart();

            if (!/^[A-Za-z]+[A-Za-z0-9_]*$/.test(key)) {
              return createErrorWithLine(i, 'errorKeyFormat');
            }

            if (value === '') {
              return createErrorWithLine(i, 'errorValueMissing');
            }

            if (parts[1][0] !== ' ') {
              return createErrorWithLine(i, 'errorNoSpaceAfterColon');
            }
          }
          return true;
        })
    });
  }

  return (
    <Modal name={props.name} isOpen={modal} toggle={toggle} className={className}>
      <ModalHeader>
        <i className={props.modalTitleIcon}></i>
        {props.modalTitleText}
      </ModalHeader>
      <Formik
        initialValues={{ [name]: paramValue }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          acceptOnClick({
            name: props.name,
            id: props.id,
            isSecret: props.modalType === "SECRET"
          });
        }}>
        {({ errors, touched }) => (
          <Form>
            <ModalBody className="modal-body">
              {props.modalBodyText}
              <span className="modal-body-link">
                {props.modalBodyLink}
              </span>
              <div className={props.modalType === "SECRET" ? "form-password-wrapper" : ""}>
                <Label htmlFor={id}>{label}</Label>
                {modalType === "SECRET" && (
                  <Button
                    className="form-btn-show-password"
                    onClick={() => setPassView(!passView)}>
                    <i className={passView ? "icon-eye-closed" : "icon-eye"}></i>
                    <div>
                      {passView ? 'Hide' : 'Show'}
                    </div>
                  </Button>
                )}
              </div>
              <CustomField
                type={inputType}
                className={`form-control ${errors[name] && touched[name] ? 'is-invalid' : ''}`}
                id={id}
                name={name}
                placeholder={t("formModal.placeHolder", { parameterLabel: label })}
                onChange={(e) => {
                  handleInputChange(e);
                  setParamValue(e.target.value);
                }}
                value={paramValue} />
            </ModalBody>
            <ModalFooter className="modal-footer">
              <div className='modal-footer-left'>
                <Button color="secondary" onClick={toggle}>{props.modalCancelBtn}</Button>
              </div>
              <div className='modal-footer-right'>
                {props.modalShowAuxBtn && defValue && (
                  <Button
                    className="form-aux-btn"
                    color="secondary"
                    onClick={recoverDefaultValue}>{props.modalAuxBtn}
                  </Button>
                )}
                <Button color={themeContext} type="submit">{props.modalAcceptBtn}</Button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default FormModal;