//AUTH0 RESPONSES
export const ACCOUNT_CREATION_SUCCESS = 201; //Auth0 sends the codes without quotes

//LAINOTIK GRAPHQL RESPONSES
export const RESPONSE_SUCCESS = 200

//Platform deployment codes
export const DEPLOYMENT_PLATFORM_STATE_INITSETUP = "122";
export const DEPLOYMENT_PLATFORM_STATE_ADDONS = "124";
export const DEPLOYMENT_PLATFORM_STATE_MONITORING = "125";
export const DEPLOYMENT_PLATFORM_STATE_READY = "127";
export const DEPLOYMENT_PLATFORM_STATE_CANNOTCONNECT = "128";
export const DEPLOYMENT_PLATFORM_STATE_CANNOTCONFIG = "129";
export const DEPLOYMENT_PLATFORM_STATE_NEWSETUP = "130";
export const DEPLOYMENT_PLATFORM_STATE_MONITORINGERROR = "142";

//Addon deployment codes
export const DEPLOYMENT_ADDON_STATE_REQUESTED = "111";
export const DEPLOYMENT_ADDON_STATE_INITIATED = "112";
export const DEPLOYMENT_ADDON_STATE_ONGOING = "113";
export const DEPLOYMENT_ADDON_STATE_COMPLETED = "110";
export const DEPLOYMENT_ADDON_STATE_ROLLEDBACK = "114";

//Aplication deployment codes
export const DEPLOYMENT_STATE_NEVERDEPLOYED = "133";
export const DEPLOYMENT_STATE_ROLLEDBACK = "114";
export const DEPLOYMENT_STATE_UPDATING = "111"; //Check if the states 112 and 113 are also functional
export const DEPLOYMENT_STATE_ACTIVE = "110";
export const DEPLOYMENT_STATE_INACTIVE = "115";
export const DEPLOYMENT_SAVED_NOT_DEPLOYED = "171";

//Misc string values 
export const PLATFORM_CONNECTION_STATUS_REQUESTED = "REQUESTED"
export const PLATFORM_CONNECTION_STATUS_CONNECTED = "CONNECTED"

//This is a constant used on the front to set the return object of a mutation to be dealt as an error on the other parts of the code
export const BACKEND_RESPONSE_ERROR = "Error";

//Infaprovider Values for now, need to refactor to load them from a list
export const INFRAPROVIDER_MINIKUBE = "1" //Infra for local environment
export const INFRAPROVIDER_GENERIC = "2"