import React from "react";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import * as BackEndConsts from "tools/constants/backEndConstants";
import {
  handleDate,
  platformNotifMessages
} from "tools/commonFunctions";
import { dataService } from "tools/dataService/dataService"
import { trackPromise } from "react-promise-tracker";
import UserContextInfo from "tools/userContextInfo";

import {
  UncontrolledTooltip,
  UncontrolledCollapse
} from "reactstrap";

import i18next from 'i18next';

import { LainoSwitch } from "components/Switch/Switch";

import AppComponent from "./AppComponent";
import GenericModal from "components/Modal/GenericModal";
class ServiceApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appId: this.props.app.id,
      appName: this.props.app.name,
      appState: this.props.app.state,
      appEditable: this.props.app.editable,
      appActivation: this.props.app.enabled,
      appDeployCode: this.props.app.deploymentstateinfo.code,
      appDeployStatus: this.props.app.deploymentstateinfo.codeinfo,
      appNotifError: this.props.app.deploynotificationerror,
      appDeployOwner: this.props.app.deployby,
      appDeployDate: this.props.app.deploydate
        ? handleDate(this.props.app.deploydate).dateFormatted
        : "",
      appDeployDuration: this.props.app.deployduration,
      appOnlineUsers: this.props.app.onlineusercount,
      appFinishState: this.props.app.deployfinishedstate,
      appNotifications: this.props.app.notifications,
      appTooltip: this.props.app.tooltip,
      appSecClusterEnabled: this.props.app.security.tool,
      appSecTerminalEnabled: this.props.app.security.console,
      appSecIsAdmin: this.props.app.security.admin,
      appComponents: this.props.app.appcomponents,
      deployButtonDisable: true,
      deployButtonCaption: "",
      endPointsModal: false,
      servicesDisabled: true,
      componentUpdated: false
    }

    this.toggleModal = this.toggleModal.bind(this);
    this.endPointsRequest = this.endPointsRequest.bind(this);
    this.handleTerminals = this.handleTerminals.bind(this);
    this.requestDeployLoop = this.requestDeployLoop.bind(this);
  }

  componentDidMount() {
    const t = this.props.t;
    let app = this.props.app;

    this.getAppData();

    this.setState({
      deployButtonDisable: app.state === "INACTIVE" ? true : false,
      deployButtonCaption: app.state !== "UPDATING" ? t("platformService.serviceApp.deploy") : "",
      servicesDisabled: app.state !== "ACTIVE" ? true : false
    })

    //Call to refresh deployment data every X seconds until state not being UPDATING
    if (app.state === "UPDATING") {
      let intervalTiming = 5000;

      if (localStorage.getItem(['IntervalId-' + app.id])) {
        let CurrentIntervalId = localStorage.getItem(['IntervalId-' + app.id]);
        clearInterval(CurrentIntervalId);
        this.requestDeployLoop(app.state, app.id, intervalTiming);
      } else this.requestDeployLoop(app.state, app.id, intervalTiming);

    } else return null
  };

  componentDidUpdate() {
    let appState = this.state.appState;
    let id = ('accordion-' + this.state.appId);
    if (appState === "UPDATING") {
      let element = document.getElementById(id)
      if (element !== undefined) {
        element.classList.remove("show");
      } else return null
    }
  }

  getAppData() {
    const t = this.props.t;

    trackPromise(
      dataService.queries.getServiceAppData(this.props.app.id).then(
        (dataapp) => {

          let compoProtocolObject = [];
          dataapp.appcomponents.map(
            (appComponent) => {
              appComponent.appcomponentprotocols.map(
                (compoProtocol) => {
                  if (compoProtocol.enabled) {
                    compoProtocolObject.push({
                      id: compoProtocol.id
                    })
                  } else null
                })
            })

          this.setState({
            appId: dataapp.id,
            appName: dataapp.name,
            appState: dataapp.state,
            appEditable: dataapp.editable,
            appActivation: dataapp.enabled,
            appDeployCode: dataapp.deploymentstateinfo.code,
            appDeployStatus: dataapp.deploymentstateinfo.codeinfo,
            appNotifError: dataapp.deploynotificationerror,
            appDeployOwner: dataapp.deployby,
            appDeployDate: dataapp.deploydate
              ? handleDate(dataapp.deploydate).dateFormatted
              : "",
            appDeployDuration: dataapp.deployduration,
            appOnlineUsers: dataapp.onlineusercount,
            appFinishState: dataapp.deployfinishedstate,
            appNotifications: dataapp.notifications,
            appClusters: dataapp.appclusters,
            appK8Endpoints: dataapp.k8endpoints,
            platformAppCACRT: dataapp.companyservice.kubeplatform.company.cacrt,
            platformStorages: dataapp.companyservice.kubeplatform.k8storages,
            platformLabels: dataapp.companyservice.kubeplatform.k8labels,
            appComponents: dataapp.appcomponents,
            componentProtocolId: compoProtocolObject.length > 0
              ? compoProtocolObject[0].id
              : null,
            deployButtonDisable: dataapp.state === "INACTIVE" ? true : false,
            deployButtonCaption: dataapp.state !== "UPDATING" ? t("platformService.serviceApp.deploy") : "",
            servicesDisabled: dataapp.state !== "ACTIVE" ? true : false
          })
        }
      )
    )
  }

  sendDeploy(appId) {
    const t = this.props.t;

    trackPromise(
      dataService.mutations.deployServiceApp(appId).then(
        (datadeploy) => {
          if (datadeploy === BackEndConsts.BACKEND_RESPONSE_ERROR) {
            this.setState({
              appActivation: !this.state.appActivation
            })
          } else {
            let datadeployAppData = datadeploy.companyserviceapp;
            let compoProtocolObject = []
            datadeployAppData.appcomponents.map(
              (appComponent) => {
                appComponent.appcomponentprotocols.map(
                  (compoProtocol) => {
                    if (compoProtocol.enabled) {
                      compoProtocolObject.push({
                        id: compoProtocol.id
                      })
                    } else null
                  })
              })

            this.setState({
              appId: datadeployAppData.id,
              appEditable: datadeployAppData.editable,
              appState: datadeployAppData.state,
              appActivation: datadeployAppData.enabled,
              appDeployMessage: datadeployAppData.deploymentstateinfo.message,
              appDeployMessageLevel: datadeployAppData.deploymentstateinfo.messagelevel,
              appDeployMessageTitle: datadeployAppData.deploymentstateinfo.messagetitle,
              appNotifError: datadeployAppData.deploynotificationerror,
              appDeployOwner: datadeployAppData.deployby,
              appDeployDate: datadeployAppData.deploydate
                ? handleDate(datadeployAppData.deploydate).dateFormatted
                : "",
              appDeployDuration: datadeployAppData.deployduration,
              appOnlineUsers: datadeployAppData.onlineusercount,
              appFinishState: datadeployAppData.deployfinishedstate,
              appNotifications: datadeployAppData.notifications,
              appComponents: datadeployAppData.appcomponents,
              componentProtocolId: compoProtocolObject.length > 0
                ? compoProtocolObject[0].id
                : null,
              componentProtocols: compoProtocolObject,
              settingsOpened: false,
              deployButtonDisable: datadeployAppData.state === "UPDATING" ? true : false,
              deployButtonCaption: datadeployAppData.state !== "UPDATING" ? t("platformService.serviceApp.deploy") : "",
              servicesDisabled: (datadeployAppData.state !== "ACTIVE"
                || this.state.appSecClusterEnabled === false) ? true : false
            });

            platformNotifMessages(
              this.state.appDeployMessageLevel,
              this.state.appDeployMessageTitle,
              this.state.appDeployMessage,
              this.props.app.name)

            //Call to refresh deployment data every X seconds until state not being UPDATING
            this.requestDeployLoop(datadeployAppData.state, datadeployAppData.id, 10000);
          }
        }
      )
    )
  }

  sendDelete(appId) {
    const t = this.props.t;

    trackPromise(
      dataService.mutations.deleteServiceApp(appId).then(
        (datadelete) => {
          if (datadelete === BackEndConsts.BACKEND_RESPONSE_ERROR) {
            this.setState({
              appActivation: !this.state.appActivation
            })
          } else {
            let datadeleteAppData = datadelete.companyserviceapp;
            let compoProtocolObject = []
            datadeleteAppData.appcomponents.map(
              (appComponent) => {
                appComponent.appcomponentprotocols.map(
                  (compoProtocol) => {
                    if (compoProtocol.enabled) {
                      compoProtocolObject.push({
                        id: compoProtocol.id
                      })
                    } else null
                  })
              })

            this.setState({
              appId: datadeleteAppData.id,
              appEditable: datadeleteAppData.editable,
              appState: datadeleteAppData.state,
              appActivation: datadeleteAppData.enabled,
              appDeployMessage: datadeleteAppData.deploymentstateinfo.message,
              appDeployMessageLevel: datadeleteAppData.deploymentstateinfo.messagelevel,
              appDeployMessageTitle: datadeleteAppData.deploymentstateinfo.messagetitle,
              appNotifError: datadeleteAppData.deploynotificationerror,
              appDeployOwner: datadeleteAppData.deployby,
              appDeployDate: datadeleteAppData.deploydate
                ? handleDate(datadeleteAppData.deploydate).dateFormatted
                : "",
              appDeployDuration: datadeleteAppData.deployduration,
              appOnlineUsers: datadeleteAppData.onlineusercount,
              appFinishState: datadeleteAppData.deployfinishedstate,
              appNotifications: datadeleteAppData.notifications,
              appComponents: datadeleteAppData.appcomponents,
              componentProtocolId: compoProtocolObject.length > 0
                ? compoProtocolObject[0].id
                : null,
              componentProtocols: compoProtocolObject,
              deployButtonDisable: datadeleteAppData.state === "INACTIVE" ? true : false,
              deployButtonCaption: datadeleteAppData.state !== "UPDATING" ? t("platformService.serviceApp.deploy") : "",
              servicesDisabled: (datadeleteAppData.state !== "ACTIVE"
                || this.state.appSecClusterEnabled === false) ? true : false
            });

            platformNotifMessages(
              this.state.appDeployMessageLevel,
              this.state.appDeployMessageTitle,
              this.state.appDeployMessage,
              this.props.app.name)

            //Call to refresh deployment data every X seconds until state not being UPDATING
            this.requestDeployLoop(datadeleteAppData.state, datadeleteAppData.id, 10000);
          }
        }
      )
    )
  }

  getDeployStatus(appId, intervalID, callNotif) {
    const t = this.props.t;

    //Loop query without the trackpromise to not show the spinner
    dataService.queries.getServiceAppData(appId).then(
      (dataapp) => {
        let compoProtocolObject = []
        dataapp.appcomponents.map(
          (appComponent) => {
            appComponent.appcomponentprotocols.map(
              (compoProtocol) => {
                if (compoProtocol.enabled) {
                  compoProtocolObject.push({
                    id: compoProtocol.id
                  })
                } else null
              })
          })

        this.setState({
          appState: dataapp.state,
          appEditable: dataapp.editable,
          appActivation: dataapp.enabled,
          appDeployCode: dataapp.deploymentstateinfo.code,
          appDeployStatus: dataapp.deploymentstateinfo.codeinfo,
          appDeployMessage: dataapp.deploymentstateinfo.message,
          appDeployMessageLevel: dataapp.deploymentstateinfo.messagelevel,
          appDeployMessageTitle: dataapp.deploymentstateinfo.messagetitle,
          appNotifError: dataapp.deploynotificationerror,
          appDeployOwner: dataapp.deployby,
          appDeployDate: dataapp.deploydate
            ? handleDate(dataapp.deploydate).dateFormatted
            : "",
          appDeployDuration: dataapp.deployduration,
          appOnlineUsers: dataapp.onlineusercount,
          appFinishState: dataapp.deployfinishedstate,
          appNotifications: dataapp.notifications,
          appComponents: dataapp.appcomponents,
          componentProtocolId: compoProtocolObject.length > 0
            ? compoProtocolObject[0].id
            : null,
          componentProtocols: compoProtocolObject,
          appK8Endpoints: dataapp.k8endpoints,
          platformAppCACRT: dataapp.companyservice.kubeplatform.company.cacrt,
          platformStorages: dataapp.companyservice.kubeplatform.k8storages,
          platformLabels: dataapp.companyservice.kubeplatform.k8labels,

          intervalID: intervalID,

          deployButtonDisable: dataapp.state === "INACTIVE" ? true : false,
          deployButtonCaption: dataapp.state !== "UPDATING" ? t("platformService.serviceApp.deploy") : "",
          servicesDisabled: (dataapp.state !== "ACTIVE"
            || this.state.appSecClusterEnabled === false) ? true : false
        })

        if (dataapp.state === "UPDATING") {
          localStorage.setItem(['IntervalId-' + appId], intervalID);

          if (callNotif !== false) {
            return (
              platformNotifMessages(
                this.state.appDeployMessageLevel,
                this.state.appDeployMessageTitle,
                this.state.appDeployMessage,
                this.props.app.name)
            );
          } else return null;
        } else if (dataapp.deployfinishedstate.length !== 0) {
          return (
            this.handleDeploymentFinishedState(
              intervalID,
              dataapp.deploymentstateinfo.code,
              dataapp.deploymentstateinfo.messagelevel,
              dataapp.deploymentstateinfo.messagetitle,
              dataapp.deploymentstateinfo.message,
              dataapp.deployfinishedstate
            )
          );
        } else return null;
      }
    )
  };

  requestDeployLoop(deployStatus, appId, intervalTiming) {
    let intervalfunc = this.getDeployStatus.bind(this);
    let intervalLoop = function () {
      if (deployStatus === "UPDATING") {
        return (
          // eslint-disable-next-line
          localStorage.setItem(['IntervalId-' + appId], interval),
          intervalfunc(appId, interval, false)
        );
      }
      else return (
        clearInterval(interval)
      )
    };
    let interval = setInterval(intervalLoop, intervalTiming);
    intervalLoop();
  }

  handleCheckboxChange = (e) => {
    let appId = this.state.appId;
    this.setState({
      appActivation: !this.state.appActivation,
      settingsOpened: false,
      appState: "UPDATING"
    })
    e === true ? this.sendDeploy(appId) : this.sendDelete(appId);
  };

  handleDeploymentFinishedState = (
    intervalID,
    deployCode,
    messagelevel,
    messagetitle,
    message,
    finishState) => {

    let reason = this.state.appNotifError;

    switch (deployCode) {
      case BackEndConsts.DEPLOYMENT_STATE_ROLLEDBACK:
        message = reason;
        break;
      default:
        break;
    }
    if (finishState) {
      return (
        platformNotifMessages(
          messagelevel,
          messagetitle,
          message,
          // eslint-disable-next-line
          this.props.app.name),
        //Remove the created loop
        clearInterval(intervalID),
        this.setState({
          appFinishedState: finishState,
          settingsOpened: false,
          componentUpdated: !this.state.componentUpdated
        }),
        localStorage.removeItem(['IntervalId-' + this.state.appId])
      )
    } else return null
  }

  renderSwitchButton = () => {
    let isAccountActivated = UserContextInfo.activeCompanyActivation()
    let isAdmin = this.state.appSecIsAdmin;
    let disableStyles = this.state.appState !== "UPDATING" ? "" : "disabled disabled"

    if (isAdmin !== true || isAccountActivated !== "ACTIVATED") {
      return (
        <>
          <LainoSwitch
            disabled={true}
            checked={this.state.appActivation}
            onChange={this.handleCheckboxChange}
            disableStyles="disabled disabled"
            hexColor={this.props.hexColor}
          />
        </>
      );
    } else return (
      <>
        <LainoSwitch
          disabled={this.state.appState === "UPDATING" ? true : false}
          checked={this.state.appActivation}
          onChange={this.handleCheckboxChange}
          disableStyles={disableStyles}
          hexColor={this.props.hexColor}
        />
      </>
    )
  };

  toggleModal = (e) => {
    e.persist();

    let name = e.target.name;
    let state = e.target.value;

    this.setState({
      [name]: state === "true" ? false : true,
      endPointsModal: !this.state.endPointsModal
    });
  }

  toggleSettingsSection = () => {
    this.setState({
      settingsOpened: !this.state.settingsOpened
    })
  }

  endPointsRequest() {
    const t = this.props.t;

    this.setState(prevState => ({
      modalTitleText: t("platformService.serviceApp.endPointModal.endPointTitle", { appName: this.props.app.name }),
      modalBodyText: t("platformService.serviceApp.endPointModal.endPointBody"),
      modalAcceptBtn: t("platformService.serviceApp.endPointModal.endPointAcceptBtn"),
      modalCancelBtn: t("kubernetesSettings.deleteModal.deleteCancelBtn"),
      endPointsModal: !prevState.endPointsModal
    }));
  }

  handleEndpoints(
    terminalBlocked,
    appEditable,
    endpointID,
    appCredentials) {

    const t = this.props.t;
    let isAdmin = this.state.appSecIsAdmin

    if (terminalBlocked || appEditable === false) {
      return (
        <i
          id={'endpoint-' + endpointID}
          className="icon-api-big elem-settings-btn disabled"></i>
      )
    } else {
      return (
        <>
          <i
            id={'endpoint-' + endpointID}
            className={terminalBlocked ? "cluster-icon link-disabled" : "cluster-icon"}
            onClick={() => { this.endPointsRequest() }}
            target="_blank"
            rel="noopener noreferrer">
            <i
              className="icon-api-big elem-settings-btn"></i>
            <UncontrolledTooltip
              placement="bottom"
              target={'endpoint-' + endpointID}>{t("platformService.serviceApp.endpoints.title")}</UncontrolledTooltip>
          </i>
          {this.state.endPointsModal ?
            <GenericModal
              id={endpointID}
              name="endPointsModal"
              toggleModal={(e) => { this.toggleModal(e) }}
              acceptOnClick={this.handleRenewTSLCert}
              modalTitleText={this.state.modalTitleText}
              modalBodyText={this.state.modalBodyText}
              modalBodyLink={this.state.modalBodyLink}
              modalExtraText={[
                { label: t("platformService.serviceApp.endPointModal.extraText.endpointProtocols") + appCredentials[4], content: appCredentials[0] },
                { label: t("platformService.serviceApp.endPointModal.extraText.caCrt"), content: appCredentials[1] },
                { label: t("platformService.serviceApp.endPointModal.extraText.tlskey"), content: appCredentials[2] },
                { label: t("platformService.serviceApp.endPointModal.extraText.tlsCrt") + appCredentials[5], content: appCredentials[3] }]}
              modalAcceptBtn={this.state.modalAcceptBtn}
              modalCancelBtn={this.state.modalCancelBtn}
              acceptBtnDisabled={!isAdmin} /> :
            null
          }
        </>
      )
    }
  }

  handleTerminals(terminalBlocked, terminalUrl, terminalID) {
    const t = this.props.t;

    let terminalEnabled = this.state.appSecTerminalEnabled;
    if (terminalBlocked || terminalEnabled === false) {
      return (
        <i
          id={'terminal-' + terminalID}
          className="icon-terminal-min elem-settings-btn disabled"></i>
      )
    } else {
      return (
        <a
          className={terminalBlocked ? "cluster-icon link-disabled" : "cluster-icon"}
          href={terminalUrl}
          target="_blank"
          rel="noopener noreferrer">
          <i
            id={'terminal-' + terminalID}
            className="icon-terminal-min elem-settings-btn"></i>
          <UncontrolledTooltip
            placement="bottom"
            target={'terminal-' + terminalID}
          >{t("platformService.serviceApp.console")}</UncontrolledTooltip>
        </a>
      )
    }
  }

  handleAppComponents = () => {
    const i18n = i18next;
    let components = this.state.appComponents;
    let componentProtocolsArray = [];
    let componentEnabledProtocolsArray = [];

    if (components.constructor === Array && components.length !== 0) {
      return (
        <>
          {components.map((appComponent, key) => {
            if (appComponent.show === true &&
              this.state.platformLabels) {

              if (appComponent.appcomponentprotocols
                && appComponent.appcomponentprotocols.constructor === Array
                && appComponent.appcomponentprotocols.length !== 0) {
                appComponent.appcomponentprotocols.map((compoProtocol) => {
                  if (compoProtocol.enabled === true) {
                    componentEnabledProtocolsArray.push(
                      compoProtocol.id
                    )
                  }
                  componentProtocolsArray.push({
                    id: compoProtocol.id,
                    enabled: compoProtocol.enabled,
                    name: compoProtocol.protocol.name
                  })
                })
              }
              return (
                <AppComponent
                  i18n={i18n}
                  key={key}
                  appId={this.state.appId}
                  appActivation={this.state.appActivation}
                  appDeployCode={this.state.appDeployCode}
                  deployButtonDisable={this.state.deployButtonDisable}
                  requestDeployLoop={this.requestDeployLoop}
                  appFinishedState={this.props.app.deployfinishedstate}
                  appComponent={appComponent}
                  componentUpdated={this.state.componentUpdated}
                  hexColor={this.props.hexColor}
                  platformStorages={this.state.platformStorages}
                  platformLabels={this.state.platformLabels}
                  componentProtocolId={this.state.componentProtocolId}
                  componentProtocols={componentProtocolsArray}
                  componentEnabledProtocols={componentEnabledProtocolsArray}
                />
              )
            } else return null
          })}
        </>
      )
    }
  }

  handleRenewTSLCert = () => {
    trackPromise(
      dataService.mutations.renewAppTLSCert(
        this.state.appId
      ).then(
        (datarenewappcert) => {
          if (datarenewappcert !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            platformNotifMessages(
              datarenewappcert.response.messageinfo.messagelevel,
              datarenewappcert.response.messageinfo.messagetitle,
              datarenewappcert.response.messageinfo.message
            );
            this.setState(prevState => ({
              endPointsModal: !prevState.endPointsModal
            }));

            //Call to refresh deployment data every X seconds until state not being UPDATING
            this.requestDeployLoop("UPDATING", this.state.appId, 5000)
          } else {
            this.setState(prevState => ({
              endPointsModal: !prevState.endPointsModal
            }));
          }
        }
      )
    )
  }

  renderSettingsSection(appid, appEditable) {
    let isAdmin = this.state.appSecIsAdmin;
    //This if else clause seems it could be refactored easily but if i remove those else return in doesn't works as expected...
    if (appEditable === true && isAdmin) {
      if (this.state.appState !== "UPDATING") {
        return (
          <>
            <i
              onClick={() => {
                this.toggleSettingsSection()
              }}
              id={'content-' + appid}
              className={`elem-settings-btn ${this.state.settingsOpened ?
                'icon-close expanded' : 'icon-configuration'}`} />
          </>
        );
      } else return (
        <>
          <i
            id={'content-' + appid}
            className="elem-settings-btn icon-configuration disabled" />
        </>
      );
    } else return (
      <>
        <i
          id={'content-' + appid}
          className="elem-settings-btn icon-configuration disabled" />
      </>
    );
  }

  renderDeploymentInfo() {
    const { t, language } = i18next;
    const { appDeployCode, appDeployStatus, appDeployOwner, appDeployDate, appDeployDuration, appNotifError, appId } = this.state;
  
    if (!appDeployCode || appDeployCode === BackEndConsts.DEPLOYMENT_STATE_NEVERDEPLOYED) {
      return <span className="app-status-label">{appDeployStatus}</span>;
    }
  
    const isEusLanguage = language === "eus";
    const isRolledBack = appDeployCode === BackEndConsts.DEPLOYMENT_STATE_ROLLEDBACK;
    const spanId = isRolledBack ? `DeployFinishState-${appId}` : '';
  
    const getTooltip = () => (
      <UncontrolledTooltip
        placement="bottom"
        target={`DeployFinishState-${appId}`}>
        {appNotifError}
      </UncontrolledTooltip>
    );
  
    return (
      <span className="app-status-label">
        {t("common.deploymentLegend.part1")}
        <span id={spanId} className={`app-status-msg ${isEusLanguage ? 'app-msg-email' : 'app-msg-highlighted'}`}>
          {isEusLanguage
            ? t("common.deploymentLegend.part1_param", { DeployBy: appDeployOwner })
            : isRolledBack
              ? <span className="app-status-info">{t("common.deploymentLegend.part1_param", { DeployCodeInfo: appDeployStatus })}</span>
              : t("common.deploymentLegend.part1_param", { DeployCodeInfo: appDeployStatus })
          }
        </span>
        {t("common.deploymentLegend.part2", { DeployDate: appDeployDate })}
        {!isEusLanguage
          ? <span className={`app-status-msg ${isEusLanguage ? '' : 'app-msg-highlighted'}`}>
            {t("common.deploymentLegend.part2_param", { DeployDate: appDeployDate })}
          </span>
          : t("common.deploymentLegend.part2_param")
        }
        {t("common.deploymentLegend.part3")}
        <span id={isEusLanguage && isRolledBack ? `DeployFinishState-${appId}` : ''} className="app-status-msg app-msg-highlighted">
          {isEusLanguage
            ? isRolledBack
              ? <span className="app-status-info">{t("common.deploymentLegend.part3_param", { DeployCodeInfo: appDeployStatus })}</span>
              : t("common.deploymentLegend.part3_param", { DeployCodeInfo: appDeployStatus })
            : t("common.deploymentLegend.part3_param", { DeployDuration: appDeployDuration })
          }
        </span>
        {t("common.deploymentLegend.part4")}
        <span className="app-status-msg app-msg-email">
          {isEusLanguage
            ? t("common.deploymentLegend.part4_param", { DeployDuration: appDeployDuration })
            : t("common.deploymentLegend.part4_param", { DeployBy: appDeployOwner })
          }
        </span>
        {isEusLanguage && t("common.deploymentLegend.part5")}
        {isRolledBack && getTooltip()}
      </span>
    );
  }
  

  render() {
    const t = this.props.t;
    let appId = this.props.app.id;
    let notifications = this.state.appNotifications;

    return (
      <>
        <tr key={this.state.appId}>
          <td>
            <span className="switch-control">
              {this.renderSwitchButton()}
            </span>
          </td>
          <td className="app-name">{this.state.appName}</td>
          <td>
            <ul className="cluster-list serviceapp-cluster">
              {this.state.appClusters && this.state.appClusters.map((cluster, key) => {
                if (cluster.type === "Service") {
                  return (
                    <li key={key}
                      id={cluster.id}
                      className="cluster-elem">
                      <a
                        className={(this.state.servicesDisabled || this.state.appSecClusterEnabled === false) ? "cluster-icon link-disabled" : "cluster-icon"}
                        href={cluster.url}
                        target="_blank"
                        rel="noopener noreferrer">
                        <i className={cluster.image}></i>
                      </a>
                      <span
                        id={"ClusterTooltip-" + cluster.id}
                        className="cluster-name">{cluster.name}</span>
                      <UncontrolledTooltip
                        placement="right"
                        target={"ClusterTooltip-" + cluster.id}
                      >{cluster.tooltip}</UncontrolledTooltip>
                    </li>
                  )
                } else return null
              })}
            </ul>
          </td>
          <td
            className="administration-column-set">
            {this.state.appK8Endpoints && this.state.appK8Endpoints.map((k8endpoint) => {
              if (k8endpoint.type === "public") {
                return (
                  <>
                    {this.handleEndpoints(
                      this.state.servicesDisabled,
                      this.state.appEditable,
                      k8endpoint.id,
                      [
                        k8endpoint.domain,
                        this.state.platformAppCACRT,
                        k8endpoint.clientkey,
                        k8endpoint.clientcrt,
                        k8endpoint.protocols,
                        k8endpoint.certexpirationdate
                      ])
                    }
                  </>
                )
              } else return null
            })}
            {this.state.appClusters && this.state.appClusters.map((cluster, key) => {
              if (cluster.type === "Terminal") {
                return (
                  <>
                    {this.handleTerminals(
                      this.state.servicesDisabled,
                      cluster.url,
                      cluster.id,
                      cluster.name
                    )}
                  </>
                )
              } else return null
            })}
            {this.renderSettingsSection(appId, this.state.appEditable)}
            <UncontrolledTooltip
              placement="bottom"
              target={'content-' + appId}
            >{t("common.settings")}</UncontrolledTooltip>
          </td>
        </tr>
        <tr className="row-separator messaging-wrapper">
          <td colSpan="4" className="noborder">
            <table className="table-status-info">
              <th className="collapsed-row-wrapper header-status-wrapper header-deploystatus">
                {this.renderDeploymentInfo()}
              </th>
              <th className="collapsed-row-wrapper header-status-wrapper header-onlineusers">
                <span className="app-status-label">{t("common.online")}:</span>
                <span className="app-status-msg">{this.state.appOnlineUsers}</span>
              </th>
            </table>
          </td>
        </tr>
        {notifications.length !== 0 &&
          <tr>
            <td colSpan="4" className="noborder">
              <table className="table-lainotik table-notifications">
                <tr className="row-separator messaging-wrapper">
                  <th className="column-header column-name">{t("kubernetesSettings.platformStatusArea.monitoringStatus.tableNotifications.pod")}</th>
                  <th className="column-header column-phase">{t("kubernetesSettings.platformStatusArea.monitoringStatus.tableNotifications.phase")}</th>
                  <th className="column-header column-sche">{t("kubernetesSettings.platformStatusArea.monitoringStatus.tableNotifications.scheduled")}</th>
                  <th className="column-header column-init">{t("kubernetesSettings.platformStatusArea.monitoringStatus.tableNotifications.initialized")}</th>
                  <th className="column-header column-ready">{t("kubernetesSettings.platformStatusArea.monitoringStatus.tableNotifications.ready")}</th>
                </tr>
                {notifications && notifications.map((notif, index) => {
                  return (
                    <tr key={index}>
                      <td className="table-value value-name">{notif.name}</td>
                      <td className="table-value">{notif.phase}</td>
                      <td className="table-value">{notif.podscheduled}</td>
                      <td className="table-value">{notif.initialized}</td>
                      <td className="table-value">{notif.ready}</td>
                    </tr>
                  )
                })}
              </table>
            </td>
          </tr>
        }
        <tr>
          <td className="collapsed-content" colSpan="4">
            <UncontrolledCollapse
              id={'accordion-' + this.props.app.id}
              toggler={'#content-' + this.props.app.id}
              isOpen={this.state.settingsOpened}>
              {this.state.appComponents
                && this.handleAppComponents()}
            </UncontrolledCollapse>
          </td>
        </tr>
      </>
    );
  }
};
export default ServiceApp

ServiceApp.propTypes = {
  components: PropTypes.array
}