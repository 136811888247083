import React from "react";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table
} from "reactstrap";

import KubernetesSettings from "./KubernetesSettings";

class TableCreation extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
  };

  handleCollapseSection = () => {
    this.setState(NewStatusCheck => ({
      isExpanded: !NewStatusCheck.isExpanded
    }));
  }

  handleKubernetesPlatforms = () => {
    if (this.props.kubernetesPlatform
      && this.props.kubernetesPlatform.constructor === Array
      && this.props.kubernetesPlatform.length !== 0) {
      return (
        <>
          {this.props.kubernetesPlatform.map((kubernetes, key) => {
            if (!kubernetes) {
              console.error("kubernetes is undefined o null:", kubernetes);
            }
            return (
              <KubernetesSettings
                t={this.t}
                key={key}
                serviceName={this.props.serviceName}
                kubernetesPlatform={kubernetes}
                isEmpty={kubernetes.isEmpty ? kubernetes.isEmpty : false}
                kubernetesId={kubernetes.id}
                handlePlatformServices={this.props.handlePlatformServices}
                enableCreateButton={this.props.enableCreateButton}
                companyId={this.props.companyId}
                bgColor={this.props.bgColor}
                activePlatformId={this.props.activePlatformId} />
            );
          })}
        </>
      )
    } else return (
      <>
        <tr>
          <td><span className="status-icon"></span></td>
          <td> - </td>
          <td> - </td>
          <td className="wrapper-monitoring"> - </td>
          <td className="administration-column-set"> - </td>
        </tr>
      </>
    )
  };

  render() {
    const t = this.t;
    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">{t("kubernetesPlatform.tableCreation.yourLainotikPlatforms")}</CardTitle>
            </CardHeader>
            <CardBody>
              <Table id="Table" className="tablesorter" responsive>
                <thead className="text-primary">
                  <tr className="theader">
                    <th className="row-1">{t("kubernetesPlatform.tableCreation.status")}</th>
                    <th className="row-2">{t("kubernetesPlatform.tableCreation.name")}</th>
                    <th className="row-3">{t("kubernetesPlatform.tableCreation.setup")}</th>
                    <th className="row-4 row-monitoring">{t("kubernetesPlatform.tableCreation.monitoring")}</th>
                    <th className="row-5">{t("kubernetesPlatform.tableCreation.management")}</th>
                  </tr>
                </thead>
                <tbody data={this.props.bgColor}>
                  {this.handleKubernetesPlatforms()}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}
export default TableCreation;

TableCreation.propTypes = {
  kubernetes: PropTypes.array
}
