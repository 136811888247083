import React from "react";

import {
  Row,
  Col
} from "reactstrap";

import { withTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import SimpleFooter from "components/Footer/SimpleFooter";

class EmailSentPage extends React.Component {
  constructor(props) {
    super();
    this.state = {}
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <Row className="justify-content-center align-items-center">
          <Col md="6">
            <div className="content signup-form-wrapper">
              <img
                className="lainotik-logo"
                alt="Lainotik logo"
                src={require('assets/img/condensed-pink.svg').default} />
            </div>
          </Col>
        </Row>
        <div className="signup-form-wrapper">
          <Row className="justify-content-center align-items-center">
            <Col md="7">
              <section className="section-title">
                <h1>{t("forgotPassword.emailSent.title")}</h1>
              </section>
              <section className="section-intro">
                <p className="signup-confirmation">
                  <p>{t("forgotPassword.emailSent.sentEmail")}
                    <span className="signup-email"> {this.props.accountEmail} </span>
                  </p>
                  <p>{t("forgotPassword.emailSent.checkEmailNewPassword")}</p>
                </p>
                <p>{t("forgotPassword.emailSent.checkEmailTip")}</p>
              </section>
              <span className="onboarding-nav-link">
                <Link to="/">{t("signup.signupConfirmPage.backToLogin")}</Link>
              </span>
            </Col>
          </Row>
          <SimpleFooter />
        </div>
      </>
    )
  }
}
export default withTranslation()(EmailSentPage);
