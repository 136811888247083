import React from "react";
import {
  Container
} from "reactstrap";

import i18n from "localization/i18n";

import RegisterPage from "layouts/Onboarding/SignUp/SignUp_RegisterForm";
import ConfirmPage from "layouts/Onboarding/SignUp/SignUp_Confirm";
export default class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountCreated: false
    }
  };

  componentDidMount() {
    i18n.changeLanguage(i18n.language || 'en');
  }

  handleAccountCreation(newAccountEmail) {
    this.setState({
      accountCreated: true,
      accountEmail: newAccountEmail
    })
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <Container className="h-100vh">
          {(!this.state.accountCreated) ?
            <RegisterPage
              t={t}
              handleAccountCreation={(e) => { this.handleAccountCreation(e) }} /> :
            <ConfirmPage
              t={t}
              accountEmail={this.state.accountEmail} />}
        </Container>
      </>
    )
  }
}