import Cookies from "universal-cookie";

const createMutationsLainotik = (httpClient, dataServiceFunctions, i18n, BackEndConsts) => {
  /* - Create an account - Account Level */
  const register = async (firstname, lastname, email, password, company) => {

    let CREATE_ACCOUNT = {
      "query": `mutation SignUp($firstname: String!, $lastname: String!, $email: String!, $pwd: String!, $companyname: String!){
          signUp(
            firstname: $firstname,
            lastname: $lastname,
            email : $email,
            pwd : $pwd,
            companyname: $companyname
          )
          {
            code
            error
            message
            messageinfo {
              message
              messagelevel
              messagetitle
            }
          }
        }`,
      "variables": {
        "firstname": firstname,
        "lastname": lastname,
        "email": email,
        "pwd": password,
        "companyname": company
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(CREATE_ACCOUNT, this)
      .then(response => {
        let createaccountresp = response.data.data.signUp;
        let createaccountData = dataServiceFunctions.handleBackEndErrorMessage(createaccountresp, BackEndConsts.ACCOUNT_CREATION_SUCCESS);
        return createaccountData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Update the values of an existing user account - Profile level */
  const updateUserProfile = async (accountId, firstName, lastName) => {
    let UPDATE_USER_PROFILE = {
      "query": `mutation UpdateAccount($accountid: ID!, $firstname: String!, $lastname: String!){
          updateAccount(
            accountid: $accountid,
            firstname: $firstname,
            lastname: $lastname
          ){
            response {
              code
              error
              message
              messageinfo {
                messagelevel
                messagetitle
                message
              }
            }
            account {
              id
              firstname
              lastname
              email
            }
          }
        }`,
      "variables": {
        "accountid": accountId,
        "firstname": firstName,
        "lastname": lastName
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(UPDATE_USER_PROFILE, this)
      .then(response => {
        let updateaccountresp = response.data.data.updateAccount.response;
        if (updateaccountresp.code !== BackEndConsts.RESPONSE_SUCCESS) {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: "DANGER",
                messagetitle: updateaccountresp.error,
                message: updateaccountresp.message
              }
            }
          });
          let updateaccountData = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return updateaccountData;
        } else {
          let updateaccountData = response.data.data.updateAccount;
          return updateaccountData;
        }
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Login */
  const login = async (username, password) => {
    let LOGIN_USER = {
      "query": `mutation ($email: String!, $pwd: String){
            login(
              email: $email,
              pwd: $pwd
            )
            {
              response {
                code
                error
                message
                messageinfo {
                  message
                  messagelevel
                  messagetitle
                }
              }
              account {
                id
                firstname
                lastname
                email
                oauthid
                creationdate
                lastcompany {
                  id
                  name
                  activation
                  security {
                    roleid
                    rolename
                    companycreate
                    k8create
                    accountcreate
                  }
                }
                lastplatform {
                  id
                  name
                  clsaddmon
                  connectionstate
                  deploymentstateinfo {
                    codeinfolevel
                    codeinfo
                  }
                }
              }
              companies {
                id
                name
                activation
                security {
                  roleid
                }
              }
              accesstoken
            }
        }`,
      "variables": {
        "email": username,
        "pwd": password
      }
    }
    return await httpClient.httpPostRequestGraphQL(LOGIN_USER, this).then(response => {
      let backEndResponse = response.data.data.login;
      let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
      return backEndResponseData;
    },
      error => {
        dataServiceFunctions.handleBackEndInternalError(error);
        //Clear ALL the localStorage keys
        localStorage.clear()
        //Clear the cookie used for proxy login
        Cookies.remove('authLainotik', { path: '/', domain: ".lainotik.io" });
      }
    );
  };

  /* - Forgot Password - Account level */
  const forgotPassword = async (email) => {
    let RESET_PASS_EMAIL = {
      "query": `mutation ForgotPassword($email: String!){
          forgotPassword(
            email : $email
          )
          {
            code
            error
            message
            messageinfo {
              message
              messagelevel
              messagetitle
            }
          }
        }`,
      "variables": {
        "email": email
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(RESET_PASS_EMAIL, this)
      .then(response => {
        let dataforgotpassword = response.data.data.forgotPassword;
        if (dataforgotpassword.code !== BackEndConsts.RESPONSE_SUCCESS) {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: "DANGER",
                messagetitle: dataforgotpassword.error,
                message: dataforgotpassword.message
              }
            }
          });
          let dataforgotpasswordData = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return dataforgotpasswordData;
        } else {
          let dataforgotpasswordData = dataforgotpassword.messageinfo;
          return dataforgotpasswordData;
        };
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Set a new password for an already existing account - Account level */
  const changePassword = async (password) => {
    let RESET_PASS_NEW_PASS = {
      "query": `mutation ChangePassword($pwd: String!){
          changePassword(
            pwd : $pwd
          )
          {
            code
            error
            message
            messageinfo {
              message
              messagelevel
              messagetitle
            }
          }
        }`,
      "variables": {
        "pwd": password
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(RESET_PASS_NEW_PASS, this)
      .then(response => {
        let datachangepassword = response.data.data.changePassword;
        if (datachangepassword.code !== BackEndConsts.RESPONSE_SUCCESS) {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: "DANGER",
                messagetitle: datachangepassword.error,
                message: datachangepassword.message
              }
            }
          });
          let datachangepasswordData = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return datachangepasswordData;
        } else {
          let datachangepasswordData = datachangepassword.messageinfo;
          return datachangepasswordData;
        }
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Request account activation for an specific company - Account level */
  const requestAccountActivation = async (companyID) => {
    let ACCOUNT_ACTIVATION_REQUEST = {
      "query": `mutation CompanyActivationRequest($companyid: ID!){
          companyActivationRequest(
            companyid: $companyid
          )
          {
            response {
              error
              code
              message
              messageinfo {
                messagelevel
                messagetitle
                message
              }
            }
            company {
              id
              activation
              security {
                roleid
                rolename
                companycreate
                k8create
                accountcreate
              }
              name
              address
              zipcode
              city
              country {
                code
                name
              }
            }    
          }
        }`,
      "variables": {
        "companyid": companyID
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(ACCOUNT_ACTIVATION_REQUEST, this)
      .then(response => {
        let backEndResponse = response.data.data.companyActivationRequest;
        let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
        return backEndResponseData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Create an account and bind it to a company (Invite Account)- Account level */
  const inviteEmail = async (companyID, email) => {
    let INVITE_EMAIL = {
      "query": `mutation InviteAccount( $companyid: ID!, $email: String!){
          inviteAccount(
            companyid : $companyid,
            email: $email,
          ) 
          {
            response {
              error
              code
              message
              messageinfo {
                messagelevel
                messagetitle
                message
              }
            }
            account {
              id
              email
              firstname
              lastname
            }
            role {
              id
              name
            }
          }
        }`,
      "variables": {
        "companyid": companyID,
        "email": email
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(INVITE_EMAIL, this)
      .then(response => {
        let backEndResponse = response.data.data.inviteAccount;
        let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
        return backEndResponseData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Update the role of an account - Account level - Administrator level */
  const updateAccountRole = async (companyId, accountId, roleId) => {
    let UPDATE_ACCOUNT_ROLE = {
      "query": `mutation UpdateAccountCompanyRole($companyid: ID!, $accountid: ID!, $roleid: Int!){
          updateAccountCompanyRole(
            companyid: $companyid,
            accountid: $accountid,
            roleid: $roleid
          )
          {
            response{
              code
              error
              message
              messageinfo {
                messagelevel
                messagetitle
                message
              }
            }
            accountcompany {
              account {
                id
                firstname
                lastname
                email
              }
              company {
                id
                name
              }
              role {
                id
                name
              }
            }
          }
        }`,
      "variables": {
        "companyid": companyId,
        "accountid": accountId,
        "roleid": parseInt(roleId)
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(UPDATE_ACCOUNT_ROLE, this)
      .then(response => {
        let backEndResponse = response.data.data.updateAccountCompanyRole;
        let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
        return backEndResponseData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Unbind an existing user account from an specific company - Company level - Administrator level */
  const unbindUser = async (accountId, companyId) => {
    let UNBIND_USER_COMPANY = {
      "query": `mutation DeleteAccountFromCompany($accountid: ID!, $companyid: ID!){
          deleteAccountFromCompany(
            accountid: $accountid,
            companyid: $companyid
          ){
            response {
              code
              error
              message
              messageinfo {
                messagelevel
                messagetitle
                message
              }
            }
          }
        }`,
      "variables": {
        "accountid": accountId,
        "companyid": companyId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(UNBIND_USER_COMPANY, this)
      .then(response => {
        let backEndResponse = response.data.data.deleteAccountFromCompany;
        let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
        return backEndResponseData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Creation of a new company - Company level */
  const createCompany = async (companyName, companyAddress, companyZipCode, companyCity, companyCountryId) => {
    let CREATE_COMPANY = {
      "query": `mutation CreateCompany($name: String!, $address: String, $zipcode: String!, $city: String, $countryid: Int!) {
          createCompany(
            name: $name,
            address: $address,
            zipcode: $zipcode,
            city: $city,
            countryid: $countryid
          ) 
          {
            response {
              code
              error
              message
              messageinfo {
                messagelevel
                messagetitle
                message
              }
            }
            company {
              id
              name
              address
              zipcode
              city
              country {
                id
                code
                name
              }
              activation
              security {
                roleid
                rolename
                companycreate
                k8create
                accountcreate
              }
            }
          }
        }`,
      "variables": {
        "name": companyName,
        "address": companyAddress,
        "zipcode": companyZipCode,
        "city": companyCity,
        "countryid": parseInt(companyCountryId)
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(CREATE_COMPANY, this)
      .then(response => {
        let backEndResponse = response.data.data.createCompany;
        let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
        return backEndResponseData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Update values of an existing company - Company level */
  const updateCompany = async (companyId, companyName, companyAddress, companyZipCode, companyCity, companyCountryId) => {
    let UPDATE_COMPANY = {
      "query": `mutation UpdateCompany($updateCompanyId: ID!, $name: String!, $address: String, $zipcode: String!, $city: String, $countryid: Int!){
          updateCompany(
            id: $updateCompanyId,
            name: $name,
            address: $address,
            zipcode: $zipcode,
            city: $city,
            countryid: $countryid
          ) 
          {
            response {
              code
              error
              message
              messageinfo {
                messagelevel
                messagetitle
                message
              }
            }
            company {
              id
              name
              address
              zipcode
              city
              country {
                id
              }
              activation
              security {
                roleid
                rolename
                companycreate
                k8create
                accountcreate
              }
            }
          }
        }`,
      "variables": {
        "updateCompanyId": companyId,
        "name": companyName,
        "address": companyAddress,
        "zipcode": companyZipCode,
        "city": companyCity,
        "countryid": parseInt(companyCountryId)
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(UPDATE_COMPANY, this)
      .then(response => {
        let backEndResponse = response.data.data.updateCompany;
        let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
        return backEndResponseData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Deletion of company - Company level */
  const deleteCompany = async (companyId) => {
    let DELETE_COMPANY = {
      "query": `mutation DeleteCompany($deleteCompanyId: ID!){
          deleteCompany(
            id: $deleteCompanyId
          ){
            response {
              code
              error
              message
              messageinfo {
                messagelevel
                messagetitle
                message
              }
            }
            company {
              id
              name
            }
          }
        }`,
      "variables": {
        "deleteCompanyId": companyId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(DELETE_COMPANY, this)
      .then(response => {
        let backEndResponse = response.data.data.deleteCompany;
        let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
        return backEndResponseData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Switch of company and set it as the last one used - Company level */
  const switchCompany = async (companyId) => {
    let SWITCH_COMPANY = {
      "query": `mutation ChangeAccountCompany($companyid: ID!){
          changeAccountCompany(
            companyid: $companyid
          ){
            response {
              code
              error
              message
              messageinfo {
                messagelevel
                messagetitle
                message
              }
            }
            company {
              id
              name
              activation
              security {
                roleid
                rolename
                companycreate
                k8create
                accountcreate
              }
              accountcompanies {
                account {
                  email
                  firstname
                  lastname
                }
                role {
                  id
                }
              }
            }
          }
        }`,
      "variables": {
        "companyid": companyId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(SWITCH_COMPANY, this)
      .then(response => {
        let backEndResponse = response.data.data.changeAccountCompany;
        let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
        return backEndResponseData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Creation of a new platform */
  const createPlatform = async (kubernetesId, companyId, kubernetesName, kubernetesClusterAddress, extIPRangeStart, extIPRangeEnd, kubernetesInfraProviderId, kubernetesPlanId, lainotikMonitoring) => {
    let CREATE_KUBERNETES = {
      "query": `mutation CreateKubernetesPlatform($k8Id: String, $companyid: ID!, $name: String!, $clusteraddress: String!, $externaliprangestart: String, $externaliprangeend: String, $infraproviderid: Int, $planid: Int, $lainotikmonitoring: Boolean){
          createKubernetesPlatform(
            k8id: $k8Id,
            companyid: $companyid,
            name: $name,
            clusteraddress: $clusteraddress,
            externaliprangestart: $externaliprangestart,
            externaliprangeend: $externaliprangeend,
            infraproviderid: $infraproviderid,
            planid: $planid,
            lainotikmonitoring: $lainotikmonitoring
          ) 
          {
            response {
              code
              error
              message
              messageinfo {
                message
                messagelevel
                messagetitle
              }
            }
            kubernetesplatform {
              id
              name
              demo
              state
              clsaddmon
              connectionstate
              k8labeldefault
              lainotikmonitoring
              infraprovider {
                id
                name
              }
              clusteraddress
              externaliprangestart
              externaliprangeend              
              skiptlsverify
              creationdate
              deploymentstateinfo {
                codeinfo
                codeinfolevel
                message
                messagelevel
                messagetitle
              }
              k8certs {
                id
                authmode
                clientcsr
                clientcrt
                cacrt
                token
                expirationdate
              }
              plan {
                id
                name
              }
              companyservices {
                id
                name
                state
                image
                release
                companyserviceapps {
                  id
                  name
                  state
                  enabled
                  editable
                  k8endpoints {
                    type
                    id
                    domain
                    clientkey
                    clientcrt
                  }
                  companyservice {
                    kubeplatform {
                      company {
                        cacrt
                      }
                    }
                  }
                  appclusters {
                    id
                    name
                    type
                    tooltip
                    url
                    image
                  }
                  appcomponents {
                    id
                    show
                    publicname
                    name
                    showhwresources
                    cpureq
                    cpureq_default
                    cpulimit
                    cpulimit_default
                    memreq
                    memreq_default
                    memlimit
                    memlimit_default
                    appcomponentpersistences {
                      id
                      name
                      storageclass
                      size
                      k8storage {
                        id
                      }
                    }
                    loadbalancer
                    podsnumber
                    podsnumber_default
                    podsnumber_deployed
                    podantiaffinity
                    nodeaffinity
                  }
                }
              }
            }
          }
        }`,
      "variables": {
        "k8Id": kubernetesId,
        "companyid": companyId,
        "name": kubernetesName,
        "clusteraddress": kubernetesClusterAddress,
        "externaliprangestart": extIPRangeStart,
        "externaliprangeend": extIPRangeEnd,
        "infraproviderid": parseInt(kubernetesInfraProviderId),
        "planid": parseInt(kubernetesPlanId),
        "lainotikmonitoring": lainotikMonitoring
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(CREATE_KUBERNETES, this)
      .then(response => {
        let backEndResponse = response.data.data.createKubernetesPlatform;
        let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
        return backEndResponseData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Updates the values of an already created platform */
  const updatePlatform = async (kubernetesId, kubernetesName, kubernetesClusterAddress, clientCRT, caCRT, extIPRangeStart, extIPRangeEnd, kubeAccessToken, authMode, skipTLSVerify) => {
    let UPDATE_KUBERNETES = {
      "query": `mutation UpdateKubernetesPlatform($k8Id: String!, $name: String!, $clusteraddress: String!, $clientcrt: String, $cacrt: String, $externaliprangestart: String, $externaliprangeend: String, $token: String, $authmode: String, $skiptlsverify: Boolean) {
          updateKubernetesPlatform(
            k8id: $k8Id,
            name: $name,
            clusteraddress: $clusteraddress,
            clientcrt: $clientcrt,
            cacrt: $cacrt,            
            externaliprangestart: $externaliprangestart,
            externaliprangeend: $externaliprangeend,
            token: $token,
            authmode: $authmode,
            skiptlsverify: $skiptlsverify
          ) 
          {
            response {
              code
              error
              message
              messageinfo {
                message
                messagelevel
                messagetitle
              }
            }
            kubernetesplatform {
              id
              demo
              name
              clsaddmon
              connectionstate
              k8labeldefault
              clusteraddress
              externaliprangestart
              externaliprangeend
              skiptlsverify
              infraprovider {
                id
                name
              }
              plan {
                id
                name
              }
              deploymentstateinfo {
                codeinfo
                codeinfolevel
                code
                messagelevel
                messagetitle
                message
              }
              k8certs {
                id
                authmode
                clientcsr
                clientcrt
                cacrt
                token
                expirationdate
              }
              k8nodes {
                id
                name
                maxpods
                cores
                memory
                usedcores
                usedmemory
                creation
              }
              k8storages {
                id
                default
                publicname
                reclaimpolicy
                volumebindingmode
                allowvolumeexpansion
                provisioner
              }
              k8labels {
                id
                name
                default
              }
              k8addons {
                id
                index
                publicname
                deployfinishedstate
                message
                lastnotificationdate
                deployby
                deployduration
                deploydate
                deploymentstateinfo {
                  codeinfolevel
                  codeinfo
                  code
                  messagelevel
                  messagetitle
                  message         
                }
              }
              security {
                accountmanage
              }
              companyservices {
                id
                name
                state
                image
                release
                companyserviceapps {
                  id
                  name
                  state
                  enabled
                  editable
                  k8endpoints {
                    type
                    id
                    domain
                    clientkey
                    clientcrt
                  }
                  companyservice {
                    kubeplatform {
                      company {
                        cacrt
                      }
                    }
                  }
                  appclusters {
                    id
                    name
                    type
                    tooltip
                    url
                    image
                  }
                  appcomponents {
                    id
                    show
                    publicname
                    name
                    showhwresources
                    cpureq
                    cpureq_default
                    cpulimit
                    cpulimit_default
                    memreq
                    memreq_default
                    memlimit
                    memlimit_default
                    appcomponentpersistences {
                      id
                      name
                      storageclass
                      size
                      k8storage {
                        id
                      }
                    }
                    loadbalancer
                    podsnumber
                    podsnumber_default
                    podsnumber_deployed
                    podantiaffinity
                    nodeaffinity
                  }
                }
              }
            }
          }
        }`,
      "variables": {
        "k8Id": kubernetesId,
        "name": kubernetesName,
        "clusteraddress": kubernetesClusterAddress,
        "clientcrt": clientCRT,
        "cacrt": caCRT,
        "externaliprangestart": extIPRangeStart,
        "externaliprangeend": extIPRangeEnd,
        "token": kubeAccessToken,
        "authmode": authMode,
        "skiptlsverify": skipTLSVerify
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(UPDATE_KUBERNETES, this)
      .then(response => {
        let backEndResponse = response.data.data.updateKubernetesPlatform;
        let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
        return backEndResponseData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* Sends a new default label value for all the applications - Setup Process */
  const newDefaultLabelValue = async (kubernetesId, defaultLabelId) => {
    let SET_DEFAULT_APP_LABEL = {
      "query": `mutation ($k8id: ID!, $labelid: ID!){
            setDefaultLabelAndStorage(
              k8id: $k8id,
              labelid: $labelid
            )
            {
              response {
                code
                error
                message
                messageinfo {
                  messagelevel
                  messagetitle
                  message
                }
              }
            }
          }`,
      "variables": {
        "k8id": kubernetesId,
        "labelid": defaultLabelId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(SET_DEFAULT_APP_LABEL, this)
      .then(response => {
        let setDefaultLabelResponse = response.data.data.setDefaultLabelAndStorage;
        if (setDefaultLabelResponse.response.code !== 200) {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: setDefaultLabelResponse.response.messageinfo.messagelevel,
                messagetitle: setDefaultLabelResponse.response.messageinfo.messagetitle,
                message: setDefaultLabelResponse.response.messageinfo.message
              }
            }
          });
          let backEndResponseData = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return backEndResponseData;
        } else {
          let backEndResponseData = setDefaultLabelResponse;
          return backEndResponseData;
        }
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Sends a validation request to setup a platform */
  const k8DeploymentRequest = async (kubernetesId) => {
    let SET_CERTS = {
      "query": `mutation ($k8id: ID!){
          createK8DeploymentRequest (
            k8id: $k8id
          )
          {
            response {
              code
              error
              message
              messageinfo {
                messagelevel
                messagetitle
                message
              }
            }
            kubernetesplatform {
              demo
              deploymentstateinfo {
                code
                codeinfo 
                codeinfolevel
                messagelevel
                messagetitle
                message
              }
            }
          }
        }`,
      "variables": {
        "k8id": kubernetesId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(SET_CERTS, this)
      .then(response => {
        let backEndResponse = response.data.data.createK8DeploymentRequest;
        let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
        return backEndResponseData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Sends a DELETE request for an specific platform - Platform level */
  const deletePlatform = async (kubernetesId) => {
    let DELETE_PLATFORM = {
      "query": `mutation DeleteKubernetesPlatform($k8Id: String!){
          deleteKubernetesPlatform (
            k8id: $k8Id
          )
          {
            code
            error
            message
            messageinfo {
              message
              messagelevel
              messagetitle
            }
          }
        }`,
      "variables": {
        "k8Id": kubernetesId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(DELETE_PLATFORM, this)
      .then(response => {
        let deleteKubernetes = response.data.data.deleteKubernetesPlatform;
        //TODO will need to include here .response when having it correctly encapsulled
        if (deleteKubernetes.code !== 200) {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: deleteKubernetes.response.messageinfo.messagelevel,
                messagetitle: deleteKubernetes.response.messageinfo.messagetitle,
                message: deleteKubernetes.response.messageinfo.message
              }
            }
          });
          let deleteKubernetesData = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return deleteKubernetesData;
        } else {
          localStorage.removeItem('KubernetesPlatform');
          let deleteKubernetesData = deleteKubernetes.messageinfo;
          return deleteKubernetesData;
        }
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Switch of platform and set it as the last one used - Platform level */
  const switchPlatform = async (kubernetesId) => {
    let SWITCH_PLATFORM = {
      "query": `mutation ChangeAccountPlatform($k8Id: ID!){
            changeAccountPlatform(
              k8id: $k8Id
            ){
              response {
                code
                error
                message
                messageinfo {
                  messagelevel
                  messagetitle
                  message
                }
              }
            }
          }`,
      "variables": {
        "k8Id": kubernetesId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(SWITCH_PLATFORM, this)
      .then(response => {
        let backEndResponse = response.data.data.changeAccountPlatform;
        let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
        return backEndResponseData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Sends a DEPLOY request for an specific application - App level */
  const deployServiceApp = async (appId) => {
    let DEPLOY_SERVICEAPP = {
      "query": `mutation DeployCompanyServiceApp($deployCompanyServiceAppId: ID!){
            deployCompanyServiceApp (
              id: $deployCompanyServiceAppId
            )
            {
              response {
                code
                error
                message
                messageinfo {
                  message
                  messagelevel
                  messagetitle
                }
              }
              companyserviceapp {
                id
                name
                state
                tooltip
                enabled
                message
                deployfinishedstate
                deploydate
                deployby
                deployduration
                deploynotificationerror
                onlineusercount
                deploymentstateinfo {
                  code
                  codeinfo
                  codeinfolevel
                  message
                  messagelevel
                  messagetitle
                }
                notifications {
                  id
                  name
                  phase
                  podscheduled
                  initialized
                  ready
                }
                appcomponents {
                  id
                  name
                  show
                  publicname
                  podsnumber
                  lowerlimitpods
                  quorumpods
                  loadbalancer
                  showhwresources
                  cpureq
                  cpureq_default
                  cpulimit
                  cpulimit_default
                  memreq
                  memreq_default
                  memlimit
                  memlimit_default
                  appcomponentprotocols {
                    enabled
                    protocol {
                      id
                      name
                    }
                  }
                  appcomponentconfigs {
                    id
                    publicname
                    value
                    defaultvalue
                    configtype
                    format
                    description
                    showdefault
                  }
                  appcomponentsecrets {
                    id
                    type
                    name
                    value
                  }
                }
                onlineusers {
                  id
                  account {
                    email
                  }
                }
                activation {
                  activated
                  starttime
                  endtime
                }
                security {
                  admin
                  console
                  tool
                }
              }
            }
          }`,
      "variables": {
        "deployCompanyServiceAppId": appId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(DEPLOY_SERVICEAPP, this)
      .then(response => {
        let datadeploy = response.data.data.deployCompanyServiceApp;
        if (datadeploy.response.code !== 200 && datadeploy.response.message !== "OK") {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: datadeploy.response.messageinfo.messagelevel,
                messagetitle: datadeploy.response.messageinfo.messagetitle,
                message: datadeploy.response.messageinfo.message
              }
            }
          });
          datadeploy = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return datadeploy
        } else return datadeploy;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Sends a DELETE request for an specific application - App level */
  const deleteServiceApp = async (appId) => {
    let DELETE_SERVICEAPP = {
      "query": `mutation DeleteCompanyServiceApp($deleteCompanyServiceAppId: ID!){
          deleteCompanyServiceApp (
              id: $deleteCompanyServiceAppId
            )
            {
              response {
                code
                error
                message
                messageinfo {
                  message
                  messagelevel
                  messagetitle
                }
              }
              companyserviceapp {
                id
                name
                state
                tooltip
                enabled
                message
                deployfinishedstate
                deploydate
                deployby
                deployduration
                deploynotificationerror
                onlineusercount
                deploymentstateinfo {
                  code
                  codeinfo
                  codeinfolevel
                  message
                  messagelevel
                  messagetitle
                }
                notifications {
                  id
                  name
                  phase
                  podscheduled
                  initialized
                  ready
                }
                appcomponents {
                  id
                  name
                  show
                  publicname
                  podsnumber
                  lowerlimitpods
                  quorumpods
                  loadbalancer
                  showhwresources
                  cpureq
                  cpureq_default
                  cpulimit
                  cpulimit_default
                  memreq
                  memreq_default
                  memlimit
                  memlimit_default
                  appcomponentprotocols {
                    enabled
                    protocol {
                      id
                      name
                    }
                  }
                  appcomponentconfigs {
                    id
                    publicname
                    value
                    defaultvalue
                    configtype
                    format
                    description
                    showdefault
                  }
                  appcomponentsecrets {
                    id
                    type
                    name
                    value
                  }           
                }
                onlineusers {
                  id
                  account {
                    email
                  }
                }
                activation {
                  activated
                  starttime
                  endtime
                }
                security {
                  admin
                  console
                  tool
                }
              }
            }
          }`,
      "variables": {
        "deleteCompanyServiceAppId": appId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(DELETE_SERVICEAPP, this)
      .then(response => {
        let datadelete = response.data.data.deleteCompanyServiceApp;
        if (datadelete.response.code !== 200 && datadelete.response.message !== "OK") {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: datadelete.response.messageinfo.messagelevel,
                messagetitle: datadelete.response.messageinfo.messagetitle,
                message: datadelete.response.messageinfo.message
              }
            }
          });
          datadelete = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return datadelete
        } else return datadelete;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Updates with a new component set of values and sends a deployment request for an specific component at ServiceApp component level */
  const updateAppComponent = async (componentId, appId, componentPods, loadBalancer, componentCPU, componentCPULimit, componentMem, componentMemLimit, componentLabelId, componentProtocol, componentPersistences) => {
    let UPDATE_APPCOMPONENT = {
      "query": `mutation UpdateAppComponentAndDeployApp($updateAppComponentAndDeployAppId: ID!, $appid: ID!, $podsnumber: Int!, $loadbalancer: Boolean, $cpureq: String, $cpulimit: String, $memreq: String, $memlimit: String, $labelid: String, $protocols: [SelectedProtocol], $persistences: [SelectedStorage]){
          updateAppComponentAndDeployApp(
            id: $updateAppComponentAndDeployAppId,
            appid: $appid,
            podsnumber: $podsnumber,
            loadbalancer: $loadbalancer,
            cpureq: $cpureq,
            cpulimit: $cpulimit,
            memreq: $memreq,
            memlimit: $memlimit,
            labelid: $labelid,
            protocols: $protocols,
            persistences: $persistences
          ) 
          {
            response {
              code
              error
              message
              messageinfo {
                message
                messagecode
                messagelevel
                messagetitle
              }
            }
            appcomponent {
              show
              podsnumber
              lowerlimitpods
              loadbalancer
              showhwresources
              cpureq
              cpureq_default
              cpulimit
              cpulimit_default
              memreq
              memreq_default
              memlimit
              memlimit_default
              k8label {
                id
                name
              }
              k8storage {
                id
                publicname
              }
              appcomponentprotocols {
                enabled
                protocol {
                  id
                  name
                }
              }
              appcomponentconfigs {
                id
                publicname
                value
                defaultvalue
                configtype
                format
                description
                showdefault
              }
              appcomponentsecrets {
                id
                type
                name
                value
              }
            }
          }
        }`,
      "variables": {
        "updateAppComponentAndDeployAppId": componentId,
        "appid": appId,
        "podsnumber": parseInt(componentPods),
        "loadbalancer": loadBalancer,
        "cpureq": String(componentCPU),
        "cpulimit": String(componentCPULimit),
        "memreq": String(componentMem),
        "memlimit": String(componentMemLimit),
        "labelid": componentLabelId,
        "protocols": componentProtocol,
        "persistences": componentPersistences
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(UPDATE_APPCOMPONENT, this)
      .then(response => {
        let dataupdatecomp = response.data.data.updateAppComponentAndDeployApp;
        if (dataupdatecomp.response.code !== BackEndConsts.RESPONSE_SUCCESS
          && dataupdatecomp.response.message !== "OK") {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: dataupdatecomp.response.messageinfo.messagelevel,
                messagetitle: dataupdatecomp.response.messageinfo.messagetitle,
                message: dataupdatecomp.response.messageinfo.message
              }
            }
          });
          dataupdatecomp = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return dataupdatecomp;
        } else return dataupdatecomp;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Launchs a certificate renewal request, App level, initially inside the API/Endpoint modal */
  const renewAppTLSCert = async (appId) => {
    let RENEW_APP_CERT = {
      "query": `mutation RenewAppCertificate($renewAppCertificateId: ID!){
          renewAppCertificate(
            id: $renewAppCertificateId
          )
          {
            response {
              code
              error
              message
              messageinfo {
                message
                messagelevel
                messagetitle
              }
            }
          }
        }`,
      "variables": {
        "renewAppCertificateId": appId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(RENEW_APP_CERT, this)
      .then(response => {
        let datarenewappcert = response.data.data.renewAppCertificate;
        if (datarenewappcert.response.code !== BackEndConsts.RESPONSE_SUCCESS
          && datarenewappcert.response.message !== "OK") {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: datarenewappcert.response.messageinfo.messagelevel,
                messagetitle: datarenewappcert.response.messageinfo.messagetitle,
                message: datarenewappcert.response.messageinfo.message
              }
            }
          });
          datarenewappcert = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return datarenewappcert;
        } else return datarenewappcert;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Updates the value of one of the component CONFIG parameters */
  const updateAppComponentConfig = async (id, value) => {
    let UPDATE_APPCOMPONENT_CONFIG = {
      "query": `mutation UpdateAppComponentConfig($updateAppComponentConfigId: ID!, $value: String!){
          updateAppComponentConfig(
            id: $updateAppComponentConfigId,
            value: $value
          )
          {
            response {
              code
              error
              message
              messageinfo {
                message
                messagelevel
                messagetitle
                messagecode
              }
            }
            config {
              value
            }
          }
        }`,
      "variables": {
        "updateAppComponentConfigId": id,
        "value": value
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(UPDATE_APPCOMPONENT_CONFIG, this)
      .then(response => {
        let dataupdatecompconfig = response.data.data.updateAppComponentConfig;
        if (dataupdatecompconfig.response.code !== BackEndConsts.RESPONSE_SUCCESS
          && dataupdatecompconfig.response.message !== "OK") {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: dataupdatecompconfig.response.messageinfo.messagelevel,
                messagetitle: dataupdatecompconfig.response.messageinfo.messagetitle,
                message: dataupdatecompconfig.response.messageinfo.message
              }
            }
          });
          dataupdatecompconfig = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return dataupdatecompconfig;
        } else return dataupdatecompconfig;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Updates the value of one of the component SECRET parameters */
  const updateAppComponentSecret = async (id, value) => {
    let UPDATE_APPCOMPONENT_SECRET = {
      "query": `mutation UpdateAppComponentSecret($updateAppComponentSecretId: ID!, $value: String!){
            updateAppComponentSecret(
              id: $updateAppComponentSecretId,
              value: $value
            )
            {
              response {
                code
                error
                message
                messageinfo {
                  message
                  messagelevel
                  messagetitle
                  messagecode
                }
              }
              secret {
                value
              }
            }
          }`,
      "variables": {
        "updateAppComponentSecretId": id,
        "value": value
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(UPDATE_APPCOMPONENT_SECRET, this)
      .then(response => {
        let dataupdatecompsecret = response.data.data.updateAppComponentSecret;
        if (dataupdatecompsecret.response.code !== BackEndConsts.RESPONSE_SUCCESS
          && dataupdatecompsecret.response.message !== "OK") {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: dataupdatecompsecret.response.messageinfo.messagelevel,
                messagetitle: dataupdatecompsecret.response.messageinfo.messagetitle,
                message: dataupdatecompsecret.response.messageinfo.message
              }
            }
          });
          dataupdatecompsecret = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return dataupdatecompsecret;
        } else return dataupdatecompsecret;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  return {
    register,
    updateUserProfile,
    login,
    forgotPassword,
    changePassword,
    requestAccountActivation,
    inviteEmail,
    updateAccountRole,
    unbindUser,
    createCompany,
    updateCompany,
    deleteCompany,
    switchCompany,
    createPlatform,
    updatePlatform,
    newDefaultLabelValue,
    k8DeploymentRequest,
    deletePlatform,
    switchPlatform,
    deployServiceApp,
    deleteServiceApp,
    updateAppComponent,
    renewAppTLSCert,
    updateAppComponentConfig,
    updateAppComponentSecret
  };
};

export { createMutationsLainotik };