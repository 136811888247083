import React from "react";
import { dataService } from "tools/dataService/dataService";
import * as BackEndConsts from "tools/constants/backEndConstants";
import {
  handleInputChange,
  updateActiveCompanyLS,
  CustomField
} from "tools/commonFunctions";
import Cookies from "universal-cookie";
import SimpleFooter from "components/Footer/SimpleFooter";
import { SHOW_SIGNUP } from "tools/config";
import i18n from "localization/i18n";
import { withTranslation } from "react-i18next";
import { trackPromise } from 'react-promise-tracker';
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Label,
  Button
} from "reactstrap";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

let cookies = new Cookies();

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountEmail: "",
      accountPassword: "",
      isPasswordShown: false
    }
  };

  componentDidMount() {
    i18n.changeLanguage(i18n.language || 'en');
  }

  handleInputChangeLoc = (e) => {
    let inputResponse = handleInputChange(e)

    this.setState({
      [inputResponse.name]: inputResponse.value
    })
  };

  // Function to extract the top-level domain and second-level domain from the hostname
  getCookieDomainFromHostname() {
    // Split the hostname into parts
    const hostnameParts = window.location.hostname.split('.');
    // Take the last two parts of the hostname, usually the SLD and TLD
    const domainParts = hostnameParts.slice(-2);
    // Join them with a dot and prepend with a dot to make the cookie accessible on all subdomains
    return '.' + domainParts.join('.');
  }

  handleFormSubmit = (e) => {
    trackPromise(
      dataService.mutations.login(this.state.accountEmail, this.state.accountPassword).then(
        (backEndResponseData) => {
          if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            localStorage.setItem('AccountInfo', JSON.stringify(backEndResponseData));

            updateActiveCompanyLS(backEndResponseData.account.lastcompany)

            let activePlatformDataObj = {
              PlatformId: backEndResponseData.account.lastplatform.id,
              PlatformName: backEndResponseData.account.lastplatform.name,
              PlatformStatusLevel: backEndResponseData.account.lastplatform.deploymentstateinfo.codeinfolevel,
              PlatformStatus: backEndResponseData.account.lastplatform.deploymentstateinfo.codeinfo,
              PlatformAccessStatus: backEndResponseData.account.lastplatform.clsaddmon,
              PlatformConnectStatus: backEndResponseData.account.lastplatform.connectionstate,
              PlatformHealthCheckClass: (backEndResponseData.account.lastplatform.clsaddmon === "ACCESIBLE" &&
                backEndResponseData.account.lastplatform.connectionstate === "CONNECTED") ? "running" : "notrunning"
            }
            localStorage.setItem('ActivePlatformData', JSON.stringify(activePlatformDataObj))

            // Set the Cookie
            const cookieValue = backEndResponseData.accesstoken;
            const expiresAt = 60 * 24;
            let date = new Date();
            date.setTime(date.getTime() + (expiresAt * 60 * 1000));

            // Try to get the value of the domain from the env variable or uses a default value get from hostname
            const domain = process.env.REACT_APP_COOKIE_DOMAIN || this.getCookieDomainFromHostname();

            const options = { path: '/', expires: date, ...(domain && { domain }) };
            cookies.set('authLainotik', cookieValue, options);

            this.props.history.push('/portal/kubernetes');
          } else return null;
        }
      )
    )
  };

  togglePasswordVisibility = (e) => {
    this.setState({
      isPasswordShown: !this.state.isPasswordShown
    })
  }

  getValidationSchema = () => {
    const t = this.props.t;
    return Yup.object().shape({
      accountEmail: Yup.string()
        .email(t('login.loginError.incorrectUsername'))
        .required(t('login.loginError.requiredUsername')),
      accountPassword: Yup.string()
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).\S{8,}$/, t(
            'login.loginError.incorrectPassword')
        )
        .required(t('login.passwordPlaceholder'))
    });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <Container className="h-100vh">
          <Row className="justify-content-center align-items-center">
            <Col md="6">
              <div className="content">
                <img
                  className="lainotik-logo"
                  alt="Lainotik logo"
                  src={require('assets/img/condensed-pink.svg').default} />
              </div>
            </Col>
          </Row>
          <div className="login-form-wrapper">
            <Row className="justify-content-center align-items-center">
              <Col md="6">
                <section className="section-title">
                  <h1>{t("login.loginButton")}</h1>
                </section>
              </Col>
            </Row>
            {SHOW_SIGNUP === true &&
              <Row className="justify-content-center align-items-center">
                <Col md="6">
                  <fieldset>
                    <span>
                      {t("signup.haventAccount")}<Link to="/signup">{t("signup.signupButton")}</Link>
                    </span>
                  </fieldset>
                </Col>
              </Row>
            }
            <Formik
              initialValues={{
                accountEmail: this.state.accountEmail,
                accountPassword: this.state.accountPassword
              }}
              validationSchema={this.getValidationSchema()}
              onSubmit={this.handleFormSubmit}>
              {({ ...formikProps }) => (
                <Form className="form">
                  <Row className="justify-content-center align-items-center">
                    <Col md="6">
                      <fieldset>
                        <Label htmlFor="accountemail" sm="auto">{t("login.usernameLabel")}</Label>
                        <CustomField
                          type="email"
                          id="accountemail"
                          name="accountEmail"
                          className="form-control"
                          sm="auto"
                          onChange={(e) => { this.handleInputChangeLoc(e) }}
                          placeholder={t("login.usernamePlaceholder")}
                          form={formikProps} />
                        <div className="form-password-wrapper">
                          <Label htmlFor="accountpassword" sm="auto">{t("login.passwordLabel")}</Label>
                          <Button className="form-btn-show-password" onClick={this.togglePasswordVisibility}>
                            <i className={this.state.isPasswordShown ? "icon-eye-closed" : "icon-eye"}></i>
                            <div>{this.state.isPasswordShown
                              ? t("common.passwordIcon.hide")
                              : t("common.passwordIcon.show")}
                            </div>
                          </Button>
                        </div>
                        <CustomField
                          type={this.state.isPasswordShown ? "text" : "password"}
                          id="accountpassword"
                          className="login-pass form-control"
                          sm="auto"
                          name="accountPassword"
                          onChange={(e) => { this.handleInputChangeLoc(e) }}
                          placeholder={t("login.passwordPlaceholder")}
                          form={formikProps} />
                      </fieldset>
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-items-baseline">
                    <Col md="3">
                      <fieldset>
                        <span>
                          <Link to="/forgotpassword">{t("forgotPassword.title")}</Link>
                        </span>
                      </fieldset>
                    </Col>
                    <Col md="3">
                      <Button
                        className="form-btn align-right"
                        type="submit"
                        value="Submit">{t("login.loginButton")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
            <SimpleFooter />
          </div>
        </Container>
      </>
    );
  }
}
export default withTranslation()(Login);