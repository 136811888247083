let graphQLHost;
let rejectUnauthorized;
let showSignUp;

const protocol = window && window.location && window.location.protocol; // Just the protocol http:// or https://
const hostname = window && window.location && window.location.hostname; // Just the domain without the portnumber

const url = protocol + "//" + hostname + ":" + process.env.REACT_APP_GRAPHQL_PORT;
//Local domains were instead of dinamic protocol https has to be hardcoded and at the same time taken as "untrusted"
const localDomains = ["minikube", "localhost"];

if (localDomains.some(e => hostname.includes(e))) {
  showSignUp = true;
  rejectUnauthorized = false;
  console.log('untrusted connection');
  graphQLHost = "https://" + hostname + ":" + process.env.REACT_APP_GRAPHQL_PORT;
} else {
  showSignUp = false;
  rejectUnauthorized = true;
  graphQLHost = url;
}

const GRAPH_QL_API_URL = `${graphQLHost}`
const REJECT_UNAUTHORIZED = rejectUnauthorized
const SHOW_SIGNUP = showSignUp

export { 
  GRAPH_QL_API_URL,
  REJECT_UNAUTHORIZED,
  SHOW_SIGNUP
}