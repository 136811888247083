import React from "react";
import * as BackEndConsts from "tools/constants/backEndConstants";
import {
  platformNotifMessages,
  updateActivePlatformLS,
  handleDate,
  handleChangeDropdown,
  platformStateTag,
  CustomField
} from "tools/commonFunctions";
import { dataService } from "tools/dataService/dataService"
import { trackPromise } from "react-promise-tracker";
import UserContextInfo from "tools/userContextInfo";
import GenericModal from "components/Modal/GenericModal";

import {
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Collapse
} from "reactstrap";

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import MonitoringServices from "./MonitoringServices";
// Platform setup and status components
import OverviewSettings from "./setup/overviewSettings";
import AccessSetup from "./setup/accessSetup";
import NodesSetup from "./setup/nodesSetup";
import StorageSetup from "./setup/storageSetup";
import PlatformStatusArea from "./status/platformStatusArea";
class KubernetesSettings extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.accordionRef = React.createRef();
    this.platformDeploymentStateForLoopStart = [
      BackEndConsts.DEPLOYMENT_PLATFORM_STATE_ADDONS,
      BackEndConsts.DEPLOYMENT_PLATFORM_STATE_MONITORING]

    this.state = {
      companyServices: this.props.kubernetesPlatform.companyservices,
      kubernetesId: this.props.kubernetesId,
      kubernetesStateCode: this.props.kubernetesPlatform.deploymentstatecode,
      kubernetesName: "",
      kubeDemo: false,
      kubernetesClusterAddress: "",
      extIPRangeStart: "",
      extIPRangeEnd: "",
      kubernetesInfraProviderId: 0,
      kubernetesPlanId: 0,
      healthCheckMessage: this.t("kubernetesSettings.healthCheck.setupNotFinishedDemo"),
      skipTLSVerify: false,
      authMode: "",
      kubeAccessToken: "",
      clientCSR: "",
      clientCRT: "",
      caCRT: "",
      kubeSettings: [],
      dropdownDisable: true,
      submitButtonDisable: true,
      deletePlatformModal: false,
      helpModal: false,
      csrModal: false,
      isFormValid: false,
      platformUpdated: false,
      kubernetesMessage: "",
      kubernetesMessageTitle: "",
      kubernetesMessageLevel: "",
      step1Validated: false,
      step2Validated: false,
      step3Validated: false,
      platStatusAccordionOpened: false
    }
  };

  componentDidMount() {
    this.getPlatformInfo(false);
    this.getMonitoringDeployInfo(5000, false);
    this.checkIfSuperUser();
  };

  componentDidUpdate(prevProps) {
    const t = this.t;
    if (this.props.kubernetesPlatform.id !== prevProps.kubernetesPlatform.id) {
      this.setState({
        kubernetesId: this.props.kubernetesPlatform.id,
        kubernetesName: this.props.kubernetesPlatform.name,
        kubeDemo: this.props.kubernetesPlatform.demo,
        kubernetesClusterAddress: this.props.kubernetesPlatform.clusteraddress,
        extIPRangeStart: this.props.kubernetesPlatform.externaliprangestart,
        extIPRangeEnd: this.props.kubernetesPlatform.externaliprangeend,
        kubernetesState: this.props.kubernetesPlatform.deploymentstateinfo.codeinfo,
        kubernetesStateLevel: this.props.kubernetesPlatform.deploymentstateinfo.codeinfolevel,
        companyServices: this.props.isEmpty ? [] : this.props.kubernetesPlatform.companyservices,
        skipTLSVerify: this.props.isEmpty ? "" : this.props.kubernetesPlatform.skiptlsverify,
        authMode: this.props.isEmpty ? "" : this.props.kubernetesPlatform.k8certs[0].authmode,
        kubeAccessToken: this.props.isEmpty ? "" : this.props.kubernetesPlatform.k8certs[0].token,
        clientCSR: this.props.isEmpty ? "" : this.props.kubernetesPlatform.k8certs[0].clientcsr,
        clientCRT: this.props.isEmpty ? "" : this.props.kubernetesPlatform.k8certs[0].clientcrt,
        caCRT: this.props.isEmpty ? "" : this.props.kubernetesPlatform.k8certs[0].cacrt,
        certExpirationDate: this.props.isEmpty ? "" : this.props.kubernetesPlatform.k8certs[0].expirationdate,
        kubeAccessStatus: this.props.kubernetesPlatform.clsaddmon,
        kubeConnectStatus: this.props.kubernetesPlatform.connectionstate,
        lainotikMonitoring: this.props.kubernetesPlatform.lainotikmonitoring,
        step1Validated: this.props.kubernetesPlatform.connectionstate === BackEndConsts.PLATFORM_CONNECTION_STATUS_CONNECTED
          ? true : false,
        k8Nodes: this.props.kubernetesPlatform.k8nodes,
        k8Labels: this.props.kubernetesPlatform.k8labels,
        step2Validated: (
          (this.props.kubernetesPlatform.k8nodes
            && this.props.kubernetesPlatform.k8nodes.constructor === Array
            && this.props.kubernetesPlatform.k8nodes.length !== 0)
          && this.props.kubernetesPlatform.k8labeldefault !== null)
          ? true : false,
        k8Storages: this.props.kubernetesPlatform.k8storages,
        step3Validated: (this.props.kubernetesPlatform.k8storages
          && this.props.kubernetesPlatform.k8storages.constructor === Array
          && this.props.kubernetesPlatform.k8storages.length !== 0)
          ? true : false,
        k8Addons: this.props.kubernetesPlatform.k8addons,
        submitButtonCaption: this.props.isEmpty ? t("common.start") : t("common.update"),
        dropdownDisable: this.props.isEmpty ? false : true,
        modalTitleText: "",
        modalBodyText: "",
        modalAcceptBtn: "",
        modalCancelBtn: "",
        SettingsOpened: this.props.isEmpty ? true : false,
        kubernetesStateCode: this.props.kubernetesPlatform.deploymentstateinfo.code,
        kubernetesState: this.props.kubernetesPlatform.deploymentstateinfo.codeinfo,
        kubernetesStateLevel: this.props.kubernetesPlatform.deploymentstateinfo.codeinfolevel,
        platformUpdated: false,
        kubernetesInfraProviderId: this.props.kubernetesPlatform.infraprovider.id,
        kubernetesPlanId: this.props.kubernetesPlatform.plan.id
      })
    }
    if (this.props.isEmpty === true &&
      this.props.isEmpty !== prevProps.isEmpty) {
      this.handleHealthCheckInfo()
    }
  }

  getPlatformInfo(showNotif) {
    let kubernetes = this.props.kubernetesPlatform;
    const t = this.t;

    if (!kubernetes.isEmpty) {
      trackPromise(
        dataService.queries.getKubernetesDeployInfo(
          this.props.kubernetesId
        ).then(
          (backEndResponseData) => {
            if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
              this.setState({
                skipTLSVerify: backEndResponseData.kubernetesplatform.skiptlsverify,
                authMode: backEndResponseData.kubernetesplatform.k8certs[0].authmode,
                kubeAccessToken: backEndResponseData.kubernetesplatform.k8certs[0].token,
                clientCSR: backEndResponseData.kubernetesplatform.k8certs[0].clientcsr,
                clientCRT: backEndResponseData.kubernetesplatform.k8certs[0].clientcrt,
                caCRT: backEndResponseData.kubernetesplatform.k8certs[0].cacrt,
                certExpirationDate: backEndResponseData.kubernetesplatform.k8certs[0].expirationdate,
                kubernetesId: backEndResponseData.kubernetesplatform.id,
                kubernetesName: backEndResponseData.kubernetesplatform.name,
                kubeDemo: backEndResponseData.kubernetesplatform.demo,
                kubeAccessStatus: backEndResponseData.kubernetesplatform.clsaddmon,
                kubeConnectStatus: backEndResponseData.kubernetesplatform.connectionstate,
                step1Validated: backEndResponseData.kubernetesplatform.connectionstate === BackEndConsts.PLATFORM_CONNECTION_STATUS_CONNECTED
                  ? true : false,
                kubeDefaultLabelName: backEndResponseData.kubernetesplatform.k8labeldefault,
                kubernetesClusterAddress: backEndResponseData.kubernetesplatform.clusteraddress,
                extIPRangeStart: backEndResponseData.kubernetesplatform.externaliprangestart,
                extIPRangeEnd: backEndResponseData.kubernetesplatform.externaliprangeend,
                kubernetesInfraProviderId: backEndResponseData.kubernetesplatform.infraprovider.id,
                kubernetesPlanId: backEndResponseData.kubernetesplatform.plan.id,
                lainotikMonitoring: backEndResponseData.kubernetesplatform.lainotikmonitoring,
                k8Nodes: backEndResponseData.kubernetesplatform.k8nodes,
                k8Labels: backEndResponseData.kubernetesplatform.k8labels,
                step2Validated: (
                  (backEndResponseData.kubernetesplatform.k8nodes
                    && backEndResponseData.kubernetesplatform.k8nodes.constructor === Array
                    && backEndResponseData.kubernetesplatform.k8nodes.length !== 0)
                  && backEndResponseData.kubernetesplatform.k8labeldefault !== null)
                  ? true : false,
                k8Storages: backEndResponseData.kubernetesplatform.k8storages,
                step3Validated: (backEndResponseData.kubernetesplatform.k8storages
                  && backEndResponseData.kubernetesplatform.k8storages.constructor === Array
                  && backEndResponseData.kubernetesplatform.k8storages.length !== 0)
                  ? true : false,
                k8Addons: backEndResponseData.kubernetesplatform.k8addons,
                submitButtonCaption: kubernetes.isEmpty ? t("common.start") : t("common.update"),
                dropdownDisable: kubernetes.isEmpty ? false : true,
                modalTitleText: "",
                modalBodyText: "",
                modalAcceptBtn: "",
                modalCancelBtn: "",
                SettingsOpened: kubernetes.isEmpty ? true : false,
                kubernetesStateCode: backEndResponseData.kubernetesplatform.deploymentstateinfo.code,
                kubernetesState: backEndResponseData.kubernetesplatform.deploymentstateinfo.codeinfo,
                kubernetesStateLevel: backEndResponseData.kubernetesplatform.deploymentstateinfo.codeinfolevel,
                platformUpdated: false
              })

              if (showNotif === true) {
                platformNotifMessages(
                  backEndResponseData.response.messageinfo.messagelevel,
                  backEndResponseData.response.messageinfo.messagetitle,
                  backEndResponseData.response.messageinfo.message
                )
              }

              if (this.state.kubernetesId === this.props.activePlatformId) {
                updateActivePlatformLS(
                  this.state.kubernetesId,
                  this.state.kubernetesName,
                  this.state.kubernetesStateLevel,
                  this.state.kubernetesState,
                  this.state.kubeAccessStatus,
                  this.state.kubeConnectStatus,
                  this.state.healthCheckMessage);
              } else null

            } else return null
          }
        ).then(
          () => {
            this.handleAccessDisabled();
            this.handleHealthCheckInfo();
            //Call to refresh deployment data every X seconds when state being "124" or "125" *backEndConstants.js
            if (!this.state.kubeDemo
              && this.state.kubernetesStateCode
              && this.platformDeploymentStateForLoopStart.some(e => e === this.state.kubernetesStateCode)) {
              this.callLoop()
            } else return null
          }
        )
      )
    } else { //This is the set/init for an empty platorm, it doesn't exists on the database yet
      kubernetes.k8certs.map((certs) => {
        return (
          this.setState({
            authMode: certs.authmode,
            kubeAccessToken: certs.token,
            clientCSR: certs.clientcsr,
            clientCRT: certs.clientcrt,
            caCRT: certs.cacrt,
          })
        )
      })
      this.setState({
        kubernetesId: kubernetes.id,
        kubernetesName: kubernetes.name,
        kubeDemo: kubernetes.demo,
        kubernetesClusterAddress: kubernetes.clusteraddress,
        skipTLSVerify: kubernetes.skiptlsverify,
        extIPRangeStart: kubernetes.externaliprangestart,
        extIPRangeEnd: kubernetes.externaliprangeend,
        kubernetesInfraProviderId: kubernetes.infraprovider.id,
        kubernetesPlanId: kubernetes.plan.id,
        lainotikMonitoring: kubernetes.lainotikmonitoring,
        submitButtonCaption: kubernetes.isEmpty ? t("common.start") : t("common.update"),
        dropdownDisable: kubernetes.isEmpty ? false : true,
        modalTitleText: "",
        modalBodyText: "",
        modalAcceptBtn: "",
        modalCancelBtn: "",
        kubernetesState: kubernetes.deploymentstateinfo.codeinfo,
        kubernetesStateLevel: kubernetes.deploymentstateinfo.codeinfolevel,
        platformUpdated: false,
        SettingsOpened: kubernetes.isEmpty ? true : false
      })
    }
  }

  handleHealthCheckInfo = () => {
    const t = this.t;

    if (this.state.kubeAccessStatus === "ACCESIBLE" && this.state.kubeConnectStatus === "CONNECTED") {
      this.setState({
        healthCheckClass: "running",
        healthCheckMessage: t("kubernetesSettings.healthCheck.connected")
      })
    } else if (this.state.kubeAccessStatus === "NOTACCESIBLE" || this.state.kubeConnectStatus === "NOTCONNECTED") {
      if (this.state.kubeAccessStatus === "NOTACCESIBLE") {
        this.setState({
          healthCheckClass: "notrunning",
          healthCheckMessage: t("kubernetesSettings.healthCheck.cannotAccess")
        })
      } else {
        this.setState({
          healthCheckClass: "notrunning",
          healthCheckMessage: t("kubernetesSettings.healthCheck.cannotConnect")
        })
      }
    } else return null
  }

  getMonitoringDeployInfo(intervalTiming, showNotif) {
    let serviceId;

    this.props.kubernetesPlatform.companyservices && this.props.kubernetesPlatform.companyservices.map((KubeService) => {
      if (KubeService.name === "k8platform") {
        serviceId = KubeService.companyserviceapps[0].id
        return serviceId
      } else return null
    })

    let CurrentIntervalId = localStorage.getItem(['IntervalId-' + serviceId]);

    if (serviceId !== undefined) {
      trackPromise(
        dataService.queries.getMonitoringAppData(
          serviceId
        ).then(
          (dataapp) => {
            if (dataapp !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
              this.setState({
                appNotifications: dataapp.notifications,
                monitoringServiceId: serviceId,
                monitoringDeployCode: dataapp.deploymentstateinfo.code,
                monitoringDeployCodeInfo: dataapp.deploymentstateinfo.codeinfo,
                monitoringDeployDate: handleDate(dataapp.deploydate).dateFormatted,
                monitoringDeployDuration: dataapp.deployduration,
                monitoringDeployBy: dataapp.deployby,
                monitoringDeployMessageLevel: dataapp.deploymentstateinfo.messagelevel,
                monitoringDeployMessageTitle: dataapp.deploymentstateinfo.messagetitle,
                monitoringDeployMessage: dataapp.deploymentstateinfo.message,
                kubernetesStateCode: dataapp.companyservice.kubeplatform.deploymentstatecode
              })

              if (showNotif === true) {
                platformNotifMessages(
                  dataapp.deploymentstateinfo.messagelevel,
                  dataapp.deploymentstateinfo.messagetitle,
                  dataapp.deploymentstateinfo.message
                )
              }
            } else return null;
          }
        ).then(
          () => {
            if (this.state.kubernetesStateCode
              && this.state.kubernetesStateCode === BackEndConsts.DEPLOYMENT_PLATFORM_STATE_MONITORING) {
              if (localStorage.getItem(['IntervalId-' + serviceId])) {
                clearInterval(CurrentIntervalId);
                this.requestDeployLoop(true, serviceId, intervalTiming);
              } else this.requestDeployLoop(true, serviceId, intervalTiming);
            } else return null
          }
        )
      )
    } else return null;
  }

  getDeployStatus(elementToLoopId, intervalId, showNotif) {
    if (!this.state.kubeDemo
      || this.state.kubeConnectStatus === BackEndConsts.PLATFORM_CONNECTION_STATUS_REQUESTED) {
      if (this.state.kubernetesStateCode
        && this.state.kubernetesStateCode === BackEndConsts.DEPLOYMENT_PLATFORM_STATE_MONITORING) {

        let serviceId;

        this.props.kubernetesPlatform.companyservices && this.props.kubernetesPlatform.companyservices.map((KubeService) => {
          if (KubeService.name === "k8platform") {
            serviceId = KubeService.companyserviceapps[0].id
            return serviceId
          } else return null
        })

        let CurrentIntervalId = localStorage.getItem(['IntervalId-' + serviceId]);

        //Loop query without the trackpromise to not show the spinner
        dataService.queries.getMonitoringAppData(
          serviceId
        ).then(
          (dataapp) => {
            if (dataapp !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
              this.setState({
                appNotifications: dataapp.notifications,
                monitoringServiceId: serviceId,
                monitoringDeployCode: dataapp.deploymentstateinfo.code,
                monitoringDeployCodeInfo: dataapp.deploymentstateinfo.codeinfo,
                monitoringDeployDate: handleDate(dataapp.deploydate).dateFormatted,
                monitoringDeployDuration: dataapp.deployduration,
                monitoringDeployBy: dataapp.deployby,
                monitoringDeployMessageLevel: dataapp.deploymentstateinfo.messagelevel,
                monitoringDeployMessageTitle: dataapp.deploymentstateinfo.messagetitle,
                monitoringDeployMessage: dataapp.deploymentstateinfo.message,
                kubernetesStateCode: dataapp.companyservice.kubeplatform.deploymentstatecode,
                kubernetesState: dataapp.companyservice.kubeplatform.deploymentstateinfo.codeinfo,
                kubernetesStateLevel: dataapp.companyservice.kubeplatform.deploymentstateinfo.codeinfolevel
              })
            } else return null;
          }
        ).then(
          () => {
            if (this.state.kubernetesStateCode
              && this.state.kubernetesStateCode === BackEndConsts.DEPLOYMENT_PLATFORM_STATE_MONITORING) {
              localStorage.setItem(['IntervalId-' + serviceId], intervalId)
            } else {
              return (
                this.handleDeploymentFinishedState(
                  serviceId,
                  CurrentIntervalId,
                  this.state.monitoringDeployMessageLevel,
                  this.state.monitoringDeployMessageTitle,
                  this.state.monitoringDeployMessage,
                )
              )
            }
          }
        )
      }
      else if (this.state.kubernetesStateCode === BackEndConsts.DEPLOYMENT_PLATFORM_STATE_ADDONS
        || this.state.kubeConnectStatus === BackEndConsts.PLATFORM_CONNECTION_STATUS_REQUESTED) {
        //Loop query without the trackpromise to not show the spinner
        dataService.queries.getKubernetesDeployInfo(
          elementToLoopId
        ).then(
          (backEndResponseData) => {
            if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
              this.setState({
                skipTLSVerify: backEndResponseData.kubernetesplatform.skiptlsverify,
                authMode: backEndResponseData.kubernetesplatform.k8certs[0].authmode,
                kubeAccessToken: backEndResponseData.kubernetesplatform.k8certs[0].token,
                clientCSR: backEndResponseData.kubernetesplatform.k8certs[0].clientcsr,
                clientCRT: backEndResponseData.kubernetesplatform.k8certs[0].clientcrt,
                caCRT: backEndResponseData.kubernetesplatform.k8certs[0].cacrt,
                certExpirationDate: backEndResponseData.kubernetesplatform.k8certs[0].expirationdate,
                kubernetesId: backEndResponseData.kubernetesplatform.id,
                kubernetesName: backEndResponseData.kubernetesplatform.name,
                kubeDemo: backEndResponseData.kubernetesplatform.demo,
                kubeAccessStatus: backEndResponseData.kubernetesplatform.clsaddmon,
                kubeConnectStatus: backEndResponseData.kubernetesplatform.connectionstate,
                step1Validated: backEndResponseData.kubernetesplatform.connectionstate === BackEndConsts.PLATFORM_CONNECTION_STATUS_CONNECTED
                  ? true : false,
                kubeDefaultLabelName: backEndResponseData.kubernetesplatform.k8labeldefault,
                kubernetesClusterAddress: backEndResponseData.kubernetesplatform.clusteraddress,
                extIPRangeStart: backEndResponseData.kubernetesplatform.externaliprangestart,
                extIPRangeEnd: backEndResponseData.kubernetesplatform.externaliprangeend,
                kubernetesInfraProviderId: backEndResponseData.kubernetesplatform.infraprovider.id,
                kubernetesPlanId: backEndResponseData.kubernetesplatform.plan.id,
                k8Nodes: backEndResponseData.kubernetesplatform.k8nodes,
                k8Labels: backEndResponseData.kubernetesplatform.k8labels,
                step2Validated: (
                  (backEndResponseData.kubernetesplatform.k8nodes
                    && backEndResponseData.kubernetesplatform.k8nodes.constructor === Array
                    && backEndResponseData.kubernetesplatform.k8nodes.length !== 0)
                  && backEndResponseData.kubernetesplatform.k8labeldefault !== null)
                  ? true : false,
                k8Storages: backEndResponseData.kubernetesplatform.k8storages,
                step3Validated: (backEndResponseData.kubernetesplatform.k8storages
                  && backEndResponseData.kubernetesplatform.k8storages.constructor === Array
                  && backEndResponseData.kubernetesplatform.k8storages.length !== 0)
                  ? true : false,
                k8Addons: backEndResponseData.kubernetesplatform.k8addons,
                kubernetesStateCode: backEndResponseData.kubernetesplatform.deploymentstateinfo.code,
                kubernetesState: backEndResponseData.kubernetesplatform.deploymentstateinfo.codeinfo,
                kubernetesStateLevel: backEndResponseData.kubernetesplatform.deploymentstateinfo.codeinfolevel,
                kubernetesDeployMessageLevel: backEndResponseData.kubernetesplatform.deploymentstateinfo.messagelevel,
                kubernetesDeployMessageTitle: backEndResponseData.kubernetesplatform.deploymentstateinfo.messagetitle,
                kubernetesDeployMessage: backEndResponseData.kubernetesplatform.deploymentstateinfo.message
              })
            } else return null;
          }
        ).then(
          () => {
            if ((this.state.kubernetesStateCode
              && this.state.kubernetesStateCode === BackEndConsts.DEPLOYMENT_PLATFORM_STATE_ADDONS)
              || this.state.kubeConnectStatus === BackEndConsts.PLATFORM_CONNECTION_STATUS_REQUESTED) {
              localStorage.setItem(['IntervalId-' + elementToLoopId], intervalId);
            } else {
              return (
                this.handleDeploymentFinishedState(
                  elementToLoopId,
                  intervalId,
                  this.state.kubernetesDeployMessageLevel,
                  this.state.kubernetesDeployMessageTitle,
                  this.state.kubernetesDeployMessage,
                )
              );
            }
          }
        )
      }
    } else return null;
  }

  callLoop() {
    let intervalTiming = 5000;
    let serviceId;

    this.props.kubernetesPlatform.companyservices && this.props.kubernetesPlatform.companyservices.map((KubeService) => {
      if (KubeService.name === "k8platform") {
        serviceId = KubeService.companyserviceapps[0].id
        return serviceId
      } else return null
    })

    if (!this.state.kubeDemo
      && ((this.state.kubernetesStateCode
        && this.state.kubernetesStateCode === BackEndConsts.DEPLOYMENT_PLATFORM_STATE_ADDONS)
        || this.state.kubeConnectStatus === BackEndConsts.PLATFORM_CONNECTION_STATUS_REQUESTED)) {
      if (localStorage.getItem(['IntervalId-' + this.state.kubernetesId])) {
        let CurrentIntervalId = localStorage.getItem(['IntervalId-' + this.state.kubernetesId]);
        clearInterval(CurrentIntervalId);
        this.requestDeployLoop(true, this.state.kubernetesId, intervalTiming);
      } else this.requestDeployLoop(true, this.state.kubernetesId, intervalTiming);
    } else if (this.state.kubernetesStateCode
      && this.state.kubernetesStateCode === BackEndConsts.DEPLOYMENT_PLATFORM_STATE_MONITORING) {
      let CurrentIntervalId = localStorage.getItem(['IntervalId-' + serviceId]);
      clearInterval(CurrentIntervalId);
      this.requestDeployLoop(true, serviceId, intervalTiming);
    } else return null
  }

  requestDeployLoop(keepLoopAlive, elementToLoopId, intervalTiming) {
    let intervalfunc = this.getDeployStatus.bind(this);
    let intervalLoop = function () {
      if (keepLoopAlive === true) {
        return (
          // eslint-disable-next-line
          localStorage.setItem(['IntervalId-' + elementToLoopId], intervalId),
          intervalfunc(elementToLoopId, intervalId, false)
        );
      }
      else return (
        clearInterval(intervalId)
      )
    };
    let intervalId = setInterval(intervalLoop, intervalTiming);
    intervalLoop();
  };

  handleDeploymentFinishedState = (
    elementToLoopId,
    intervalID,
    messagelevel,
    messagetitle,
    message
  ) => {

    platformNotifMessages(
      messagelevel,
      messagetitle,
      message,
      this.state.kubernetesName)

    //Remove the created loop
    clearInterval(intervalID)
    localStorage.removeItem(['IntervalId-' + elementToLoopId])

    this.setState({
      checkingConnection: false
    })

    this.handleValidationState();
  };

  handlePlatformCreation() {
    const t = this.t;

    trackPromise(
      dataService.mutations.createPlatform(
        this.state.kubernetesId,
        UserContextInfo.activeCompanyId(),
        this.state.kubernetesName,
        this.state.kubernetesClusterAddress,
        this.state.extIPRangeStart,
        this.state.extIPRangeEnd,
        this.state.kubernetesInfraProviderId,
        this.state.kubernetesPlanId,
        this.state.lainotikMonitoring
      ).then(
        (backEndResponseData) => {
          if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            this.setState({
              kubernetesPlatform: backEndResponseData.kubernetesplatform,
              kubernetesId: backEndResponseData.kubernetesplatform.id,
              kubernetesName: backEndResponseData.kubernetesplatform.name,
              kubeAccessStatus: backEndResponseData.kubernetesplatform.clsaddmon,
              kubeConnectStatus: backEndResponseData.kubernetesplatform.connectionstate,
              kubeDefaultLabelName: backEndResponseData.kubernetesplatform.k8labeldefault,
              kubeDemo: backEndResponseData.kubernetesplatform.demo,
              submitButtonCaption: t("common.update"),
              skipTLSVerify: backEndResponseData.kubernetesplatform.skiptlsverify,
              authMode: backEndResponseData.kubernetesplatform.k8certs[0].authmode,
              kubeAccessToken: backEndResponseData.kubernetesplatform.k8certs[0].token,
              clientCSR: backEndResponseData.kubernetesplatform.k8certs[0].clientcsr, //<-- This hardcoded array pointing has to be fixed on the Back since that element always will have just a single child, so no need of arrays.
              dropdownDisable: true,
              submitButtonDisable: true,
              kubernetesState: backEndResponseData.kubernetesplatform.deploymentstateinfo.codeinfo,
              kubernetesStateLevel: backEndResponseData.kubernetesplatform.deploymentstateinfo.codeinfolevel,
              kubernetesMessage: backEndResponseData.kubernetesplatform.deploymentstateinfo.message,
              kubernetesMessageTitle: backEndResponseData.kubernetesplatform.deploymentstateinfo.messagetitle,
              kubernetesMessageLevel: backEndResponseData.kubernetesplatform.deploymentstateinfo.messagelevel,
            })

            //When creating the new Platform allows again the "New" button
            this.props.enableCreateButton();

            this.props.handlePlatformServices(
              this.state.kubernetesId,
              this.state.kubernetesName,
              this.state.kubernetesStateLevel,
              this.state.kubernetesState,
              this.state.kubeAccessStatus,
              this.state.kubeConnectStatus,
              this.state.healthCheckClass,
              this.state.healthCheckMessage)

            this.handleValidationState()

            //Temporary solution to refresh the page after x seconds after creating a platform
            setTimeout(() => {
              window.location.reload(true);
            }, 3000);
          } else return null
        }
      )
    )
  };

  handlePlatformUpdate(
    showModal,
    kubernetesClusterAddress,
    clientCRT,
    caCRT,
    extIPRangeStart,
    extIPRangeEnd,
    kubeAccessToken,
    authMode,
    skipTLSVerify) {
    trackPromise(
      dataService.mutations.updatePlatform(
        this.state.kubernetesId,
        this.state.kubernetesName,
        kubernetesClusterAddress,
        clientCRT,
        caCRT,
        extIPRangeStart,
        extIPRangeEnd,
        kubeAccessToken,
        authMode,
        skipTLSVerify
      ).then(
        (backEndResponseData) => {
          if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            this.setState({
              kubernetesPlatform: backEndResponseData.kubernetesplatform,
              kubernetesId: backEndResponseData.kubernetesplatform.id,
              kubernetesName: backEndResponseData.kubernetesplatform.name,
              kubeAccessStatus: backEndResponseData.kubernetesplatform.clsaddmon,
              kubeConnectStatus: backEndResponseData.kubernetesplatform.connectionstate,
              kubeDefaultLabelName: backEndResponseData.kubernetesplatform.k8labeldefault,
              kubernetesState: backEndResponseData.kubernetesplatform.deploymentstateinfo.codeinfo,
              kubernetesStateLevel: backEndResponseData.kubernetesplatform.deploymentstateinfo.codeinfolevel,
              kubernetesMessageLevel: backEndResponseData.kubernetesplatform.deploymentstateinfo.messagelevel,
              kubernetesMessageTitle: backEndResponseData.kubernetesplatform.deploymentstateinfo.messagetitle,
              kubernetesMessage: backEndResponseData.kubernetesplatform.deploymentstateinfo.message,
              kubernetesClusterAddress: backEndResponseData.kubernetesplatform.clusteraddress,
              extIPRangeStart: backEndResponseData.kubernetesplatform.externaliprangestart,
              extIPRangeEnd: backEndResponseData.kubernetesplatform.externaliprangeend,
              skipTLSVerify: backEndResponseData.kubernetesplatform.skiptlsverify,
              authMode: backEndResponseData.kubernetesplatform.k8certs[0].authmode,
              kubeAccessToken: backEndResponseData.kubernetesplatform.k8certs[0].token,
              clientCSR: backEndResponseData.kubernetesplatform.k8certs[0].clientcsr, //<-- This hardcoded array pointing has to be fixed on the Back since that element always will have just a single child, so no need of arrays.
              clientCRT: backEndResponseData.kubernetesplatform.k8certs[0].clientcrt,
              caCRT: backEndResponseData.kubernetesplatform.k8certs[0].cacrt,
              certExpirationDate: backEndResponseData.kubernetesplatform.k8certs[0].expirationdate,
              kubernetesInfraProviderId: backEndResponseData.kubernetesplatform.infraprovider.id,
              kubernetesPlanId: backEndResponseData.kubernetesplatform.plan.id,
              submitButtonDisable: true,
              platformUpdated: true,
              checkingConnection: true
            })
            if (showModal === true) {
              this.handleValidationState();
            }
          } else return null
        }
      ).then(
        () => {
          if (this.state.kubeConnectStatus
            && this.state.kubeConnectStatus === BackEndConsts.PLATFORM_CONNECTION_STATUS_REQUESTED) {
            this.handleAccessDisabled();
            this.handleHealthCheckInfo();
            this.handleStepValidation("ACCESS", false);
            this.callLoop();
          } else if (
            this.state.kubeConnectStatus === BackEndConsts.PLATFORM_CONNECTION_STATUS_CONNECTED
          ) {
            this.handleStepValidation("ACCESS", true);
            this.setState({
              checkingConnection: false
            })
          } else {
            this.handleStepValidation("ACCESS", false);
          }
        }
      )
    )
  }

  handlePlatformDelete = (e) => {
    trackPromise(
      dataService.mutations.deletePlatform(
        this.state.kubernetesId
      ).then(
        (deleteKubernetesData) => {
          if (deleteKubernetesData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            // HERE COMPLETE AT LEAST NOTIFICATIONS WHEN HAVING THE FIELDS ON THE DATABASE
            this.setState({
              kubernetesMessage: this.state.kubernetesName + "-" + deleteKubernetesData.message,
              kubernetesMessageTitle: this.state.kubernetesName + "-" + deleteKubernetesData.messagetitle,
              kubernetesMessageLevel: deleteKubernetesData.messagelevel
            });

            this.handleValidationState();

            localStorage.removeItem('ActivePlatformData');

            //Temporary solution to refresh the page after x seconds after creating a platform
            setTimeout(() => {
              window.location.reload(true);
            }, 3000);
          } else return null
        }
      )
    )
  }

  handlePlatformDeploy = (e) => {
    trackPromise(
      dataService.mutations.k8DeploymentRequest(this.state.kubernetesId).then(
        (backEndResponseData) => {
          if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            this.setState({
              kubernetesState: backEndResponseData.kubernetesplatform.deploymentstateinfo.codeinfo,
              kubernetesStateCode: backEndResponseData.kubernetesplatform.deploymentstateinfo.code,
              kubernetesStateLevel: backEndResponseData.kubernetesplatform.deploymentstateinfo.codeinfolevel,
              kubernetesMessage: backEndResponseData.response.messageinfo.message,
              kubernetesMessageTitle: backEndResponseData.response.messageinfo.messagetitle,
              kubernetesMessageLevel: backEndResponseData.response.messageinfo.messagelevel,
              platStatusAccordionOpened: true
            })

            if (this.accordionRef.current.state.platStatusAccordionOpened === false) {
              //this calls by reference to the function which opens the accordion
              this.accordionRef.current.toggleStatusSection()
            }

            //Update the state of the platform
            this.handleValidationState()
          } else return null
        }
      )
    )
  }

  handlePlansAndInfraProviders() {
    trackPromise(
      dataService.queries.getPlans().then(
        (plansresponse) => {
          this.setState({ dataplans: plansresponse })
        }
      )
    )
    trackPromise(
      dataService.queries.getInfraProviders().then(
        (infraprovidersresponse) => {
          this.setState({ datainfraproviders: infraprovidersresponse })
        }
      )
    )
  }

  handleChangeDropdownLoc(e) {
    let dropdownResponse = handleChangeDropdown(e)

    this.setState({
      [dropdownResponse.name]: dropdownResponse.value,
    });
  };

  checkIfSuperUser = async () => {
    const { isSU } = await UserContextInfo.isSuperUser();
    this.setState({ isSuperUser: isSU });
  };

  handleAccessDisabled = () => {
    if (this.state.isSuperUser) {
      return this.setState({ fieldDisabled: false });
    }

    let disableConditions = [
      this.props.kubernetesPlatform.demo,
      this.state.checkingConnection === true,
      this.state.kubernetesStateCode === BackEndConsts.DEPLOYMENT_PLATFORM_STATE_READY,
      UserContextInfo.activeCompanyActivation() !== "ACTIVATED",
      !UserContextInfo.userRolePlatformCreation()
    ];

    const shouldDisable = disableConditions.some(e => e === true);
    this.setState({ fieldDisabled: shouldDisable });
  };

  // TO REFACTOR I've pretty same function on CreateForm.js, Company.js and here...
  // Check the approach here --> https://codesandbox.io/s/k9r9692m77?file=/src/index.js:1812-1864
  handleFormSubmit = () => {

    let kubeSettings = [...this.state.kubeSettings];

    kubeSettings.push({
      kubernetesName: this.state.kubernetesName ? this.state.kubernetesName : 'MyKubePlatform',
      kubernetesClusterAddress: this.state.kubernetesClusterAddress ? this.state.kubernetesClusterAddress : "",
      clientCRT: this.state.clientCRT ? this.state.clientCRT : "",
      caCRT: this.state.caCRT ? this.state.caCRT : "",
      extIPRangeStart: this.state.extIPRangeStart ? this.state.extIPRangeStart : "",
      extIPRangeEnd: this.state.extIPRangeEnd ? this.state.extIPRangeEnd : "",
      kubernetesInfraProviderId: this.state.kubernetesInfraProviderId,
      kubernetesPlanId: this.state.kubernetesPlanId
    });

    this.setState({
      kubeSettings
    });

    this.props.kubernetesPlatform.isEmpty ? this.handlePlatformCreation() : this.handlePlatformDeploy()
  };

  validateKubernetesName = async (value) => {
    try {
      await Yup.string()
        .required(this.t("kubernetesSettings.creationForm.requiredMessage"))
        .min(3, this.t("kubernetesSettings.creationForm.errorMinMessage"))
        .matches(/^\S*$/, this.t("kubernetesSettings.creationForm.errorMessage"))
        .validate(value);
      return true;
    } catch (error) {
      return false;
    }
  };

  handleInputChangeLoc = async (value) => {
    const isFormValid = await this.validateKubernetesName(value);
    this.setState({
      kubernetesName: value,
      isFormValid,
    });
  };

  handleLainotikMonitoringChange = (e) => {
    const checked = e.target.checked;
    this.setState({ lainotikMonitoring: checked });
  };

  deletePlatformRequest = () => {
    const { t } = this.props;

    this.setState(prevState => ({
      modalTitleText: t("kubernetesSettings.deleteModal.deleteKubename", { kubernetesName: this.state.kubernetesName }),
      modalBodyText: t("kubernetesSettings.deleteModal.deleteWarning", { kubernetesName: this.state.kubernetesName }),
      modalAcceptBtn: t("kubernetesSettings.deleteModal.deleteAcceptBtn", { kubernetesName: this.state.kubernetesName }),
      modalCancelBtn: t("kubernetesSettings.deleteModal.deleteCancelBtn"), deletePlatformModal: !prevState.deletePlatformModal
    }));
  };

  toggleHelpModal = () => {
    const { t } = this.props;

    this.setState(prevState => ({
      modalTitleText: t("kubernetesSettings.helpModal.helpCreate"),
      modalBodyText: t("kubernetesSettings.helpModal.helpInstructions"),
      modalAcceptBtn: t("common.ok"),
      helpModal: !prevState.helpModal
    }));
  };

  toggleModal = (e) => {
    e.persist();

    let name = e.target.name;
    let state = e.target.value;

    this.setState({
      [name]: state === "true" ? false : true,
      deletePlatformModal: false,
      helpModal: false
    });
  }

  handleValidationState() {
    //Call to refresh deployment data every X seconds when state being "124" or "125" *backEndConstants.js
    if (!this.state.kubeDemo
      && this.state.kubernetesStateCode
      && this.platformDeploymentStateForLoopStart.some(e => e === this.state.kubernetesStateCode)) {
      this.callLoop()
    } else {
      this.handleAccessDisabled();
      this.handleHealthCheckInfo();
      this.props.handlePlatformServices(
        this.state.kubernetesId,
        this.state.kubernetesName,
        this.state.kubernetesStateLevel,
        this.state.kubernetesState,
        this.state.kubeAccessStatus,
        this.state.kubeConnectStatus,
        this.state.healthCheckClass,
        this.state.healthCheckMessage)
    }
  }

  toggleSettingsSection = () => {
    this.setState({
      SettingsOpened: !this.state.SettingsOpened
    })
  }

  handleNodesRefresh = () => {
    this.getPlatformInfo(true);
    if ((this.state.k8Nodes
      && this.state.k8Nodes.constructor === Array
      && this.state.k8Nodes.length !== 0)
      && this.state.kubeDefaultLabelName !== null) {
      this.handleStepValidation("NODES", true);
    } else {
      this.handleStepValidation("NODES", false);
    }
  }

  handleStorageRefresh = () => {
    this.getPlatformInfo(true);
    if (this.state.k8Storages.length !== 0) {
      this.handleStepValidation("STORAGE", true);
    } else {
      this.handleStepValidation("STORAGE", false);
    }
  }

  handleStepValidation = (stepName, stepOk) => {
    switch (stepName) {
      case "ACCESS":
        this.setState({ step1Validated: stepOk });
        break;
      case "NODES":
        this.setState({ step2Validated: stepOk });
        break;
      case "STORAGE":
        this.setState({ step3Validated: stepOk });
        break;
      default:
        break;
    }
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      kubernetesName: Yup.string()
        .required(this.t("kubernetesSettings.creationForm.requiredMessage"))
        .min(3, this.t("kubernetesSettings.creationForm.errorMinMessage"))
        .matches(/^\S*$/, this.t("kubernetesSettings.creationForm.errorMessage"))
    });
  }

  render() {
    let kubernetes = this.props.kubernetesPlatform;
    const t = this.t;

    return (
      <>
        <tr className={this.props.isEmpty ? "new-platform" : ""}>
          <td>
            <span
              id={"PlatformStatus-" + this.state.kubernetesId}
              className={"status-icon " + this.state.healthCheckClass}>
              <UncontrolledTooltip
                placement="right"
                target={"PlatformStatus-" + this.state.kubernetesId}>
                {this.state.healthCheckMessage}
              </UncontrolledTooltip>
            </span>
          </td>
          <td className="platform-name-column">
            <div className="form-group">
              <Formik
                initialValues={{ kubernetesName: this.state.kubernetesName }}
                validationSchema={this.getValidationSchema()}
                onSubmit={this.handleFormSubmit}
                innerRef={ref => this.formikRef = ref}
                enableReinitialize>
                {({ setFieldValue }) => (
                  <Form>
                    <CustomField
                      type="text"
                      name="kubernetesName"
                      className="form-control"
                      disabled={this.state.dropdownDisable}
                      onChange={(e) => {
                        setFieldValue("kubernetesName", e.target.value);
                        this.handleInputChangeLoc(e.target.value);
                      }}
                      placeholder={t("kubernetesSettings.creationForm.placeHolder", { serviceName: this.props.serviceName })} />
                  </Form>
                )}
              </Formik>
            </div>
          </td>
          <td>
            {platformStateTag(this.state.kubernetesState, this.state.kubernetesStateLevel)}
          </td>
          {this.state.lainotikMonitoring !== undefined &&
            <td className="wrapper-monitoring">
              <MonitoringServices
                t={t}
                companyServices={this.state.companyServices}
                kubernetesState={this.state.kubernetesState}
                lainotikMonitoring={this.state.lainotikMonitoring}
                handleLainotikMonitoringChange={(e) => { this.handleLainotikMonitoringChange(e) }} />
            </td>
          }
          <td className="administration-column-set">
            <i
              id={'content-' + kubernetes.id}
              onClick={() => {
                this.toggleSettingsSection()
              }}
              className={`elem-settings-btn ${this.state.SettingsOpened ? "icon-close expanded" : "icon-configuration"}`}></i>
            <UncontrolledTooltip
              placement="bottom"
              target={'content-' + kubernetes.id}>
              {t("common.settings")}</UncontrolledTooltip>
          </td>
        </tr>
        <tr>
          <td className="collapsed-content" colSpan="5">
            <Collapse
              toggler={'#content-' + kubernetes.id}
              isOpen={this.state.SettingsOpened}
              defaultOpen={kubernetes.isEmpty ? true : false}>
              {(!kubernetes.isEmpty ||
                this.state.kubeDemo === true) &&
                <Col sm="auto">
                  <OverviewSettings
                    t={t}
                    step1Validated={this.state.step1Validated}
                    step2Validated={this.state.step2Validated}
                    step3Validated={this.state.step3Validated}
                  />
                </Col>
              }
              <Formik
                initialValues={{ kubernetesName: this.state.kubernetesName }}
                validationSchema={this.getValidationSchema()}
                onSubmit={this.handleFormSubmit}
                enableReinitialize>
                {() => (
                  <Form>
                    {kubernetes.isEmpty
                      && this.state.kubeDemo === false
                      && UserContextInfo.userRolePlatformCreation()
                      && UserContextInfo.activeCompanyActivation() === "ACTIVATED" &&
                      <Col sm="12">
                        <Row className="flex-sm-row align-items-center">
                          <Col sm="10">
                            <Button
                              type="button"
                              className="form-btn align-right info-wrapper elem-submit-btn btn-secondary"
                              onClick={(e) => { this.toggleHelpModal(e) }}>
                              <i name="csrModal" className="icon-info" />
                              {t("common.help")}
                            </Button>
                          </Col>
                          <Col sm="auto">
                            <Button
                              disabled={!this.state.isFormValid}
                              color={this.props.bgColor}
                              className="form-btn align-right btn-primary"
                              type="submit">
                              {this.props.isEmpty
                                ? t("common.start")
                                : t("common.update")}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    }
                    {this.state.helpModal ?
                      <GenericModal
                        t={t}
                        name="helpModal"
                        toggleModal={(e) => { this.toggleModal(e) }}
                        acceptOnClick={(e) => { this.toggleModal(e) }}
                        modalTitleText={this.state.modalTitleText}
                        modalBodyText={this.state.modalBodyText}
                        modalAcceptBtn={this.state.modalAcceptBtn}
                        modalCancelBtn={this.state.modalCancelBtn} />
                      : null
                    }
                  </Form>
                )}
              </Formik>
              {(!kubernetes.isEmpty ||
                this.state.kubeDemo === true) &&
                <>
                  <Col sm="auto" className="settings-section sub-section">
                    {this.state.kubeConnectStatus &&
                      <AccessSetup
                        t={t}
                        kubernetesPlatform={this.props.kubernetesPlatform}
                        handlePlatformUpdate={(
                          showModal,
                          ClusterAddress,
                          clientCRT,
                          caCERT,
                          extIPRangeStart,
                          extIPRangeEnd,
                          kubeAccessToken,
                          authMode,
                          skipTLSVerify) => {
                          this.handlePlatformUpdate(
                            showModal,
                            ClusterAddress,
                            clientCRT,
                            caCERT,
                            extIPRangeStart,
                            extIPRangeEnd,
                            kubeAccessToken,
                            authMode,
                            skipTLSVerify)
                        }}
                        handleStepValidation={(stepName, stepOk) => { this.handleStepValidation(stepName, stepOk) }}
                        kubernetesStateCode={this.state.kubernetesStateCode}
                        kubeConnectStatus={this.state.kubeConnectStatus}
                        kubernetesClusterAddress={this.state.kubernetesClusterAddress}
                        modalTitleText={this.state.modalTitleText}
                        modalBodyText={this.state.modalBodyText}
                        modalBodyLink={this.state.modalBodyLink}
                        modalAcceptBtn={this.state.modalAcceptBtn}
                        kubernetesState={this.state.kubernetesState}
                        fieldDisabled={this.state.fieldDisabled}
                        healthCheckClass={this.state.healthCheckClass}
                        skipTLSVerify={this.state.skipTLSVerify}
                        authMode={this.state.authMode}
                        kubeAccessToken={this.state.kubeAccessToken}
                        csrModal={this.state.csrModal}
                        clientCSR={this.state.clientCSR}
                        clientCRT={this.state.clientCRT}
                        caCRT={this.state.caCRT}
                        certExpirationDate={this.state.certExpirationDate}
                        kubeDemo={this.state.kubeDemo}
                        checkingConnection={this.state.checkingConnection}
                        isSuperUser={this.state.isSuperUser} />
                    }
                  </Col>
                  <Col sm="auto" className="settings-section sub-section">
                    {this.state.k8Nodes &&
                      <NodesSetup
                        t={t}
                        handleStepValidation={(stepName, stepOk) => { this.handleStepValidation(stepName, stepOk) }}
                        handleNodesRefresh={(e) => { this.handleNodesRefresh(e) }}
                        kubeId={this.state.kubernetesId}
                        kubeDemo={this.state.kubeDemo}
                        kubernetesStateCode={this.state.kubernetesStateCode}
                        k8Nodes={this.state.k8Nodes}
                        k8Labels={this.state.k8Labels}
                        kubeDefaultLabelName={this.state.kubeDefaultLabelName}
                        kubernetesPlatform={this.props.kubernetesPlatform}
                        healthCheckClass={this.state.healthCheckClass} />
                    }
                  </Col>
                  <Col sm="auto" className="settings-section sub-section">
                    {this.state.k8Storages &&
                      <StorageSetup
                        t={t}
                        handleStepValidation={(stepName, stepOk) => { this.handleStepValidation(stepName, stepOk) }}
                        handleStorageRefresh={(e) => { this.handleStorageRefresh(e) }}
                        k8Storages={this.state.k8Storages}
                        kubernetesPlatform={this.props.kubernetesPlatform}
                        kubeDemo={this.state.kubeDemo}
                        fieldDisabled={this.state.fieldDisabled} />
                    }
                  </Col>
                  <Col sm="auto" className="settings-section sub-section">
                    {this.state.k8Addons &&
                      <PlatformStatusArea
                        t={t}
                        ref={this.accordionRef}
                        platStatusAccordionOpened={this.state.platStatusAccordionOpened}
                        kubernetesPlatform={this.props.kubernetesPlatform}
                        k8Addons={this.state.k8Addons}
                        kubernetesStateCode={this.state.kubernetesStateCode}
                        kubernetesState={this.state.kubernetesState}
                        appNotifications={this.state.appNotifications}
                        monitoringDeployCode={this.state.monitoringDeployCode}
                        monitoringDeployCodeInfo={this.state.monitoringDeployCodeInfo}
                        monitoringDeployDate={this.state.monitoringDeployDate}
                        monitoringDeployDuration={this.state.monitoringDeployDuration}
                        monitoringDeployBy={this.state.monitoringDeployBy} />
                    }
                  </Col>
                  {this.state.kubeDemo === false
                    && UserContextInfo.userRolePlatformCreation()
                    && UserContextInfo.activeCompanyActivation() === "ACTIVATED" &&
                    <Col sm="12">
                      <Row>
                        <Col sm="6">
                          <Button
                            className="form-btn"
                            onClick={(e) => { this.deletePlatformRequest(e) }}>
                            {t("common.delete")}</Button>
                        </Col>
                        <Col sm="6">
                          <Button
                            type="button"
                            color={this.props.bgColor}
                            disabled={this.state.step1Validated !== true ||
                              this.state.step2Validated !== true ||
                              this.state.step3Validated !== true ||
                              this.platformDeploymentStateForLoopStart.some(e => e === this.state.kubernetesStateCode)}
                            className="form-btn align-right primary"
                            onClick={(e) => { this.handlePlatformDeploy(e) }}>
                            {this.state.kubernetesStateCode !== BackEndConsts.DEPLOYMENT_PLATFORM_STATE_READY
                              ? t("common.launch")
                              : t("common.relaunch")}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  }
                  {this.state.deletePlatformModal ?
                    <GenericModal
                      t={t}
                      name="deletePlatformModal"
                      toggleModal={(e) => { this.toggleModal(e) }}
                      acceptOnClick={(e) => { this.handlePlatformDelete(e) }}
                      modalTitleText={t("kubernetesSettings.deleteModal.deleteKubename", {
                        kubernetesName: this.state.kubernetesName
                      })}
                      modalBodyText={t("kubernetesSettings.deleteModal.deleteWarning", {
                        kubernetesName: this.state.kubernetesName,
                        activeCompany: UserContextInfo.activeCompanyName()
                      })}
                      modalAcceptBtn={t("kubernetesSettings.deleteModal.deleteAcceptBtn", {
                        kubernetesName: this.state.kubernetesName
                      })}
                      modalCancelBtn={t("kubernetesSettings.deleteModal.deleteCancelBtn")} />
                    : null
                  }
                </>
              }
            </Collapse>
          </td>
        </tr>
      </>
    );
  }
};
export default KubernetesSettings;
