import React from "react";
import Switch from 'react-switch';
import { getIn } from 'formik';

export const LainoSwitch = (props) => {
  // Detect if the component is under a Formik context
  const isFormikContext = props.form && props.field;

  // Detect if there's an error (if it's used by Formik)
  const isError = isFormikContext
    ? getIn(props.form.errors, props.field.name) && getIn(props.form.touched, props.field.name)
    : false;

  // Detect the value of "checked" (if it's used by Formik uses the value of "field", otherwise uses prop directly)
  const checkedValue = isFormikContext
    ? props.field.value
    : props.checked;

  const handleChange = (value, event) => {
    if (isFormikContext) {
      // For Formik, pass the event
      props.form.setFieldValue(props.field.name, value);
    } else if (props.onChange) {
      // For non-Formik use, pass both the value and the event
      props.onChange(value, event);
    }
  };

  return (
    <Switch
      className={`switch-group ${props.disableStyles} ${isError ? 'is-invalid' : ''}`}
      height={25}
      width={85}
      handleDiameter={35}
      offColor="#344675"
      onColor={props.hexColor}
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      checkedIcon={
        <span className="switch-on-label">
          ON
        </span>
      }
      uncheckedIcon={
        <span className="switch-off-label">
          OFF
        </span>
      }
      disabled={props.disabled}
      checked={checkedValue}
      onChange={handleChange}
    />
  );
};