import React from "react";
import * as BackEndConsts from "tools/constants/backEndConstants";
import { dataService } from "tools/dataService/dataService"
import { trackPromise } from "react-promise-tracker";
import {
  platformNotifMessages,
  handleInputChange,
  handleChangeDropdown,
  CustomField
} from "tools/commonFunctions";
import { LainoSwitch } from "components/Switch/Switch";
import FormModal from "components/Modal/FormModal";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

import {
  Col,
  Row,
  Button,
  Label,
  Collapse
} from "reactstrap";

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

class AppComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appComponent: this.props.appComponent,
      appId: this.props.appId,
      appActivation: this.props.appActivation,
      appDeployCode: this.props.appDeployCode,
      componentId: this.props.appComponent.id,
      componentName: this.props.appComponent.publicname,
      componentVersion: this.props.appComponent.imageversion,
      componentUpgradeVersion: this.props.appComponent.imageversion_toupgrade,
      componentLabelId: this.props.appComponent.k8label !== null
        ? this.props.appComponent.k8label.id
        : null,
      componentLabelName: this.props.appComponent.k8label !== null
        ? this.props.appComponent.k8label.name
        : null,
      componentStorageName: this.props.appComponent.k8storage !== null
        ? this.props.appComponent.k8storage.publicname
        : null,
      componentStorageId: this.props.appComponent.k8storage !== null
        ? this.props.appComponent.k8storage.id
        : null,
      showComponentPods: this.props.appComponent.showpodsnumber,
      showLoadBalancer: this.props.appComponent.showloadbalancer,
      showResourceParams: this.props.appComponent.showhwresources,
      componentPods: this.props.appComponent.podsnumber,
      componentMinimumPods: this.props.appComponent.lowerlimitpods,
      componentQuorumPods: this.props.appComponent.quorumpods,
      loadbalancer: this.props.appComponent.loadbalancer,
      componentCPU: parseInt(this.props.appComponent.cpureq),
      componentMinCPU: parseInt(this.props.appComponent.cpureq_default),
      componentCPULimit: parseInt(this.props.appComponent.cpulimit),
      componentMem: parseInt(this.props.appComponent.memreq),
      componentMinMem: parseInt(this.props.appComponent.memreq_default),
      componentMemLimit: parseInt(this.props.appComponent.memlimit),
      appFinishedState: this.props.appFinishedState,
      deployButtonDisable: this.props.deployButtonDisable,
      isModalAuxBtnDisabled: false,
      componentProtocolId: this.props.componentProtocolId,
      componentProtocols: this.props.componentProtocols,
      filteredComponentProtocols: this.props.componentProtocols.map((protocol) => {
        //Object Destructuring--> removes the field "name" from the object since the mutation only allows fields "id" and "enabled"
        const { name, ...newObj } = protocol;
        return newObj
      }),
      componentEnabledProtocols: this.props.componentEnabledProtocols,
      componentPersistences: this.props.appComponent.appcomponentpersistences,
      componentConfiguration: this.props.appComponent.appcomponentconfigs,
      componentSecrets: this.props.appComponent.appcomponentsecrets,
      componentStateChange: false,
      accordionOpened: false,
      modalTitleText: "",
      modalBodyText: "",
      modalAcceptBtn: "",
      modalCancelBtn: ""
    }
  };

  componentDidMount() {
    const t = this.props.i18n.t;

    let compoConfigMerged = [];

    if (this.props.appComponent.appcomponentconfigs.length > 0) {
      this.props.appComponent.appcomponentconfigs.map((componentConfig) => {
        compoConfigMerged.push(componentConfig)
      })
    } else null;

    if (this.props.appComponent.appcomponentsecrets !== null &&
      this.props.appComponent.appcomponentsecrets.length > 0) {
      this.props.appComponent.appcomponentsecrets.map((componentSecret) => {
        if (componentSecret.type !== "AUTO") {
          compoConfigMerged.push({
            configtype: "SECRET",
            defaultvalue: componentSecret.value,
            description: t("platformService.serviceApp.appComponent.thisSecret"),
            format: "STRING",
            id: componentSecret.id,
            publicname: componentSecret.name,
            showdefault: true,
            value: componentSecret.value,
          })
        } else null
      })
    } else null;

    this.setState({
      componentConfigMergedArray: compoConfigMerged,
      [compoConfigMerged.publicname + '-confModal']: false,
      [compoConfigMerged.publicname + '-Id']: compoConfigMerged.id,
      [compoConfigMerged.publicname + '-Value']: compoConfigMerged.value
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.componentUpdated !== prevProps.componentUpdated) {
      this.setState({
        appId: this.props.appId,
        componentId: this.props.appComponent.id,
        componentName: this.props.appComponent.publicname,
        showComponentPods: this.props.appComponent.showpodsnumber,
        showLoadBalancer: this.props.appComponent.showloadbalancer,
        showResourceParams: this.props.appComponent.showhwresources,
        componentPods: this.props.appComponent.podsnumber,
        componentMinimumPods: this.props.appComponent.lowerlimitpods,
        componentQuorumPods: this.props.appComponent.quorumpods,
        loadbalancer: this.props.appComponent.loadbalancer,
        componentCPU: parseInt(this.props.appComponent.cpureq),
        componentMinCPU: parseInt(this.props.appComponent.cpureq_default),
        componentCPULimit: parseInt(this.props.appComponent.cpulimit),
        componentMem: parseInt(this.props.appComponent.memreq),
        componentMinMem: parseInt(this.props.appComponent.memreq_default),
        componentMemLimit: parseInt(this.props.appComponent.memlimit),
        appFinishedState: this.props.appFinishedState,
        componentStorageId: this.props.appComponent.k8storage !== null
          ? this.props.appComponent.k8storage.id
          : null,
        componentProtocolId: this.props.componentProtocolId,
        deployButtonDisable: this.props.deployButtonDisable
      })
    }
  }

  getConfigData(componentParameterId, componentParameterName) {
    const t = this.props.i18n.t;

    if (componentParameterId.length > 0) { //If clause to call the query just when it makes sense
      trackPromise(
        dataService.queries.getAppComponentConfiguration(
          componentParameterId
        ).then(
          (datacompconfig) => {
            if (datacompconfig !== BackEndConsts.BACKEND_RESPONSE_ERROR &&
              datacompconfig.config.publicname) {
              return (
                this.setState({
                  [componentParameterName]: !this.state[componentParameterName], //State to SHOW/Hide the modal
                  activeComponentConfigId: datacompconfig.config.id,
                  activeComponentConfigName: datacompconfig.config.publicname + '-confModal',
                  activeComponentConfigLabel: datacompconfig.config.publicname,
                  activeComponentConfigValue: datacompconfig.config.value,
                  activeComponentConfigDefValue: datacompconfig.config.defaultvalue,
                  activeComponentConfigModalType: datacompconfig.config.configtype,
                  activeComponentConfigModalFormat: datacompconfig.config.format,
                  activeComponentConfigBodyText: datacompconfig.config.description,
                  activeComponentConfigShowAuxBtn: datacompconfig.config.showdefault,
                  activeComponentConfigAcceptBtn: t("common.save"),
                  activeComponentConfigCancelBtn: t("common.exit"),
                  activeComponentConfigAuxBtn: t("platformService.serviceApp.appComponent.setDefault"),
                  isModalAuxBtnDisabled: false
                })
              )
            }
          }
        )
      )
    } else {
      this.setState({
        [componentParameterName + '-confModal']: !this.state[componentParameterName + '-confModal'],
      });
    }
  }

  getSecretData(componentParameterId, componentParameterName) {
    const t = this.props.i18n.t;

    if (componentParameterId.length > 0) { //If clause to call the query just when it makes sense
      trackPromise(
        dataService.queries.getAppComponentSecretConfiguration(
          componentParameterId
        ).then(
          (datacompsecretconfig) => {
            if (datacompsecretconfig !== BackEndConsts.BACKEND_RESPONSE_ERROR &&
              datacompsecretconfig.secret.name) {
              return (
                this.setState({
                  [componentParameterName]: !this.state[componentParameterName], //State to SHOW/Hide the modal
                  activeComponentConfigId: datacompsecretconfig.secret.id,
                  activeComponentConfigName: datacompsecretconfig.secret.name + '-confModal',
                  activeComponentConfigLabel: datacompsecretconfig.secret.name,
                  activeComponentConfigValue: datacompsecretconfig.secret.value,
                  activeComponentConfigDefValue: datacompsecretconfig.secret.value,
                  activeComponentConfigModalType: "SECRET",
                  activeComponentConfigModalFormat: "STRING",
                  activeComponentConfigBodyText: t("platformService.serviceApp.appComponent.thisSecret"),
                  activeComponentConfigAcceptBtn: t("common.save"),
                  activeComponentConfigCancelBtn: t("common.exit"),
                  activeComponentConfigAuxBtn: t("platformService.serviceApp.appComponent.setDefault"),
                  isModalAuxBtnDisabled: false
                })
              )
            }
          }
        )
      )
    } else (
      this.setState({
        [componentParameterName]: !this.state[componentParameterName], //State to SHOW/Hide the modal
      })
    )
  }

  updateData = (values) => {
    const persistenceData = values.componentPersistences.map((filteredPersistence) => {
      const sizeAsString = String(filteredPersistence.size);
      const sizeWithUnit = sizeAsString.endsWith('Gi')
        ? filteredPersistence.size
        : `${filteredPersistence.size}Gi`; //Concat of 'Gi' string since it's what Backend understands
      return {
        idpersistence: filteredPersistence.id,
        idstorage: filteredPersistence.k8storage.id,
        size: sizeWithUnit
      };
    });

    trackPromise(
      dataService.mutations.updateAppComponent(
        this.state.componentId,
        this.state.appId,
        values.componentPods,
        values.loadbalancerSwitch,
        values.componentCPU,
        values.componentCPULimit,
        values.componentMem,
        values.componentMemLimit,
        values.componentLabelId,
        this.state.filteredComponentProtocols,
        persistenceData
      ).then(
        (dataupdatecomp) => {
          if (dataupdatecomp !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            platformNotifMessages(
              dataupdatecomp.response.messageinfo.messagelevel,
              dataupdatecomp.response.messageinfo.messagetitle,
              dataupdatecomp.response.messageinfo.message,
              this.state.componentName)

            if (dataupdatecomp.response.messageinfo.messagecode === BackEndConsts.DEPLOYMENT_STATE_UPDATING) {
              //Call to refresh deployment data every X seconds until state not being UPDATING
              this.props.requestDeployLoop("UPDATING", this.state.appId, 5000)
            }
          }
        }
      )
    )
  }

  updateComponentConfigData(e) {
    this.toggleModal(e);

    let correctedStringValue = JSON.stringify(this.state.activeComponentConfigValue)
      .replace(/"([^"]+(?="))"/g, '$1')
      .replace(/\\n/g, '\n');

    trackPromise(
      dataService.mutations.updateAppComponentConfig(
        this.state.activeComponentConfigId,
        correctedStringValue
      ).then(
        (dataupdatecompconfig) => {
          if (dataupdatecompconfig !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            platformNotifMessages(
              dataupdatecompconfig.response.messageinfo.messagelevel,
              dataupdatecompconfig.response.messageinfo.messagetitle,
              dataupdatecompconfig.response.messageinfo.message,
              this.state.activeComponentConfigName)

            if (dataupdatecompconfig.response.messageinfo.messagecode === BackEndConsts.DEPLOYMENT_STATE_UPDATING) {
              //Call to refresh deployment data every X seconds until state not being UPDATING
              this.props.requestDeployLoop("UPDATING", this.state.appId, 5000)
            }
          }
        }
      )
    )
  }

  updateComponentSecretData(e) {
    this.toggleModal(e);

    trackPromise(
      dataService.mutations.updateAppComponentSecret(
        this.state.activeComponentConfigId,
        JSON.stringify(this.state.activeComponentConfigValue).replace(/"([^"]+)":/g, '$1:')
      ).then(
        (dataupdatecompsecret) => {
          if (dataupdatecompsecret !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            platformNotifMessages(
              dataupdatecompsecret.response.messageinfo.messagelevel,
              dataupdatecompsecret.response.messageinfo.messagetitle,
              dataupdatecompsecret.response.messageinfo.message,
              this.state.activeComponentConfigName)

            if (dataupdatecompsecret.response.messageinfo.messagecode === BackEndConsts.DEPLOYMENT_STATE_UPDATING) {
              //Call to refresh deployment data every X seconds until state not being UPDATING
              this.props.requestDeployLoop("UPDATING", this.state.appId, 5000)
            }
          }
        }
      )
    )
  }

  handleInputChangeLoc = (e, setFieldValue) => {
    let inputResponse = handleInputChange(e);

    if (inputResponse.id.startsWith('persistence-')) {
      const [_, index, , fieldName] = inputResponse.name.split('-');
      if (fieldName === "size") {
        // Numeric value saved in the formulary
        const numericValue = parseInt(inputResponse.value.replace('Gi', ''), 10);
        if (!isNaN(numericValue)) {
          setFieldValue(`componentPersistences[${index}].size`, numericValue);
        };
      }
    } else if (inputResponse.id.startsWith('protocol-')) {
      this.handleInputUpdateProtocolObj(inputResponse);
    } else {
      if (inputResponse.name.endsWith('-confModal')) {
        this.setState({
          activeComponentConfigName: inputResponse.name,
          activeComponentConfigValue: inputResponse.value,
          isModalAuxBtnDisabled: false
        });
      } else {
        this.setState({
          [inputResponse.name]: inputResponse.value
        });
      }
    }
  };

  handleSwitchChange = (switchName, switchValue) => {

    this.setState({
      [switchName]: switchValue
    })
  };

  handleParamDefValue = () => {
    this.setState({
      activeComponentConfigValue: this.state.activeComponentConfigDefValue,
      isModalAcceptBtnDisabled: false,
      isModalAuxBtnDisabled: true
    })
  }

  handleInputUpdateProtocolObj = (inputResponse) => {
    const inputId = inputResponse.id.replace("protocol-", '');
    const componentProtocolObj = this.state.filteredComponentProtocols.map((componentProtocol) => {
      if (inputId === componentProtocol.id) {
        return {
          ...componentProtocol,
          enabled: !componentProtocol.enabled
        }
      } else return componentProtocol;
    })
    this.setState({
      filteredComponentProtocols: componentProtocolObj
    })
  }

  handleChangeDropdownLoc = (event, setFieldValue, config) => {
    const { formikProps, dropdownName, dropdownIdName, isPersistence } = config;
    let dropdownResponse = handleChangeDropdown(event);

    if (isPersistence) {
      this.handleDropdownUpdatePersistenceObj(
        {
          name: dropdownResponse.name,
          value: dropdownResponse.value,
          childId: dropdownResponse.childId,
          childName: dropdownResponse.childName
        },
        setFieldValue,
        formikProps
      );
    } else {
      setFieldValue(dropdownName, dropdownResponse.childName);
      setFieldValue(dropdownIdName, dropdownResponse.childId);
    }
  };

  handleDropdownUpdatePersistenceObj = (dropdownData, setFieldValue, formikProps) => {
    const { name, childId, childName } = dropdownData;
    // Asumed that the value for 'name' prop follows 'persistence-index-name' structure
    const [_, index] = name.split('-');

    const updatedValue = {
      ...formikProps.values.componentPersistences[index],
      k8storage: { id: childId, publicname: childName }
    };

    setFieldValue(`componentPersistences[${index}].k8storage`, updatedValue.k8storage);
  };

  toggleAccessSection = (e) => {
    this.setState({
      accordionOpened: !this.state.accordionOpened
    })
  };

  toggleAccessSection = (e) => {
    this.setState({
      accordionOpened: !this.state.accordionOpened
    })
  };

  toggleModal = (arg) => {
    let name, id, modal, isSecret;

    if (arg && arg.nativeEvent) {
      name = arg.target.name;
      id = arg.target.id;
      modal = arg.target.closest('.modal-dialog');
      isSecret = arg.target.getAttribute('paramtype') === "SECRET";
    } else if (typeof arg === 'object' && arg !== null) {
      name = arg.name;
      id = arg.id;
      modal = document.querySelector(`[name='${arg.name}'] .modal-dialog`);
      isSecret = arg.isSecret;
    } else {
      console.error('Invalid argument passed to toggleModal');
      return;
    }

    if (modal !== null) {
      let modalName = modal.getAttribute('name');
      this.setState(prevState => ({
        [modalName]: !prevState[modalName],
        isModalAuxBtnDisabled: false
      }));
    } else {
      if (isSecret) {
        this.getSecretData(id, name);
      } else {
        this.getConfigData(id, name);
      }
    }
  }


  renderLabels = (formikProps, setFieldValue) => {
    const t = this.props.i18n.t;

    return (
      <CustomField
        as="select"
        id={'componentlabeldropdown-' + this.state.componentId}
        name="componentLabel"
        className="dropdown-wrapper form-control"
        sm="auto"
        onChange={(e) => this.handleChangeDropdownLoc(e, setFieldValue, {
          dropdownName: 'componentLabel',
          dropdownIdName: 'componentLabelId'
        })}>
        {this.state.componentLabelId
          ? <option
            name="componentLabelId"
            id={this.state.componentLabelId}
            className="dropdown-option selected">
            {this.state.componentLabelName}
          </option>
          : <option
            className="dropdown-option selected"
            value="">
            {t("platformService.serviceApp.appComponent.labelDropdown.placeholder")}
          </option>
        }
        {this.props.platformLabels.map((defaultLabel) => {
          return (
            this.state.componentLabelId !== defaultLabel.id
              ? <option
                className="dropdown-option selected"
                key={defaultLabel.id}
                id={defaultLabel.id}
                value={defaultLabel.name}>
                {defaultLabel.name}
              </option>
              : null
          )
        })}
      </CustomField>
    );
  }

  renderPersistences = (formikProps, setFieldValue) => {
    const t = this.props.i18n.t;

    return this.state.componentPersistences.map((componentPersistence, index) => {
      const componentAtIdx = formikProps.values.componentPersistences && formikProps.values.componentPersistences[index];
      const selectedStorage = componentAtIdx && componentAtIdx.k8storage
        ? componentAtIdx.k8storage.publicname
        : '';
      const persistenceSize = componentAtIdx && componentAtIdx.size ? parseInt(componentAtIdx.size, 10) : '';

      return (
        <div className="highlighted-subsection" key={index}>
          <Row>
            <Col sm="12">
              <h6>{componentPersistence.name + ' '}<span className="title-info">{'(' + t("platformService.serviceApp.appComponent.storageSettings.persistenceControl.title") + (index + 1) + ')'}</span></h6>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Row>
                <Col>
                  <Row>
                    <Col sm="8">
                      <Label htmlFor={'persistence-' + componentPersistence.id} sm="auto">{t("platformService.serviceApp.appComponent.storageSettings.persistenceControl.label")}</Label>
                      <CustomField
                        as="select"
                        id={'persistence-' + componentPersistence.id}
                        name={'persistence-' + index + '-' + componentPersistence.name}
                        className="form-control"
                        onChange={(e) => this.handleChangeDropdownLoc(e, setFieldValue, {
                          formikProps: formikProps,
                          isPersistence: true
                        })}
                        value={selectedStorage}>
                        {componentPersistence.k8storage.id
                          ? <option
                            id={componentPersistence.k8storage.id}
                            className="dropdown-option selected"
                            value={componentPersistence.k8storage.publicname}>
                            {componentPersistence.k8storage.publicname}
                          </option>
                          : <option
                            className="dropdown-option selected"
                            value="">
                            {t("platformService.serviceApp.appComponent.storageSettings.persistenceControl.placeHolder")}
                          </option>
                        }
                        {this.props.platformStorages.map((defaultStorage) => {
                          return (
                            this.state.componentStorageId !== defaultStorage.id
                              ? <option
                                id={defaultStorage.id}
                                className="dropdown-option selected"
                                value={defaultStorage.publicname}>
                                {defaultStorage.publicname}
                              </option>
                              : null
                          )
                        })}
                      </CustomField>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col sm="6">
              <Row className="align-items-end">
                <Col sm="8" xl={{ offset: 1 }}>
                  <Label htmlFor={'persistence-' + componentPersistence.id} sm="auto">
                    {t("platformService.serviceApp.appComponent.storageSettings.persistenceControl.persistenceSettings.label")}
                  </Label>
                  <CustomField
                    type="number"
                    id={'persistence-' + componentPersistence.id}
                    name={'persistence-' + index + '-' + componentPersistence.name + '-size'}
                    className="custom-range form-control"
                    value={persistenceSize}
                    min="1"
                    disabled={this.state.appActivation && this.state.appDeployCode !== BackEndConsts.DEPLOYMENT_STATE_NEVERDEPLOYED}
                    onChange={(e) => { this.handleInputChangeLoc(e, setFieldValue) }}
                    placeholder={t("platformService.serviceApp.appComponent.storageSettings.persistenceControl.persistenceSettings.placeHolder")} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    });
  }

  renderProtocols = (formikProps) => {
    const t = this.props.i18n.t;
    const checkboxes = this.state.componentProtocols.map(componentProtocol => (
      <div
        key={componentProtocol.id}
        className="custom-checkbox custom-control custom-control-inline">
        <CustomField
          type="checkbox"
          id={'protocol-' + componentProtocol.id}
          name="enabledProtocols"
          className="custom-control-input"
          value={componentProtocol.id}
          checked={formikProps.values.enabledProtocols.includes(componentProtocol.id)}
          onChange={this.handleInputChangeLoc}
          disabled={formikProps.values.loadbalancerSwitch ? false : true}
          showError={false}//To NOT show the error message per EACH checkbox
        />
        <label
          htmlFor={'protocol-' + componentProtocol.id}
          className="custom-control-label">{componentProtocol.name}</label>
      </div>
    ));

    // Verify if there's a validation error and show it just ONCE after all checkboxes
    const isError = formikProps.errors['enabledProtocols'] && formikProps.touched['enabledProtocols'];
    const errorMessage = isError
      ? <div className="invalid-feedback d-block">{t("platformService.serviceApp.appComponent.resourceSettings.protocolCheckbox.requiredMessage")}</div>
      : null;

    return (
      <>
        {checkboxes}
        {errorMessage}
      </>
    );
  }

  renderConfigurations = () => {
    const t = this.props.i18n.t;

    return (
      this.state.componentConfigMergedArray.map((componentConfig) => {
        return (
          <>
            <div className="component-config-wrapper">
              <i name={'component-config-' + componentConfig.id}
                className="icon-conf" />
              <Button
                name={componentConfig.publicname + '-confModal'}
                id={componentConfig.id}
                className="info-wrapper elem-submit-btn settings-conf-elem"
                paramtype={componentConfig.configtype}
                onClick={(e) => { this.toggleModal(e) }}>
                {componentConfig.publicname}
              </Button>
            </div>
            {this.state[componentConfig.publicname + '-confModal']
              ? <FormModal
                lang={this.props.i18n.language}
                id={this.state.activeComponentConfigId}
                name={this.state.activeComponentConfigName}
                label={this.state.activeComponentConfigLabel}
                value={this.state.activeComponentConfigValue}
                defValue={this.state.activeComponentConfigDefValue}
                toggleModal={(e) => { this.toggleModal(e) }}
                acceptOnClick={(e) => {
                  this.state.activeComponentConfigModalType === "SECRET"
                    ? this.updateComponentSecretData(e)
                    : this.updateComponentConfigData(e)
                }}
                handleInputChange={(e) => { this.handleInputChangeLoc(e) }}
                auxBtnFunction={() => { this.handleParamDefValue() }}
                modalType={this.state.activeComponentConfigModalType}
                modalFormat={this.state.activeComponentConfigModalFormat}
                modalTitleIcon={'icon-conf'}
                modalTitleText={t("platformService.serviceApp.appComponent.configurationSettings.formTitle")}
                modalBodyText={this.state.activeComponentConfigBodyText}
                modalShowAuxBtn={this.state.activeComponentConfigShowAuxBtn}
                acceptBtnDisabled={this.state.isModalAcceptBtnDisabled}
                auxBtnDisabled={this.state.isModalAuxBtnDisabled}
                modalAcceptBtn={this.state.activeComponentConfigAcceptBtn}
                modalCancelBtn={this.state.activeComponentConfigCancelBtn}
                modalAuxBtn={this.state.activeComponentConfigAuxBtn} />
              : null
            }
          </>
        )
      })
    )
  }

  createDynamicYupSchema = () => {
    const { t } = this.props.i18n;

    const persistenceSchema = Yup.object().shape({
      size: Yup.string()
        .required(t("platformService.serviceApp.appComponent.resourceSettings.persistence.inputControl.requiredMessage"))
        .transform(value => (typeof value === 'number' || (typeof value === 'string' && value.match(/^\d+$/))) ? `${value}Gi` : value)
        .matches(/^\d+Gi$/, t("platformService.serviceApp.appComponent.persistence.inputControl.errorNotANumber")),
      k8storage: Yup.object().shape({
        publicname: Yup.string()
          .required(t("platformService.serviceApp.appComponent.resourceSettings.persistence.dropdown.requiredMessage")),
      })
    });

    let schemaFields = {
      componentLabel: Yup.string()
        .required(t("platformService.serviceApp.appComponent.labelDropdown.errorMessage")),
      // ... Validations which are always applied ...
    };

    if (this.state.showLoadBalancer && this.state.componentProtocolId !== null) {
      schemaFields.enabledProtocols = Yup.array()
        .min(1, t("platformService.serviceApp.appComponent.resourceSettings.protocolCheckbox.requiredMessage"));
    }

    if (this.state.showComponentPods === true) {
      schemaFields.componentPods = Yup.number()
        .required(t("platformService.serviceApp.appComponent.resourceSettings.numberPodsControl.errorMessage"))
        .min(this.state.componentMinimumPods, t("platformService.serviceApp.appComponent.resourceSettings.numberPodsControl.requiredMessage", { minimumPods: this.state.componentMinimumPods }))
        .test(
          'is-even',
          t("platformService.serviceApp.appComponent.resourceSettings.numberPodsControl.errorPodQuorum"),
          function (value) {
            if (this.parent.componentQuorumPods) {
              return value % 2 === 1;  // If the value is even, the validation is ok.
            }
            return true;  // If the value is odd, doesnt applies the quorum restriction.
          }
        );
    }

    if (this.state.showResourceParams === true) {
      schemaFields = {
        ...schemaFields,
        componentCPU: Yup.number()
          .min(this.state.componentMinCPU, t("platformService.serviceApp.appComponent.resourceSettings.cpuRequest.errorMessage"))
          .test('cpu-limit', t("platformService.serviceApp.appComponent.resourceSettings.cpuRequest.errorLimitMessage"), function (value) {
            return value <= this.parent.componentCPULimit;
          })
          .required(t("platformService.serviceApp.appComponent.resourceSettings.cpuRequest.requiredMessage")),
        componentMem: Yup.number()
          .min(this.state.componentMinMem, t("platformService.serviceApp.appComponent.resourceSettings.memRequest.errorMessage"))
          .test('mem-limit', t("platformService.serviceApp.appComponent.resourceSettings.memRequest.errorLimitMessage"), function (value) {
            return value <= this.parent.componentMemLimit;
          })
          .required(t("platformService.serviceApp.appComponent.resourceSettings.memRequest.requiredMessage")),
        componentCPULimit: Yup.number()
          .min(this.state.componentMinCPU, t("platformService.serviceApp.appComponent.resourceSettings.cpuLimit.errorMessage"))
          .required(t("platformService.serviceApp.appComponent.resourceSettings.cpuLimit.requiredMessage")),
        componentMemLimit: Yup.number()
          .min(this.state.componentMinMem, t("platformService.serviceApp.appComponent.resourceSettings.memLimit.errorMessage"))
          .required(t("platformService.serviceApp.appComponent.resourceSettings.memLimit.requiredMessage"))
      };
    }

    if (this.state.componentStorageId !== null && this.state.componentPersistences !== null) {
      schemaFields.componentPersistences = Yup.array().of(persistenceSchema);
    }

    return Yup.object().shape(schemaFields);
  };

  render() {
    const t = this.props.i18n.t;

    const backendValues = {
      loadbalancerSwitch: this.state.loadbalancer,
      enabledProtocols: this.state.componentEnabledProtocols,
      componentPods: this.state.componentPods,
      componentQuorumPods: this.state.componentQuorumPods,
      componentLabel: this.state.componentLabelName || '',
      componentLabelId: this.state.componentLabelId || '',
      componentCPU: this.state.componentCPU || '',
      componentMem: this.state.componentMem || '',
      componentCPULimit: this.state.componentCPULimit || '',
      componentMemLimit: this.state.componentMemLimit || '',
      componentPersistences: this.state.componentPersistences || []
    }

    const validationSchema = this.createDynamicYupSchema();

    return (
      <>
        <Col sm="auto" className="settings-section sub-section">
          <Formik
            initialValues={backendValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              this.updateData(values);
            }}
            enableReinitialize >
            {({ setFieldValue, ...formikProps }) => {
              return (
                <Form className="form-wrapper" key={this.state.componentId}>
                  <div className="form-group">
                    <Row className="sub-section-header"
                      id={'component-' + this.state.componentId}
                      onClick={(e) => {
                        this.toggleAccessSection(e)
                      }}>
                      <Col sm="6" className={`sub-section-title ${this.state.accordionOpened ? "expanded" : ""}`}>
                        <i className="icon-cube"></i>
                        <span className="software-version">{t("platformService.serviceApp.appComponent.componentVersion", { componentVersion: this.state.componentVersion })}</span>
                        <h4>{this.state.componentName}</h4>
                      </Col>
                      <Col sm="6" className="sub-section-btn">
                        <i className={`elem-settings-btn ${this.state.accordionOpened ? "icon-up-pike expanded" : "icon-down-pike"}`}></i>
                      </Col>
                    </Row>
                    <Collapse
                      isOpen={this.state.accordionOpened}
                      className="sub-section-body">
                      <Row>
                        <Col sm="12">
                          <div className="settings-section highlighted-section sub-section app-component">
                            <section className="highlighted-sub-section">
                              <h5>{t("platformService.serviceApp.appComponent.resourceSettings.title")}</h5>
                              {this.state.showLoadBalancer === true &&
                                this.state.componentProtocolId !== null &&
                                <Row className="component-row">
                                  <Col sm="6">
                                    <div className="settings-wrapper">
                                      <Row>
                                        <Col sm="12">
                                          <div className="custom-radio-button-wrapper form-group">
                                            <legend>{t("platformService.serviceApp.appComponent.resourceSettings.protocolCheckbox.label")}</legend>
                                            {this.renderProtocols(formikProps)}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="settings-wrapper">
                                      <Row>
                                        <Col sm="8" xl={{ offset: 1 }}>
                                          <Label htmlFor={'loadbalancerswitch-' + this.state.componentId} sm="auto">
                                            {t("platformService.serviceApp.appComponent.resourceSettings.loadBalancerControl.label")}
                                          </Label>
                                          <CustomField
                                            shape="switch"
                                            id={'loadbalancerswitch-' + this.state.componentId}
                                            name="loadbalancerSwitch"
                                            component={LainoSwitch}
                                            disabled={this.state.appState === "UPDATING" ? true : false}
                                            disableStyles={this.state.appState !== "UPDATING" ? "" : "disabled disabled"}
                                            hexColor={this.props.hexColor} />
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              }
                              <Row className="component-row">
                                {this.state.showComponentPods === true &&
                                  <Col sm="6">
                                    <div className="settings-wrapper">
                                      <Row>
                                        <Col sm="8">
                                          <Label htmlFor={'componentpods-' + this.state.componentId} sm="auto">
                                            {t("platformService.serviceApp.appComponent.resourceSettings.numberPodsControl.label")}
                                          </Label>
                                          <CustomField
                                            type="number"
                                            id={'componentpods-' + this.state.componentId}
                                            name="componentPods"
                                            className="custom-range form-control"
                                            sm="auto"
                                            min={this.state.componentMinimumPods}
                                            placeholder={t("platformService.serviceApp.appComponent.resourceSettings.numberPodsControl.placeHolder")} />
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                }
                                <Col sm="6">
                                  <div className="settings-wrapper">
                                    <Row>
                                      <Col sm="8" xl={this.state.showComponentPods === true ? { offset: 1 } : "8"}>
                                        <Label htmlFor={'componentlabeldropdown-' + this.state.componentId} sm="auto">{t("platformService.serviceApp.appComponent.labelDropdown.title")}</Label>
                                        {this.renderLabels(formikProps, setFieldValue)}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                              {this.state.showResourceParams &&
                                <>
                                  <Row className="component-row">
                                    <Col sm="6">
                                      <div className="settings-wrapper">
                                        <Row>
                                          <Col sm="8">
                                            <Label htmlFor={'componentcpurangebar-' + this.state.componentId} sm="auto">
                                              {t("platformService.serviceApp.appComponent.resourceSettings.cpuRequest.label")}
                                            </Label>
                                            <CustomField
                                              as="input"
                                              id={'componentcpurangebar-' + this.state.componentId}
                                              name="componentCPU"
                                              type="range"
                                              className="custom-range form-control-range"
                                              sm="auto"
                                              min={this.state.componentMinCPU}
                                              max={this.state.componentCPULimit}
                                              step={10} />
                                          </Col>
                                          <Col xs="2" sm="4" xl="3">
                                            <CustomField
                                              type="text"
                                              name="componentCPU"
                                              className="range-textbox form-control"
                                              sm="auto"
                                              placeholder={t("platformService.serviceApp.appComponent.resourceSettings.cpuRequest.placeHolder")}
                                              min={this.state.componentMinCPU}
                                              max={this.state.componentCPULimit}
                                              showError={false} />
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col sm="6">
                                      <div className="settings-wrapper">
                                        <Row>
                                          <Col sm="8" xl={{ offset: 1 }}>
                                            <Label htmlFor={'componentmemrangebar-' + this.state.componentId} sm="auto">
                                              {t("platformService.serviceApp.appComponent.resourceSettings.memRequest.label")}
                                            </Label>
                                            <CustomField
                                              as="input"
                                              type="range"
                                              id={'componentmemrangebar-' + this.state.componentId}
                                              name="componentMem"
                                              className="custom-range form-control-range"
                                              min={this.state.componentMinMem}
                                              max={this.state.componentMemLimit}
                                              step={10}
                                              sm="auto" />
                                          </Col>
                                          <Col xs="2" sm="4" xl="3">
                                            <CustomField
                                              type="text"
                                              name="componentMem"
                                              className="range-textbox form-control"
                                              sm="auto"
                                              placeholder={t("platformService.serviceApp.appComponent.resourceSettings.memRequest.placeHolder")}
                                              min={this.state.componentMinMem}
                                              max={this.state.componentMemLimit}
                                              showError={false} />
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="component-row">
                                    <Col sm="6">
                                      <div className="settings-wrapper">
                                        <Row>
                                          <Col sm="8">
                                            <Label htmlFor={'componentcpulimitrangebar-' + this.state.componentId} sm="auto">
                                              {t("platformService.serviceApp.appComponent.resourceSettings.cpuLimit.label")}
                                            </Label>
                                            <CustomField
                                              as="input"
                                              type="range"
                                              id={'componentcpulimitrangebar-' + this.state.componentId}
                                              name="componentCPULimit"
                                              className="custom-range form-control-range"
                                              min={this.state.componentMinCPU}
                                              max={16000}
                                              step={10}
                                              sm="auto" />
                                          </Col>
                                          <Col xs="2" sm="4" xl="3">
                                            <CustomField
                                              type="text"
                                              name="componentCPULimit"
                                              className="range-textbox form-control"
                                              placeholder={t("platformService.serviceApp.appComponent.resourceSettings.cpuLimit.placeHolder")}
                                              min={this.state.componentMinCPU}
                                              max={16000}
                                              sm="auto"
                                              showError={false} />
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col sm="6">
                                      <div className="settings-wrapper">
                                        <Row>
                                          <Col sm="8" xl={{ offset: 1 }}>
                                            <Label htmlFor={'componentmemlimitrangebar-' + this.state.componentId} sm="auto">
                                              {t("platformService.serviceApp.appComponent.resourceSettings.memLimit.label")}
                                            </Label>
                                            <CustomField
                                              as="input"
                                              type="range"
                                              id={'componentmemlimitrangebar-' + this.state.componentId}
                                              name="componentMemLimit"
                                              className="custom-range form-control-range"
                                              sm="auto"
                                              min={this.state.componentMinMem}
                                              max={16000}
                                              step={10} />
                                          </Col>
                                          <Col xs="2" sm="4" xl="3">
                                            <CustomField
                                              type="text"
                                              name="componentMemLimit"
                                              className="range-textbox form-control"
                                              sm="auto"
                                              placeholder={t("platformService.serviceApp.appComponent.resourceSettings.memLimit.placeHolder")}
                                              showError={false} />
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                </>
                              }
                            </section>
                            {this.state.componentConfigMergedArray &&
                              this.state.componentConfigMergedArray.length !== 0 &&
                              <section className="highlighted-sub-section">
                                <Row>
                                  <Col sm="12">
                                    <h5>{t("platformService.serviceApp.appComponent.configurationSettings.title")}</h5>
                                    <ul className="settings-conf-list">
                                      {this.renderConfigurations()}
                                    </ul>
                                  </Col>
                                </Row>
                              </section>
                            }
                            {this.state.componentStorageId !== null &&
                              this.state.componentPersistences !== null &&
                              this.state.componentPersistences.length > 0 &&
                              <section className="highlighted-sub-section">
                                <h5>{t("platformService.serviceApp.appComponent.storageSettings.title")}</h5>
                                {this.renderPersistences(formikProps, setFieldValue)}
                              </section>
                            }
                            <section>
                              <Row>
                                <Col sm="12">
                                  <div className="section-submit-wrapper">
                                    <Button
                                      disabled={!formikProps.dirty}
                                      type="submit">{t("common.update")}</Button>
                                  </div>
                                </Col>
                              </Row>
                            </section>
                          </div>
                        </Col>
                      </Row>
                    </Collapse>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Col>
      </>
    )
  }
};
export default AppComponent;

AppComponent.propTypes = {
  appcomponentprotocols: PropTypes.array
}