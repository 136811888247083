import React from "react";
import { dataService } from "tools/dataService/dataService"
import { platformStateTag } from "tools/commonFunctions";
import { trackPromise } from "react-promise-tracker";
import ServiceApp from "./ServiceApp"

import {
  Col,
  Row,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  UncontrolledTooltip
} from "reactstrap";

class PlatformService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceId: this.props.serviceId,
      serviceName: this.props.serviceName,
      serviceImage: this.props.serviceImage,
      kubernetesState: this.props.kubernetesState,
      kubernetesStateLevel: this.props.kubernetesStateLevel,
      kubeAccessStatus: this.props.kubeAccessStatus,
      kubeConnectStatus: this.props.kubeConnectStatus,
      apps: []
    }
  }

  componentDidMount() {
    let activePlatformDataObj = JSON.parse(localStorage.getItem('ActivePlatformData'));
    trackPromise(
      dataService.queries.getPlatformSingleServiceData(this.state.serviceId).then(
        (dataserviceapps) => {
          this.setState({
            apps: dataserviceapps,
            kubeHealthCheckClass: activePlatformDataObj.PlatformHealthCheckClass,
            healthCheckMessage: activePlatformDataObj.PlatformHealthCheckClass === "running" ? "Connected" : "Not connected, check the platform section for more info"
          })
        }
      )
    )
  }

  render() {
    const t = this.props.i18n.t;
    let apps = this.state.apps;
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <section className="section-wrapper">
                <h1 className="section-title">
                  <i className={this.state.serviceImage} />
                  <span className="context-root">{this.state.serviceName}</span>|
                  <span
                    id={"PlatformStatus-" + this.state.serviceId}
                    className={"status-icon " + this.state.kubeHealthCheckClass}>
                    <UncontrolledTooltip
                      placement="right"
                      target={"PlatformStatus-" + this.state.serviceId}
                    > {this.state.healthCheckMessage} </UncontrolledTooltip>
                  </span>
                  <span className="context-info">{this.props.kubernetesPlatformName}</span>
                  {platformStateTag(this.state.kubernetesState, this.state.kubernetesStateLevel)}
                </h1>
              </section>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">{t("platformService.title")}</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table id="Table" className="tablesorter" responsive>
                    <thead className="text-primary service-apps">
                      <tr className="theader">
                        <th className="row-1 row-statusaction">{t("platformService.action")}</th>
                        <th className="row-2 row-appname">{t("kubernetesPlatform.tableCreation.name")}</th>
                        <th className="row-3 row-services">{t("platformService.applications")}</th>
                        <th className="row-4 row-management">{t("kubernetesPlatform.tableCreation.management")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {apps.map((app, key) => {
                        return (
                          <>
                            <ServiceApp
                              t={t}
                              key={key}
                              app={app}
                              hexColor={this.props.hexColor}
                            />
                          </>
                        )
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    )
  }
};
export default PlatformService