import * as BackEndConsts from "tools/constants/backEndConstants";
const createQueriesLainotik = (httpClient, dataServiceFunctions, i18n) => {
  /* - Company data query - Gets all information related to a company - main level in terms of the structure - Company Page */
  const getCompanyData = async (companyId) => {
    let GET_COMPANYBYID = {
      "query": `query Company($companyId: ID!){
        company
        (
          id: $companyId
        )
        {
          id
          name
          address
          zipcode
          city
          country {
            id
            name
            code
          }
          activation
          accountcompanies {
            account {
              email
              firstname
              lastname
            }
            role {
              id
            }
          }
          security{
            roleid
            rolename
            companycreate
            k8create
            accountcreate
          }
        }
      }`,
      "variables": {
        "companyId": companyId
      }
    };

    return await httpClient
      .httpPostRequestGraphQL(GET_COMPANYBYID, this)
      .then(response => {
        let companyresponse = response.data.data.company;
        if (companyresponse !== null) {
          return companyresponse;
        } else {
          return dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: "DANGER",
                messagetitle: i18n.t("common.commDataErrorTitle"),
                message: companyresponse
              }
            }
          });
        }
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Get Country list for the Company data selector - Company level */
  const getCountries = async () => {
    let GET_COUNTRIES = {
      "query": `query Countries {
          countries {
            id
            code
            name
          }
        }`,
      "variables": {}
    }
    return await httpClient
      .httpPostRequestGraphQL(GET_COUNTRIES, this)
      .then(response => {
        let countriesresponse = response.data.data.countries;
        if (countriesresponse !== null) {
          return countriesresponse;
        } else {
          return dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: "DANGER",
                messagetitle: i18n.t("common.commDataErrorTitle"),
                message: countriesresponse
              }
            }
          });
        }
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - User data query - Gets all the info of the account which has initiated a session - Profile level */
  const getUserProfile = async (accountId) => {
    let GET_USER = {
      "query": `query Accountprofile($accountid: ID!){
          accountprofile(
            accountid: $accountid
          )
          {
            response {
              code
              error
              message
              messageinfo {
                message
                messagelevel
                messagetitle
              }
            }
            accountcompany {
              role {
                id
                name
              }
              account {
                id
                firstname
                lastname
                email
              }
            }
          }
        }`,
      "variables": {
        "accountid": accountId
      }
    }
    try {
      const response = await httpClient.httpPostRequestGraphQL(GET_USER, this);

      let accountcompanyresp = response.data.data.accountprofile.response;

      if (accountcompanyresp.code !== BackEndConsts.RESPONSE_SUCCESS) {
        dataServiceFunctions.handleBackEndErrorMessage({
          response: {
            messageinfo: {
              messagelevel: "DANGER",
              messagetitle: accountcompanyresp.error,
              message: accountcompanyresp.message
            }
          }
        });
        return BackEndConsts.BACKEND_RESPONSE_ERROR;
      }

      return response.data.data.accountprofile.accountcompany;

    } catch (error) {
      dataServiceFunctions.handleBackEndInternalError(error);
      return BackEndConsts.BACKEND_RESPONSE_ERROR;
    }
  };

  /* Get the account and its information for a search done within an specific company - Company level, Admin level */
  const getAccountInCompany = async (companyId, email) => {
    let GET_ACCOUNT_BY_EMAIL = {
      "query": `query AccountcompanyByEmail($email: String!, $companyid: ID){
          accountcompanyByEmail (
            companyid: $companyid,
            email: $email
          )
          {
            response {
              code
              error
              message
              messageinfo {
                messagelevel
                messagetitle
                message
              }
            }
            accountcompany {
              account {
                id
                email
                firstname
                lastname
              }
              role {
                id
                name
              }
            }
          }
        }`,
      "variables": {
        "companyid": companyId,
        "email": email
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(GET_ACCOUNT_BY_EMAIL, this)
      .then(response => {
        let backEndResponse = response.data.data.accountcompanyByEmail;
        let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
        return backEndResponseData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Main query for Platform Data - Gets all platforms at company level, Admin level */
  const getKubernetesData = async (companyId) => {
    let GET_KUBERNETES = {
      "query": `query KubernetesplatformByCompany($kubernetesplatformByCompanyId: ID!){
          kubernetesplatformByCompany (
            id: $kubernetesplatformByCompanyId
          ) 
          {
            id
            name
            demo
            state
            security {
              accountmanage
            }
            clsaddmon
            connectionstate
            deploymentstateinfo {
              codeinfo
              codeinfolevel
              message
              messagelevel
              messagetitle
            }
            company {
              id
              name
              activation
              security {
                roleid
                rolename
                companycreate
                k8create
                accountcreate
              }
            }
            clusteraddress
            externaliprangestart
            externaliprangeend
            creationdate
            lainotikmonitoring
            skiptlsverify
            k8certs {
              id
              authmode
              token
              clientcsr
              clientcrt
              cacrt
              expirationdate
            }
            k8nodes {
              id
              name
              label
              maxpods
              cores
              memory
              usedcores
              usedmemory
              creation
              architecture
              os
              osimage
              kernel
              kubelet
              kubeproxy
            }
            k8storages {
              id
              default
              publicname
              reclaimpolicy
              volumebindingmode
              allowvolumeexpansion
              provisioner
            }
            k8labels {
              id
              name
              default
            }
            k8addons {
              id
              index
              publicname
              deployfinishedstate
              message
              lastnotificationdate
              deployby
              deployduration
              deploydate
              deploymentstateinfo {
                codeinfolevel
                codeinfo
                code
                messagelevel
                messagetitle
                message         
              }
            }
            companyservices {
              id
              name
              state
              image
              companyserviceapps {
                id
                name
                tooltip
                enabled
                state
                editable
                message
                deployfinishedstate
                deploydate
                deployby
                deployduration
                deploynotificationerror
                onlineusercount
                notifications {
                  id
                  name
                  phase
                  podscheduled
                  initialized
                  ready
                }
                onlineusers {
                  id
                  account {
                    email
                  }
                }
                k8endpoints {
                  type
                  id
                  domain
                  clientkey
                  clientcrt
                }
                companyservice {
                  kubeplatform {
                    company {
                      cacrt
                      cakey
                    }
                  }
                }
                appclusters {
                  id
                  name
                  type
                  tooltip
                  url
                  image
                }
                appcomponents {
                  id
                  show
                  imageversion
                  imageversion_toupgrade
                  publicname
                  name
                  podsnumber
                  lowerlimitpods
                  quorumpods
                  loadbalancer
                  showhwresources
                  cpureq
                  cpureq_default
                  cpulimit
                  cpulimit_default
                  memreq
                  memreq_default
                  memlimit
                  memlimit_default
                }
              }
            }
            plan {
              id
              name
            }
            infraprovider {
              id
              name
            }
          }
        }`,
      "variables": {
        "kubernetesplatformByCompanyId": companyId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(GET_KUBERNETES, this)
      .then(response => {
        let datakubernetes = response.data.data.kubernetesplatformByCompany;
        if (datakubernetes !== null) {
          localStorage.setItem('KubernetesPlatform', JSON.stringify(datakubernetes));
          return datakubernetes;
        } else return datakubernetes;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Query for get info for an specific platform - Setup level */
  const getKubernetesDeployInfo = async (kubernetesId) => {
    let GET_K8S_DEPLOYINFO = {
      "query": `query Kubernetesplatform($k8Id: ID!){
          kubernetesplatform (
            k8id: $k8Id
          )
          {
            response {
              code
              error
              message
              messageinfo {
                messagelevel
                messagetitle
                message
              }
            }
            kubernetesplatform {
              id
              demo
              name
              clsaddmon
              connectionstate
              k8labeldefault
              clusteraddress
              externaliprangestart
              externaliprangeend
              lainotikmonitoring
              infraprovider {
                id
                name
              }
              plan {
                id
                name
              }
              deploymentstateinfo {
                codeinfo
                codeinfolevel
                code
                messagelevel
                messagetitle
                message
              }
              skiptlsverify
              k8certs {
                id
                authmode
                token
                clientcsr
                clientcrt
                cacrt
                expirationdate
              }
              k8nodes {
                id
                name
                label
                maxpods
                cores
                memory
                usedcores
                usedmemory
                creation
                architecture
                os
                osimage
                kernel
                kubelet
                kubeproxy
              }
              k8storages {
                id
                default
                publicname
                reclaimpolicy
                volumebindingmode
                allowvolumeexpansion
                provisioner
              }
              k8labels {
                id
                name
                default
              }
              k8addons {
                id
                index
                publicname
                deployfinishedstate
                message
                lastnotificationdate
                deployby
                deployduration
                deploydate
                deploymentstateinfo {
                  codeinfolevel
                  codeinfo
                  code
                  messagelevel
                  messagetitle
                  message         
                }
              }
              security {
                accountmanage
              }
            }
          }
        }`,
      "variables": {
        "k8Id": kubernetesId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(GET_K8S_DEPLOYINFO, this)
      .then(response => {
        let backEndResponse = response.data.data.kubernetesplatform;
        let backEndResponseData = dataServiceFunctions.handleBackEndErrorMessage(backEndResponse);
        return backEndResponseData;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Main query- gets all platforms at company level, top level */
  const getKubernetesNameAndState = async (companyId) => {
    let GET_KUBERNETES = {
      "query": `query KubernetesplatformByCompany($kubernetesplatformByCompanyId: ID!){
            kubernetesplatformByCompany (
              id: $kubernetesplatformByCompanyId
            ) 
            {
              id
              name
              demo
              security {
                accountmanage
              }
              deploymentstateinfo {
                codeinfo
                codeinfolevel
              }
              company {
                id
                name
                activation
                security {
                  roleid
                  rolename
                  companycreate
                  k8create
                  accountcreate
                }
              }
              companyservices {
                id
                name
                state
                image
              }
            }
          }`,
      "variables": {
        "kubernetesplatformByCompanyId": companyId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(GET_KUBERNETES, this)
      .then(response => {
        let datakubernetes = response.data.data.kubernetesplatformByCompany;
        if (datakubernetes !== null) {
          localStorage.setItem('KubernetesPlatform', JSON.stringify(datakubernetes));
          return datakubernetes;
        } else return datakubernetes;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Requests the plans defined in the Database, value required for a platform creation setup */
  const getPlans = async () => {
    let GET_PLANS = {
      "query": `query Plans{
          plans {
            id
            name
          }
        }`,
      "variables": {}
    }
    return await httpClient
      .httpPostRequestGraphQL(GET_PLANS, this)
      .then(response => {
        let plansresponse = response.data.data.plans;
        if (plansresponse !== null) {
          return plansresponse;
        } else {
          return dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: "DANGER",
                messagetitle: i18n.t("common.commDataErrorTitle"),
                message: plansresponse
              }
            }
          });
        }
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Requests the infraproviders defined in the Database, value required for a platform creation setup */
  const getInfraProviders = async () => {
    let GET_INFRAPROVIDERS = {
      "query": `query Infraproviders{
          infraproviders {
            id
            name
          }
        }`,
      "variables": {}
    }
    return await httpClient
      .httpPostRequestGraphQL(GET_INFRAPROVIDERS, this)
      .then(response => {
        let infraprovidersresponse = response.data.data.infraproviders;
        if (infraprovidersresponse !== null) {
          return infraprovidersresponse;
        } else {
          return dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: "DANGER",
                messagetitle: i18n.t("common.commDataErrorTitle"),
                message: infraprovidersresponse
              }
            }
          });
        }
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Creates an empty kubernetes object */
  const createEmptyKubernetesPlatform = async (companyId) => {
    let NEW_KUBERNETES = {
      "query": `query Emptykubernetesplatform($companyid: ID!){
          emptykubernetesplatform(
            companyid: $companyid
          ) 
          {
            id
            name
            deploymentstateinfo {
              codeinfo
              codeinfolevel
              message
              messagelevel
              messagetitle
            }
            showiprange
            infraproviderid
            infraprovidername
            lainotikmonitoring
            clusteraddress
            creationdate
            planid
            planname
          }
        }`,
      "variables": {
        "companyid": companyId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(NEW_KUBERNETES, this)
      .then(response => {
        let newkuberesponse = response.data.data.emptykubernetesplatform;
        if (newkuberesponse !== null) {
          localStorage.removeItem('KubernetesPlatform');
          return newkuberesponse;
        } else {
          return dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: "DANGER",
                messagetitle: i18n.t("common.commDataErrorTitle"),
                message: newkuberesponse
              }
            }
          });
        }
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Gets services info at an specific platform level */
  const getPlatformServicesData = async (kubernetesActiveId) => {
    let GET_PLATFORM_SERVICES = {
      "query": `query Companyservices($k8Id: ID!){
          companyservices (
            k8id: $k8Id
          ) 
          {
            id
            name
            image
            state
            activation {
              activated
              demo
              starttime
              endtime
            }
            security {
              view
            }
            companyserviceapps {
              id
              name
            }
          }
        }`,
      "variables": {
        "k8Id": kubernetesActiveId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(GET_PLATFORM_SERVICES, this)
      .then(response => {
        let dataservices = response.data.data.companyservices;
        if (dataservices !== null) {
          return dataservices;
        } else {
          return dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: "DANGER",
                messagetitle: i18n.t("common.commDataErrorTitle"),
                message: dataservices
              }
            }
          });
        }
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Gets all apps data at service level */
  const getPlatformSingleServiceData = async (serviceId) => {
    let GET_SERVICE_APPS = {
      "query": `query Companyservices($k8Id: ID!){
          companyservice (
            id: $k8Id
          ) 
          {
            companyserviceapps {
              id
              name
              tooltip
              enabled
              state
              editable
              message
              lastnotificationdate
              deployfinishedstate
              deploydate
              deployby
              deployduration
              deploynotificationerror
              onlineusercount
              deploymentstateinfo {
                code
                codeinfo
                codeinfolevel
                message
                messagelevel
                messagetitle
              }
              notifications {
                name
                phase
                podscheduled
                initialized
                ready
              }
              appcomponents {
                id
                name
                imageversion
                imageversion_toupgrade
                show
                showpodsnumber
                showloadbalancer
                publicname
                podsnumber
                lowerlimitpods
                quorumpods
                loadbalancer
                showhwresources
                cpureq
                cpureq_default
                cpulimit
                cpulimit_default
                memreq
                memreq_default
                memlimit
                memlimit_default
              }
              security {
                tool
                console
                admin
              }
            }
          }
        }`,
      "variables": {
        "k8Id": serviceId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(GET_SERVICE_APPS, this)
      .then(response => {
        let dataserviceapps = response.data.data.companyservice.companyserviceapps;
        if (dataserviceapps !== null) {
          return dataserviceapps;
        } else {
          return dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: "DANGER",
                messagetitle: i18n.t("common.commDataErrorTitle"),
                message: companyresponse
              }
            }
          });
        }
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Gets all data for an specific app */
  const getServiceAppData = async (appId) => {
    let GET_SERVICEAPP = {
      "query": `query Companyserviceapp($companyserviceappId: ID!){
          companyserviceapp (
            id: $companyserviceappId
          ) 
          {
            id
            name
            tooltip
            enabled
            state
            editable
            message
            deployfinishedstate
            deploydate
            deployby
            deployduration
            deploynotificationerror
            onlineusercount
            onlineusers {
              account {
                email
              }
            }
            deploymentstateinfo {
              code
              codeinfo
              codeinfolevel
              message
              messagelevel
              messagetitle
            }
            notifications {
              id
              name
              phase
              podscheduled
              initialized
              ready
            }
            appclusters {
              id
              name
              type
              tooltip
              url
              image
            }
            appcomponents {
              id
              name
              imageversion
              imageversion_toupgrade
              show
              showpodsnumber
              showloadbalancer
              publicname
              podsnumber
              lowerlimitpods
              quorumpods
              loadbalancer
              showhwresources
              cpureq
              cpureq_default
              cpulimit
              cpulimit_default
              memreq
              memreq_default
              memlimit
              memlimit_default
              k8label {
                id
                name
                default
              }
              k8storage {
                id
                publicname
                default
              }
              appcomponentpersistences {
                id
                name
                size
                storageclass
                k8storage {
                  id
                  publicname
                }
              }
              appcomponentprotocols {
                id
                enabled
                protocol {
                  id
                  name
                }
              }
              appcomponentconfigs {
                id
                publicname
                value
                defaultvalue
                configtype
                format
                description
                showdefault
              }
              appcomponentsecrets {
                id
                type
                name
                value
              }
            }
            k8endpoints {
              id
              type
              domain
              clientkey
              clientcrt
              protocols
              certexpirationdate
            }
            companyservice {
              kubeplatform {
                company {
                  cacrt
                }
                k8storages {
                  id
                  publicname
                  default
                }
                k8labels {
                  id
                  name
                  default
                }
              }
            }
            activation {
              activated
              demo
              starttime
              endtime
            }
            security {
              admin
              console
              tool
            }
          }
        }`,
      "variables": {
        "companyserviceappId": appId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(GET_SERVICEAPP, this)
      .then(response => {
        let dataapp = response.data.data.companyserviceapp;
        if (dataapp !== null) {
          return dataapp;
        } else return dataapp;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Get monitoring data - Platform> Platform Status> monitoringStatus view */
  // BACKEND - Pass to the response / data object model to control correctly the errors
  const getMonitoringAppData = async (appId) => {
    let GET_MONITORING = {
      "query": `query Companyserviceapp($companyserviceappId: ID!){
        companyserviceapp (
          id: $companyserviceappId
        ) 
        {
          deploydate
          deployduration
          deployby
          deploymentstateinfo {
            code
            codeinfo
            codeinfolevel
            message
            messagelevel
            messagetitle
          }
          notifications {
            id
            name
            phase
            podscheduled
            initialized
            ready
          }
          companyservice {
            kubeplatform {
              deploymentstatecode
              deploymentstateinfo {
                codeinfo
                codeinfolevel
              }
            }
          }
        }
      }`,
      "variables": {
        "companyserviceappId": appId
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(GET_MONITORING, this)
      .then(response => {
        let dataapp = response.data.data.companyserviceapp;
        if (dataapp !== null) {
          dataapp = response.data.data.companyserviceapp;
          return dataapp;
        } else {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: "DANGER",
                messagetitle: i18n.t("common.commDataErrorTitle"),
                message: i18n.t("common.commDataErrorMsg")
              }
            }
          })
          dataapp = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return dataapp;
        }
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Get admin email - Obtains the Lainotik platform SUPERADMIN email */
  const getSUAccount = async () => {
    let GET_SU_ACCOUNT = {
      "query": `query Lainotik{
          lainotik  
          {
            su_account
          }
        }`,
      "variables": {}
    }
    return await httpClient
      .httpPostRequestGraphQL(GET_SU_ACCOUNT, this)
      .then(response => {
        let datasuaccount = response.data.data.lainotik;
        if (datasuaccount !== null) {
          datasuaccount = response.data.data.lainotik;
          return datasuaccount;
        } else {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: "DANGER",
                messagetitle: i18n.t("common.commDataErrorTitle"),
                message: i18n.t("common.commDataErrorMsg")
              }
            }
          })
          datasuaccount = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return datasuaccount;
        }
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      )
  };

  /* - Get the parameter CONFIG configurations per component - Service > App > Component level */
  const getAppComponentConfiguration = async (id) => {
    let GET_COMPONENT_PARAM_CONFIG = {
      "query": `query AppComponentConfig($appComponentConfigId: String!){
          appComponentConfig(
            id: $appComponentConfigId
          )
          {
            response {
              code
              error
              message
              messageinfo {
                message
                messagelevel
                messagetitle
                messagecode
              }
            }
            config {
              id
              value
              publicname
              type
              configtype
              format
              description
              showdefault
              defaultvalue
            }
          }
        }`,
      "variables": {
        "appComponentConfigId": id
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(GET_COMPONENT_PARAM_CONFIG, this)
      .then(response => {
        let datacompconfig = response.data.data.appComponentConfig;
        if (datacompconfig.response.code !== BackEndConsts.RESPONSE_SUCCESS
          && datacompconfig.response.message !== "OK") {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: datacompconfig.response.messageinfo.messagelevel,
                messagetitle: datacompconfig.response.messageinfo.messagetitle,
                message: datacompconfig.response.messageinfo.message
              }
            }
          })
          datacompconfig = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return datacompconfig;
        } else return datacompconfig;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  /* - Get the parameter SECRET type configurations per component - Service > App > Component level */
  const getAppComponentSecretConfiguration = async (id) => {
    let GET_COMPONENT_SECRET_PARAM_CONFIG = {
      "query": `query AppComponentSecret($appComponentSecretId: String!){
          appComponentSecret(
            id: $appComponentSecretId
          )
          {
            response {
              code
              error
              message
              messageinfo {
                message
                messagelevel
                messagetitle
                messagecode
              }
            }
            secret {
              id
              value
              name
              type
            }
          }
        }`,
      "variables": {
        "appComponentSecretId": id
      }
    }
    return await httpClient
      .httpPostRequestGraphQL(GET_COMPONENT_SECRET_PARAM_CONFIG, this)
      .then(response => {
        let datacompsecretconfig = response.data.data.appComponentSecret;
        if (datacompsecretconfig.response.code !== BackEndConsts.RESPONSE_SUCCESS
          && datacompsecretconfig.response.message !== "OK") {
          dataServiceFunctions.handleBackEndErrorMessage({
            response: {
              messageinfo: {
                messagelevel: datacompsecretconfig.response.messageinfo.messagelevel,
                messagetitle: datacompsecretconfig.response.messageinfo.messagetitle,
                message: datacompsecretconfig.response.messageinfo.message
              }
            }
          })
          datacompsecretconfig = BackEndConsts.BACKEND_RESPONSE_ERROR;
          return datacompsecretconfig;
        } else return datacompsecretconfig;
      },
        error => {
          dataServiceFunctions.handleBackEndInternalError(error);
        }
      );
  };

  return {
    getCompanyData,
    getCountries,
    getUserProfile,
    getAccountInCompany,
    getKubernetesData,
    getKubernetesDeployInfo,
    getKubernetesNameAndState,
    getPlans,
    getInfraProviders,
    createEmptyKubernetesPlatform,
    getPlatformServicesData,
    getPlatformSingleServiceData,
    getServiceAppData,
    getMonitoringAppData,
    getSUAccount,
    getAppComponentConfiguration,
    getAppComponentSecretConfiguration
  };
};
export { createQueriesLainotik };