import React from "react";
import * as BackEndConsts from "tools/constants/backEndConstants";
import {
  platformNotifMessages,
  logout,
  CustomField
} from "tools/commonFunctions";
import i18n from "localization/i18n";
import { withTranslation } from "react-i18next";
import { dataService } from "tools/dataService/dataService"
import { trackPromise } from "react-promise-tracker";
import SimpleFooter from "components/Footer/SimpleFooter";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
  Container,
  Row,
  Col,
  Label,
  Button
} from "reactstrap";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountPassword: "",
      newPasswordSet: false,
      isPasswordShown: false
    };
  }

  componentDidMount() {
    i18n.changeLanguage(i18n.language || 'en');
  }

  handleFormSubmit = (values) => {
    trackPromise(
      dataService.mutations.changePassword(values.accountPassword).then(
        (datachangepasswordData) => {
          if (datachangepasswordData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            platformNotifMessages(
              datachangepasswordData.messagelevel,
              datachangepasswordData.messagetitle,
              datachangepasswordData.message
            )
            this.setState({ newPasswordSet: true });
            setTimeout(() => {
              logout();
            }, 3000);
          } else return null;
        }
      )
    );
  };

  togglePasswordVisibility = () => {
    this.setState({
      isPasswordShown: !this.state.isPasswordShown
    })
  }

  render() {
    const { t } = this.props;

    const validationSchema = Yup.object().shape({
      accountPassword: Yup.string()
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).\S{8,}$/, t("login.loginError.incorrectPassword"))
        .required(t("login.passwordPlaceholder"))
    });

    return (
      <Container className="h-100vh">
        <Row className="justify-content-center align-items-center">
          <Col md="6">
            <div className="content signup-form-wrapper">
              <img
                className="lainotik-logo"
                alt="Lainotik logo"
                src={require('assets/img/condensed-pink.svg').default} />
            </div>
          </Col>
        </Row>
        <div className="signup-form-wrapper">
          <Row className="justify-content-center align-items-center">
            <Col fluid="sm" className="section-header">
              <section className="section-title">
                <h1>{t("resetPassword.title")}</h1>
              </section>
              <section className="section-intro">
                <p>{t("forgotPassword.recoveryExplanation")}</p>
              </section>
            </Col>
          </Row>
          <Formik
            initialValues={{ accountPassword: this.state.accountPassword }}
            validationSchema={validationSchema}
            onSubmit={(values) => this.handleFormSubmit(values)}>
            {({ ...formikProps }) => (
              <Form>
                <Row className="justify-content-center align-items-center">
                  <Col sm="6">
                    <fieldsection>
                      <div className="form-password-wrapper">
                        <Label htmlFor="accountpassword">{t("login.passwordLabel")}</Label>
                        <Button className="form-btn-show-password" onClick={this.togglePasswordVisibility}>
                          <i className={this.state.isPasswordShown ? "icon-eye-closed" : "icon-eye"}></i>
                          <div>{this.state.isPasswordShown ? t("common.passwordIcon.hide") : t("common.passwordIcon.show")}</div>
                        </Button>
                      </div>
                      <CustomField
                        type={this.state.isPasswordShown ? "text" : "password"}
                        id="accountpassword"
                        name="accountPassword"
                        className="login-pass form-control"
                        placeholder={t("resetPassword.passwordPlaceholder")}/>
                    </fieldsection>
                  </Col>
                </Row>
                <Row className="justify-content-center align-items-end">
                  <Col md="3">
                    <Button
                      className="info-wrapper"
                      onClick={() => { this.props.history.goBack() }}>
                      {t("common.goBack")}
                    </Button>
                  </Col>
                  <Col sm="3">
                    <Button className="form-btn align-right" type="submit">
                      {t("forgotPassword.resetPasswordButton")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          <SimpleFooter />
        </div>
      </Container>
    )
  }
}

export default withTranslation()(ResetPassword);
