import React from "react";

import { Link } from "react-router-dom";

import {
  Row,
  Col
} from "reactstrap";

import { withTranslation } from "react-i18next";

import SimpleFooter from "components/Footer/SimpleFooter";

class ConfirmPage extends React.Component {
  constructor(props) {
    super();
    this.state = {}
  };

  render() {
    const { t } = this.props; //Translate function inherited from parent component
    return (
      <>
        <Row className="justify-content-center align-items-center">
          <Col md="6">
            <div className="content signup-form-wrapper">
              <img
                className="lainotik-logo"
                alt="Lainotik logo"
                src={require('assets/img/condensed-pink.svg').default} />
            </div>
          </Col>
        </Row>
        <div className="signup-form-wrapper">
          <Row className="justify-content-center align-items-center">
            <Col fluid="sm" className="section-header">
              <section className="section-title">
                <h1>{t("signup.signupConfirmPage.title")}</h1>
              </section>
              <section className="section-intro">
                <p className="signup-confirmation">
                  {t("signup.signupConfirmPage.confirmPart1")}{" "}
                  <span className="signup-email">{this.props.accountEmail}</span>
                  {". "}{t("signup.signupConfirmPage.confirmPart2")}
                </p>
              </section>
              <span className="signup-link">
                <Link to="/">{t("signup.signupConfirmPage.backToLogin")}</Link>
              </span>
            </Col>
          </Row>
          <SimpleFooter />
        </div>
      </>
    )
  }
}
export default withTranslation()(ConfirmPage);