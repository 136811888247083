import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

import { NavLink as RouterLink } from "react-router-dom";
import { logout } from "tools/commonFunctions";
import UserContextInfo from "tools/userContextInfo";
import { routes } from "routes";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container
} from "reactstrap";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.i18n.t;
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
      forceUpdateKey: 0,
    };
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    this.setState({
      companyAccountActivation: UserContextInfo.activeCompanyActivation(),
      messageBulletColor: ""
    })
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  };

  componentDidUpdate(prevProps) {
    const { i18n } = this.props;
    if (prevProps.i18n.language !== i18n.language) {
      // The Language selected changes.
      this.forceUpdate();
    }
  }


  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-white"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };

  logOut() {
    logout();
  }

  handleLanguageChangeLoc = (language) => {
    this.props.i18n.changeLanguage(language);
    this.setState({ forceUpdateKey: this.state.forceUpdateKey + 1 });
  };

  handlePlatformMessages() {
    let AccountStatus = this.state.companyAccountActivation;
    let platformMessage;
    let messageBulletColor;
    let messageLink;
    const t = this.t;
    switch (AccountStatus) {
      case "NONE":
        platformMessage = t("accountActivation.requestActivation");
        messageBulletColor = "warning";
        messageLink = '/portal/kubernetes';
        break;
      case "REQUESTED":
        platformMessage = t("accountActivation.accountValidation");
        messageBulletColor = "info";
        messageLink = '#'
        break;
      case "ACTIVATED":
        platformMessage = t("accountActivation.accountActivated");
        messageBulletColor = "success";
        break;
      default:
        break;
    }
    return (
      <>
        <DropdownToggle caret color="default" data-toggle="dropdown" nav>
          <div className={`notification d-none d-lg-block d-xl-block ${messageBulletColor}`} />
          <i className="icon-laino-notification" />
          <p className="d-lg-none">{t("common.messages")}</p>
        </DropdownToggle>
        <DropdownMenu className="dropdown-navbar" right tag="ul">
          <NavLink
            tag="li">
            <DropdownItem
              className="nav-item"
              onClick={() => { this.props.history.push(messageLink) }}>
              {platformMessage}
            </DropdownItem>
          </NavLink>
        </DropdownMenu>
      </>
    );
  }

  render() {
    const { logo } = this.props;
    const t = this.t;
    return (
      <>
        <Navbar
          className={classNames("navbar-absolute", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="/portal/kubernetes">
                <div className="logo-img">
                  <img src={logo.imgSrc} alt="Lainotik logo" />
                </div>
              </NavbarBrand>
            </div>
            <button
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navigation"
              data-toggle="collapse"
              id="navigation"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>
                <UncontrolledDropdown>
                  <this.props.LanguageSelector handleLanguageChange={this.handleLanguageChangeLoc} />
                </UncontrolledDropdown>
                <UncontrolledDropdown nav>
                  {this.handlePlatformMessages()}
                </UncontrolledDropdown>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                    onClick={e => e.preventDefault()}>
                    <div className="avatar">
                      {UserContextInfo.userAvatar()}
                    </div>
                    <p className="d-lg-none">{UserContextInfo.userFullName()}</p>
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-navbar"
                    right tag="ul">
                    <div className="user-info">
                      <DropdownItem disabled
                        className="d-none d-lg-block user-name">
                        {UserContextInfo.userFullName()}
                      </DropdownItem>
                      <DropdownItem disabled
                        className="user-email">
                        {UserContextInfo.userEmail()}
                      </DropdownItem>
                      <DropdownItem disabled
                        className="user-role">
                        {UserContextInfo.userRoleName(UserContextInfo.userRoleId(), this.props.t)}
                      </DropdownItem>
                    </div>
                    <DropdownItem divider tag="li" />
                    {routes.map((prop, index) => {
                      if (prop.redirect || !prop.path.startsWith("/settings/")) return null;
                      return (
                        <DropdownItem
                          key={index}
                          tag="li"
                          className="nav-item">
                          <RouterLink
                            className="nav-item dropdown-item"
                            to={prop.layout + prop.path}>
                            {t(prop.name)}
                          </RouterLink>
                        </DropdownItem>
                      );
                    })}
                    <DropdownItem divider tag="li" />
                    <NavLink tag="li">
                      <DropdownItem
                        className="nav-item"
                        onClick={() => { this.logOut() }}>{t("common.logOut")}</DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
export default AdminNavbar;
