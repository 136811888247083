import React from "react";
import * as BackEndConsts from "tools/constants/backEndConstants";
import UserContextInfo from "tools/userContextInfo";
import {
  platformNotifMessages,
  updateCompanyLS,
  handleInputChange,
  CustomField
} from "tools/commonFunctions";
import { dataService } from "tools/dataService/dataService"
import { trackPromise } from "react-promise-tracker";
import History from "tools/history";
import CompanyData from "components/Cards/CompanyData";
import GenericModal from "components/Modal/GenericModal";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Label,
  Row,
  Col,
  Table,
  Collapse
} from "reactstrap";

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.i18n.t;

    this.state = {
      isLoading: true,
      submitButtonDisable: true,
      cardIcon: "icon-laino-company-create",
      accordionOpened: false,
      deleteCompanyModal: false,
      isSuperUser: false
    }
  };

  componentDidMount() {
    this.setState({
      accountId: UserContextInfo.userId(),
      submitButtonDisable: true,
      companyDeleted: false
    })
    const userProfilePromise = dataService.queries.getUserProfile(UserContextInfo.userId());
    const suAccountDataPromise = dataService.queries.getSUAccount();


    trackPromise(
      Promise.all([userProfilePromise, suAccountDataPromise])
        .then(([accountcompanyData, datasuaccount]) => {
          if (accountcompanyData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            this.setState({
              accountId: accountcompanyData.account.id,
              accountFirstName: accountcompanyData.account.firstname,
              accountLastName: accountcompanyData.account.lastname,
              accountEmail: accountcompanyData.account.email
            });
          }

          if (datasuaccount !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            this.checkIfSuperUser();
          }

          this.setState({ isLoading: false });
        })
    )
  };

  checkIfSuperUser = async () => {
    const { isSU } = await UserContextInfo.isSuperUser();
    this.setState({ isSuperUser: isSU });
  };

  handleFormSubmit = (values) => {
    trackPromise(
      dataService.mutations.updateUserProfile(
        UserContextInfo.userId(),
        values.accountFirstName,
        values.accountLastName
      ).then(
        (updateaccountData) => {
          if (updateaccountData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            this.setState({
              accountFirstName: updateaccountData.account.firstname,
              accountLastName: updateaccountData.account.lastname,
              accountEmail: updateaccountData.account.email
            });
            platformNotifMessages(
              updateaccountData.response.messageinfo.messagelevel,
              updateaccountData.response.messageinfo.messagetitle,
              updateaccountData.response.messageinfo.message,
              values.accountEmail
            );
          } else return null;
        }
      )
    )
  };

  handleCompanyDelete = () => {
    trackPromise(
      dataService.mutations.deleteCompany(
        this.state.companyIdToDelete
      ).then(
        (backEndResponseData) => {
          if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {


            updateCompanyLS(false, backEndResponseData.company, backEndResponseData.company.id)

            platformNotifMessages(
              backEndResponseData.response.messageinfo.messagelevel,
              backEndResponseData.response.messageinfo.messagetitle,
              backEndResponseData.response.messageinfo.message,
              backEndResponseData.company.name
            );

          } else return null
        }
      ).then(
        () => {
          this.handleUpdateCompanyList(false);
        }
      )
    )
  };

  handleUpdateCompanyList(creating) {
    const isAccordionOpened = this.state.accordionOpened;

    if (creating === true) {
      this.setState({
        companyCreated: true
      });
      if (!isAccordionOpened) {
        this.toggleAccordionSection();
      }
    } else {
      this.setState({
        companyDeleted: true,
        deleteCompanyModal: false
      });
      if (isAccordionOpened) {
        this.toggleAccordionSection();
      }
    }
  };

  handleAccountCompanies() {
    let companyArray = UserContextInfo.accountCompanyData()

    return (
      companyArray && companyArray.map((company, key) => {
        if (companyArray === null) return null;
        return (
          <>
            <tr key={key}>
              <td> <i className="icon-laino-company-logo" /> </td>
              <td> {company.props.children[0]} </td>
              {this.state.isSuperUser &&
                <td>{company.props.children[1]} </td>
              }
              <td className={`${this.state.isSuperUser ? "" : "administration-column-set"}`}>
                {company.props.children[2]}
              </td>
              {this.state.isSuperUser &&
                <td className="administration-column-set">
                  <i className="icon-bin card-settings"
                    onClick={() => {
                      this.deleteCompanyRequest(company.props.children[0], company.key)
                    }}></i>
                </td>
              }
            </tr>
          </>
        )
      })
    )
  };

  deleteCompanyRequest = (companyName, companyId) => {
    const t = this.t;

    this.setState(prevState => ({
      companyIdToDelete: companyId,
      companyNameToDelete: companyName,
      modalTitleText: t("userProfile.deleteCompanyModal.title", { companyName: companyName }),
      modalBodyText: t("userProfile.deleteCompanyModal.modalBody", { companyName: companyName }),
      modalAcceptBtn: t("userProfile.deleteCompanyModal.deleteCompanyAcceptBtn", { companyName: companyName }),
      modalCancelBtn: t("kubernetesSettings.deleteModal.deleteCancelBtn"),
      deleteCompanyModal: !prevState.deleteCompanyModal
    }));
  };

  handleInputChangeLoc = (e) => {
    let inputResponse = handleInputChange(e)

    this.setState({
      [inputResponse.name]: inputResponse.value,
      submitButtonDisable: false
    })
  };

  toggleAccordionSection = () => {
    this.setState({
      accordionOpened: !this.state.accordionOpened
    })
  };

  toggleModal = (e) => {
    e.persist();

    let name = e.target.name;
    let state = e.target.value;

    this.setState({
      [name]: state === "true" ? false : true,
      deleteCompanyModal: !this.state.deleteCompanyModal
    });
  };

  getValidationSchema = () => {
    return Yup.object().shape({
      accountFirstName: Yup.string().required(this.t("signup.signupRegisterForm.formFirstName.requiredMessage")),
      accountLastName: Yup.string().required(this.t("signup.signupRegisterForm.formLastName.requiredMessage")),
      accountEmail: Yup.string().email(this.t("login.loginError.incorrectUsername")).required(this.t("login.loginError.requiredUsername"))
    });
  };

  render() {
    const t = this.t;

    if (this.state.isLoading === false) {
      return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                <section className="section-wrapper">
                  <h1 className="section-title">
                    <span className="context-root">{t("userProfile.title")}</span>|
                    <span className="context-info">{UserContextInfo.activeCompanyName()}</span>
                  </h1>
                </section>
              </Col>
            </Row>
            <Row >
              <Col md="12">
                <Card className="card-user">
                  <CardBody>
                    <div className="author">
                      <div className="block block-one" />
                      <Row>
                        <Col md="2">
                          <div className="avatar">
                            {UserContextInfo.userAvatar()}
                          </div>
                        </Col>
                        <Col md="10">
                          <h2 className="title">{UserContextInfo.userFullName()}</h2>
                          <p className="role">
                            {t("userProfile.role", {
                              roleName: UserContextInfo.userRoleName(UserContextInfo.userRoleId(), this.props.t),
                              companyName: UserContextInfo.activeCompanyName()
                            })}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      <i className="icon-laino-user" />
                      {t("userProfile.informationCard.title")}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Formik
                      initialValues={{
                        accountFirstName: this.state.accountFirstName || '',
                        accountLastName: this.state.accountLastName || '',
                        accountEmail: this.state.accountEmail || ''
                      }}
                      validationSchema={this.getValidationSchema()}
                      onSubmit={(values) => this.handleFormSubmit(values)}>
                      {(formikProps) => (
                        <Form>
                          <Row>
                            <Col md="6">
                              <Label htmlFor="accountfirstname">{t("signup.signupRegisterForm.formFirstName.label")}</Label>
                              <CustomField
                                type="text"
                                id="accountfirstname"
                                name="accountFirstName"
                                className="form-control"
                                placeholder={t("signup.signupRegisterForm.formFirstName.placeHolder")} />
                            </Col>
                            <Col className="pl-md-1" md="6">
                              <Label htmlFor="accountlastname">{t("signup.signupRegisterForm.formLastName.label")}</Label>
                              <CustomField
                                type="text"
                                id="accountlastname"
                                name="accountLastName"
                                className="form-control"
                                placeholder={t("signup.signupRegisterForm.formLastName.placeHolder")} />
                            </Col>
                          </Row>
                          <Row className="align-items-center">
                            <Col md="6">
                              <Label htmlFor="accountemail">{t("login.usernameLabel")}</Label>
                              <CustomField
                                type="email"
                                id="accountemail"
                                name="accountEmail"
                                className="form-control"
                                placeholder={t("login.usernamePlaceholder")}
                                disabled />
                            </Col>
                            <Col md="6">
                              <Button className="info-wrapper" onClick={() => { History.push('/resetpassword') }}>
                                {t("forgotPassword.resetPasswordButton")}
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button
                                disabled={this.state.submitButtonDisable && !formikProps.dirty}
                                className="align-right" type="submit">
                                {t("common.update")}
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row >
              <Col md="12">
                <Card>
                  <CardHeader className="accordion"
                    onClick={() => {
                      this.toggleAccordionSection()
                    }}>
                    <CardTitle tag="h4">
                      <i className="icon-laino-company-minilogo" />
                      {t("userProfile.companiesCard.title")}
                    </CardTitle>
                    <i className={`elem-settings-btn ${this.state.accordionOpened ? "icon-up-pike expanded" : "icon-down-pike"}`}></i>
                  </CardHeader>
                  <Collapse
                    isOpen={this.state.accordionOpened}>
                    <CardBody>
                      <Table id="Table" className="tablesorter" responsive>
                        <thead className="text-primary">
                          <tr className="theader">
                            <th className="row-logo">{t("userProfile.companiesCard.logo")}</th>
                            <th className="row-name">{t("kubernetesPlatform.tableCreation.name")}</th>
                            {this.state.isSuperUser &&
                              <th className="row-activation">{t("userProfile.companiesCard.activation")}</th>
                            }
                            <th className="row-role">{t("userProfile.companiesCard.roleInCompany")}</th>
                            {this.state.isSuperUser &&
                              <th className="row-delete">{t("common.delete")}</th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {this.handleAccountCompanies()}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
            </Row>
            <Row>
              {this.state.isSuperUser &&
                <CompanyData
                  i18n={this.props.i18n}
                  cardIcon={this.state.cardIcon}
                  cardTitle={t("userProfile.createCompany")}
                  handleUpdateCompanyList={(creating) => { this.handleUpdateCompanyList(creating) }} />
              }
            </Row>
          </div>
          {this.state.deleteCompanyModal ?
            <GenericModal
              name="deleteCompanyModal"
              toggleModal={(e) => { this.toggleModal(e) }}
              acceptOnClick={(e) => { this.handleCompanyDelete(e) }}
              modalTitleText={this.state.modalTitleText}
              modalBodyText={this.state.modalBodyText}
              modalAcceptBtn={this.state.modalAcceptBtn}
              modalCancelBtn={this.state.modalCancelBtn} /> :
            null
          }
        </>
      );
    } else return null;
  }
};
export default UserProfile