import React from "react";
import * as BackEndConsts from "tools/constants/backEndConstants";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

import {
  Row,
  Col
} from "reactstrap";

import i18next from 'i18next';

class MonitoringStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyServices: this.props.kubernetesPlatform.companyservices
    }
  };

  renderMonitoringInfo() {
    const t = i18next.t;
    const currentLanguage = i18next.language;

    if (!this.props.monitoringDeployCode || this.props.monitoringDeployCode === BackEndConsts.DEPLOYMENT_STATE_NEVERDEPLOYED) {
      return (
        <span className="app-status-label align-right">{this.props.monitoringDeployCodeInfo}</span>
      )
    } else return (
      <span className="app-status-label align-right">
        {t("common.deploymentLegend.part1")}
        <span className={ currentLanguage === "eus" ? "app-status-msg app-msg-email" : "app-status-msg app-msg-highlighted"}>
          {t("common.deploymentLegend.part1_param", currentLanguage === "eus"
            ? { DeployBy: this.props.monitoringDeployBy }
            : { DeployCodeInfo: this.props.monitoringDeployCodeInfo })}
        </span>
        {t("common.deploymentLegend.part2")}
        <span className="app-status-msg app-msg-highlighted">
          {t("common.deploymentLegend.part2_param", { DeployDate: this.props.monitoringDeployDate })}
        </span>
        {t("common.deploymentLegend.part3")}
        <span className="app-status-msg app-msg-highlighted">
          {t("common.deploymentLegend.part3_param", currentLanguage === "eus"
            ? { DeployCodeInfo: this.props.monitoringDeployCodeInfo }
            : { DeployDuration: this.props.monitoringDeployDuration })}
        </span>
        {t("common.deploymentLegend.part4")}
        <span className="app-status-msg app-msg-email">
          {t("common.deploymentLegend.part4_param", currentLanguage === "eus"
            ? { DeployDuration: this.props.monitoringDeployDuration }
            : { DeployBy: this.props.monitoringDeployBy })}
        </span>
        {currentLanguage === "eus"
        ? t("common.deploymentLegend.part5")
        : null
        }
      </span>
    );
  };

  renderMonitoringTableValues = () => {
    const t = i18next.t;

    let appNotifications = this.props.appNotifications;

    return (
      <table className="table-lainotik table-notifications">
        <tr>
          <th className="column-header column-name">{t("kubernetesSettings.platformStatusArea.monitoringStatus.tableNotifications.pod")}</th>
          <th className="column-header column-phase">{t("kubernetesSettings.platformStatusArea.monitoringStatus.tableNotifications.phase")}</th>
          <th className="column-header column-sche">{t("kubernetesSettings.platformStatusArea.monitoringStatus.tableNotifications.scheduled")}</th>
          <th className="column-header column-init">{t("kubernetesSettings.platformStatusArea.monitoringStatus.tableNotifications.initialized")}</th>
          <th className="column-header column-ready">{t("kubernetesSettings.platformStatusArea.monitoringStatus.tableNotifications.ready")}</th>
        </tr>
        {appNotifications.map((notif, key) => {
          return (
            <tr key={key}>
              <td className="table-value value-name">{notif.name}</td>
              <td className="table-value ">{notif.phase}</td>
              <td className="table-value ">{notif.podscheduled}</td>
              <td className="table-value">{notif.initialized}</td>
              <td className="table-value">{notif.ready}</td>
            </tr>
          )
        })}
      </table>
    )
  }

  render() {
    const t = i18next.t;
    let appNotifications = this.props.appNotifications;

    return (
      <>
        <Row className="subsection-child-header">
          <Col sm="12">
            <h5>{t("kubernetesPlatform.tableCreation.monitoring")}</h5>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <tr className="row-separator messaging-wrapper">
              <td colSpan="4">
                <table className="table-addons-info">
                  <th className="collapsed-row-wrapper">
                    {this.renderMonitoringInfo()}
                  </th>
                </table>
              </td>
            </tr>
            {appNotifications.length !== 0 &&
              this.renderMonitoringTableValues()
            }
          </Col>
        </Row>
      </>
    )
  };
};
export default MonitoringStatus;

MonitoringStatus.propTypes = {
  appNotifications: PropTypes.array
};