import React from "react";
import * as BackEndConsts from "tools/constants/backEndConstants";
import UserContextInfo from "tools/userContextInfo";
import {
  logout,
  updateActiveCompanyLS,
  platformNotifMessages,
  handleInputChange
} from "tools/commonFunctions";
import { dataService } from "tools/dataService/dataService"
import { trackPromise } from "react-promise-tracker";
import History from "tools/history";

import {
  Row,
  Col
} from "reactstrap";

import NewPlatform from './subSections/NewPlatform';
import TableCreation from './subSections/TableCreation';
class PlatformSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRolePlatCreation: UserContextInfo.userRolePlatformCreation(),
      companyActivationStatus: UserContextInfo.activeCompanyActivation(),
      kubernetesPlatform: [],
      kubernetesName: "",
      serviceImage: this.props.serviceImage,
      serviceName: this.props.serviceName,
      kubernetesStatus: false,
      kubernetesClusterAddress: "",
      kubernetesInfraProvider: "",
      kubernetesPlanId: 1,
      buttonDisable: false,
    }

    this.enableCreateButton = this.enableCreateButton.bind(this);
  };

  async componentDidMount() {
    if (!localStorage.getItem('ActiveCompanyData')) {
      console.log('No Company data found, logout')
      logout()
    } else {
      this.setState({
        companyId: UserContextInfo.activeCompanyId(),
        companyName: UserContextInfo.activeCompanyName()
      })
      let companyId = UserContextInfo.activeCompanyId();
      trackPromise(
        dataService.queries.getKubernetesData(companyId).then(
          (datakubernetes) => {
            this.setState({
              userRolePlatCreation: UserContextInfo.userRolePlatformCreation(),
              companyActivationStatus: UserContextInfo.activeCompanyActivation(),
              kubernetesPlatform: datakubernetes
            })
          }
        )
      )
      console.log('Using data from localStorage - [CompanyId]')
    }

    if (!localStorage.getItem('KubernetesPlatform')) {
      console.log('No Platforms created')
    } else {
      let LSKubernetesPlatform = JSON.parse(localStorage.getItem('KubernetesPlatform'));
      let activePlatformDataObj = JSON.parse(localStorage.getItem('ActivePlatformData'));
      this.setState({
        kubernetesPlatform: LSKubernetesPlatform,
        activePlatformId: activePlatformDataObj.PlatformId
      })
    }
  }

  // Call to BackEnd to create an empty Kubernetes object so the creation process can be rendered correctly
  handleEmptyK8sCreation(companyId) {
    trackPromise(
      dataService.queries.createEmptyKubernetesPlatform(companyId).then(
        (newkuberesponse) => {
          // Crear una nueva plataforma Kubernetes con la respuesta del servidor
          const newKubernetesPlatform = {
            isEmpty: true,
            clusteraddress: newkuberesponse.clusteraddress,
            companyservices: [],
            creationdate: newkuberesponse.creationdate,
            id: newkuberesponse.id,
            demo: false,
            lainotikmonitoring: true,
            infraprovider: {
              id: newkuberesponse.infraproviderid,
              name: newkuberesponse.infraprovidername
            },
            externaliprangestart: "",
            externaliprangeend: "",
            k8certs: [],
            name: newkuberesponse.name,
            plan: {
              id: newkuberesponse.planid,
              name: newkuberesponse.planname
            },
            deploymentstateinfo: {
              codeinfo: newkuberesponse.deploymentstateinfo.codeinfo,
              codeinfolevel: newkuberesponse.deploymentstateinfo.codeinfolevel,
              message: newkuberesponse.deploymentstateinfo.message,
              messagelevel: newkuberesponse.deploymentstateinfo.messagelevel,
              messagetitle: newkuberesponse.deploymentstateinfo.messagetitle
            },
            showiprange: newkuberesponse.showiprange
          };

          // Añadir la nueva plataforma al inicio del array existente de plataformas
          let updatedKubernetesPlatform = [newKubernetesPlatform, ...this.state.kubernetesPlatform];

          // Actualizar el estado con la nueva lista de plataformas
          this.setState({
            kubernetesPlatform: updatedKubernetesPlatform,
            buttonDisable: true
          });
        }
      )
    );
  }

  handleK8sCreation = (e) => {
    e.preventDefault();
    this.handleEmptyK8sCreation(this.state.companyId)
  };

  handleRequestActivation = () => {
    trackPromise(
      dataService.queries.getCompanyData(
        this.state.companyId
      ).then(
        (companyresponse) => {
          if (companyresponse.address !== null
            && companyresponse.zipcode !== null
            && companyresponse.city !== null
            && companyresponse.country.id !== null) {
            trackPromise(
              dataService.mutations.requestAccountActivation(this.state.companyId).then(
                (backEndResponseData) => {
                  if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
                    this.setState({
                      companyActivationStatus: backEndResponseData.company.activation,
                      userRolePlatCreation: backEndResponseData.company.security.k8create
                    })
                    updateActiveCompanyLS(backEndResponseData.company)
                    platformNotifMessages(
                      backEndResponseData.response.messageinfo.messagelevel,
                      backEndResponseData.response.messageinfo.messagetitle,
                      backEndResponseData.response.messageinfo.message,
                      this.state.companyName
                    );
                    //To check it like the rest of the forced refreshs
                    setTimeout(() => {
                      window.location.reload(true);
                    }, 3000);
                  } else return null;
                }
              )
            )
          } else History.push('/portal/settings/company');
        }
      )
    )
  }

  handleInputChangeLoc = (e) => {
    let inputResponse = handleInputChange(e)

    this.setState({
      [inputResponse.name]: inputResponse.value
    })
  };

  handleCheckboxChange = () => {
    this.setState(NewStatusCheck => ({
      kubernetesStatus: !NewStatusCheck.kubernetesStatus
    }));
  }

  enableCreateButton() {
    this.setState({
      buttonDisable: false
    })
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <section className="section-wrapper">
                <h1 className="section-title">
                  <i className={this.state.serviceImage} />
                  <span className="context-root">{this.state.serviceName}</span>|
                  <span className="context-info">{this.state.companyName}</span>
                </h1>
              </section>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <NewPlatform
                t={this.props.i18n.t}
                handleK8sCreation={this.handleK8sCreation}
                handleRequestActivation={this.handleRequestActivation}
                serviceName={this.props.serviceName}
                companyId={this.state.companyId}
                buttonDisable={this.state.buttonDisable} />
              <TableCreation
                t={this.props.i18n.t}
                handlePlatformServices={this.props.handlePlatformServices}
                checked={this.state.status}
                serviceName={this.props.serviceName}
                kubernetesPlatform={this.state.kubernetesPlatform}
                companyId={this.state.companyId}
                kubernetesClusterAddress={this.state.kubernetesClusterAddress}
                kubernetesInfraProvider={this.state.kubernetesInfraProvider}
                kubernetesPlanId={this.state.kubernetesPlanId}
                enableCreateButton={this.enableCreateButton}
                bgColor={this.props.bgColor}
                activePlatformId={this.state.activePlatformId} />
            </Col>
          </Row>
        </div>
      </>
    )
  }
}
export default PlatformSection;
