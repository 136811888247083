/*eslint-disable*/
import React from "react";

import { withTranslation } from "react-i18next";

// reactstrap components
import { Container, Row, Nav, NavItem, NavLink } from "reactstrap";

class FullFooter extends React.Component {

  getLocalizedUrl = () => {
    const language = this.props.i18n.language;
    let url = "https://lainotik.com";

    if (language === "es") {
      url += "/inicio/";
    } else if (language === "eus") {
      url += "/hasiera/";
    }

    return url;
  };

  render() {
    const { t } = this.props;
    const formattedDate = new Date().getFullYear();
    const localizedUrl = this.getLocalizedUrl();

    return (
      <footer className="footer">
        <Container fluid>
          <Row className="original-footer">
            <Nav>
              <NavItem>
                <NavLink target="_blank" href="https://lainotik.com/documentation/services/">{t("common.documentation")}</NavLink>
              </NavItem>
            </Nav>
            <div className="copyright">
              © {formattedDate}{" "}
              <a href={localizedUrl} target="_blank" rel="noopener noreferrer">
                Datagara
              </a>
            </div>
          </Row>
          <Row className="extended-footer">
          </Row>
        </Container>
      </footer>
    );
  }
}
export default withTranslation()(FullFooter);