// Routes configuration
// NOTE: The "name" property in the route objects is a translation key JSON.
// The actual translation takes place in the AdminNavBar component.
import PlatformSection from "sections/platforms/PlatformSection.js";

import UserProfile from "sections/account/UserProfile.js";
import Company from "sections/company/Company.js";

export var routes = [
  // Elements with the layout prop value "/portal" will be loaded in the application sidebar
  {
    path: "/kubernetes",
    name: "Kubernetes",
    class: "",
    icon: "icon-laino-kubernetes",
    component: PlatformSection,
    layout: "/portal"
  },
  // Elements with the path prop value "/settings" will be loaded in the application Account's dropdown
  {
    path: "/settings/profile",
    name: "adminNavBar.profile",
    class: "",
    component: UserProfile,
    layout: "/portal"
  },
  {
    path: "/settings/company",
    name: "signup.signupRegisterForm.formCompany.label",
    class: "",
    icon: "icon-stats",
    component: Company,
    layout: "/portal"
  },  
];
