import React from "react";
import UserContextInfo from "tools/userContextInfo";

import {
  Row,
  Col,
  Form,
  FormGroup,
  Button
} from "reactstrap";

//It allows defining text strings inside it and adding placeholders to replace dynamic values.
import { Trans } from 'react-i18next';

class NewPlatform extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      status: false,
      userRolePlatCreation: UserContextInfo.userRolePlatformCreation(),
      companyActivationStatus: UserContextInfo.activeCompanyActivation()
    };
  };

  renderActivationRequestBanner() {
    const t = this.t;

    return (
      <section className="section-wrapper centered-section highlighted-section warning">
        <Row>
          <Col md="9" className="block-vert-align-middle">
            <i className="icon-alert" />
            <span className="highlighted-text">
              <Trans
                i18nKey="kubernetesPlatform.newPlatform.accountNotActivated"
                values={{ companyName: UserContextInfo.activeCompanyName() }}
              />
              {' '}
              <Trans
                i18nKey="kubernetesPlatform.newPlatform.requestActivationThrough"
              />
            </span>
          </Col>
          <Col md="3">
            <Form onSubmit={(e) => {
              e.preventDefault();
              this.props.handleRequestActivation();
            }}>
              <FormGroup>
                <Col md="auto" className="elem-submit-btn">
                  <Button
                    disabled={this.props.buttonDisable}
                    type="submit"
                    value="Submit">
                    {t("kubernetesPlatform.newPlatform.requestActivation")}
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </section>
    );
  }

  renderActivationRequestedBanner() {
    const t = this.t;

    return (
      <section className="section-wrapper centered-section highlighted-section info">
        <Row>
          <Col md="12" className="block-vert-align-middle">
            <i className="icon-info" />
            <span className="highlighted-text">
              {t("kubernetesPlatform.newPlatform.activationRequested", { companyName: UserContextInfo.activeCompanyName() })}
              {t("kubernetesPlatform.newPlatform.requestValidating")}
            </span>
          </Col>
        </Row>
      </section>
    );
  }

  renderNewButton() {
    const t = this.t;

    return (
      <section className="section-wrapper centered-section highlighted-section">
        <Row>
          <Col md="auto">
            <Form onSubmit={this.props.handleK8sCreation}>
              <FormGroup>
                <Col md="auto" className="elem-submit-btn">
                  <Button
                    disabled={this.props.buttonDisable}
                    type="submit"
                    value="Submit">
                    {t("common.new")}
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </Col>
          <Col md="auto" className="block-vert-align-middle">
            <span className="highlighted-text">
              <Trans
                i18nKey="kubernetesPlatform.newPlatform.connectSetup"
                values={{ serviceName: this.props.serviceName }}
              />
            </span>
          </Col>
        </Row>
      </section>
    );
  }

  handlePlatformCreationButton() {
    const { userRolePlatCreation, companyActivationStatus } = this.state;

    switch (true) {
      case !userRolePlatCreation:
        if (companyActivationStatus === "REQUESTED") {
          return (
            <section className="section-wrapper centered-section highlighted-section warning">
              <Row>
                <Col md="12" className="block-vert-align-middle">
                  <i className="icon-alert" />
                  <span className="highlighted-text">
                    <Trans
                      i18nKey="kubernetesPlatform.newPlatform.accountNotActivated"
                      values={{ companyName: UserContextInfo.activeCompanyName() }}
                    />
                    {' '}
                    <Trans
                      i18nKey="kubernetesPlatform.newPlatform.contactAdministrator"
                    />
                  </span>
                </Col>
              </Row>
            </section>
          );
        }
        break;
      case companyActivationStatus === "NONE":
        return this.renderActivationRequestBanner();
      case companyActivationStatus === "REQUESTED":
        return this.renderActivationRequestedBanner();
      case companyActivationStatus === "ACTIVATED":
        return this.renderNewButton();
      default:
        break;
    }
  };

  render() {
    return (
      <>
        {this.handlePlatformCreationButton()}
      </>
    );
  }
}
export default NewPlatform;
