import React from 'react';
import { dataService } from "tools/dataService/dataService"

class UserContextInfo {

  static getUserData(key) {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  static Role = Object.freeze({
    "1": "Administrator",
    "2": "Company Owner",
    "3": "User",
    "4": "Platform Admin",
    "5": "Data User",
  });

  userAvatar() {
    const userFullName = this.userFullName();

    //sanitize to remove accents
    const userFullNameSat = userFullName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    const userAvatar = userFullNameSat.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
    return userAvatar;
  };

  userId() {
    const accountInfo = UserContextInfo.getUserData('AccountInfo');
    return accountInfo?.account?.id ?? "No Id for the account";
  }

  userEmail() {
    const accountInfo = UserContextInfo.getUserData('AccountInfo');
    return accountInfo?.account?.email ?? "No email registered";
  }

  userFullName() {
    const accountInfo = UserContextInfo.getUserData('AccountInfo');
    const userFirstName = accountInfo?.account?.firstname ?? "Unknown";
    const userLastName = accountInfo?.account?.lastname ?? "Unknown";
    const userFullName = userFirstName + " " + userLastName;
    return userFullName;
  }

  userRoleName(userRole) {
    return UserContextInfo.Role[userRole] || '';
  }

  userRoleId() {
    const activeCompanyInfo = UserContextInfo.getUserData('ActiveCompanyData');
    return activeCompanyInfo?.CompanySecurity?.RoleId;
  }

  userRoleCompanyCreation() {
    const activeCompanyInfo = UserContextInfo.getUserData('ActiveCompanyData');
    return activeCompanyInfo?.CompanySecurity?.CompanyCreate;
  }

  userRoleAccountCreation() {
    const activeCompanyInfo = UserContextInfo.getUserData('ActiveCompanyData');
    return activeCompanyInfo?.CompanySecurity?.AccountCreate;
  }

  userRolePlatformCreation() {
    const activeCompanyInfo = UserContextInfo.getUserData('ActiveCompanyData');
    return activeCompanyInfo?.CompanySecurity?.PlatformCreate;
  }

  async isSuperUser() {
    try {
      const suAccountData = await dataService.queries.getSUAccount();
      const suAccountEmail = suAccountData?.su_account;
      const currentUserEmail = this.userEmail();

      return {
        isSU: suAccountEmail === currentUserEmail,
        suEmail: suAccountEmail
      };
    } catch (error) {
      console.error('Error checking SU Account:', error);
      return { isSU: false, suEmail: null };
    }
  }

  accountCompanyData() {
    const accountInfo = UserContextInfo.getUserData('AccountInfo');
    const companyName = accountInfo?.companies?.map((company) =>
      <React.Fragment key={company.id}>
        {company.name}
        {company.activation}
        {this.userRoleName(company.security.roleid)}
      </React.Fragment>
    ) || "No Company!";
    return companyName;
  }

  activePlatformId() {
    const activePlatform = UserContextInfo.getUserData('ActivePlatformData');
    return activePlatform?.PlatformId ?? "No Platform ID!";
  }

  activePlatformName() {
    const activePlatform = UserContextInfo.getUserData('ActivePlatformData');
    return activePlatform?.PlatformName ?? "No Platform Name!";
  }

  activeCompanyId() {
    const activeCompany = UserContextInfo.getUserData('ActiveCompanyData');
    return activeCompany?.CompanyId ?? "No Company ID!";
  }

  activeCompanyName() {
    const activeCompany = UserContextInfo.getUserData('ActiveCompanyData');
    return activeCompany?.CompanyName ?? "No Company Name!";
  }

  activeCompanyActivation() {
    const activeCompany = UserContextInfo.getUserData('ActiveCompanyData');
    return activeCompany?.CompanyActivationStatus ?? "No Active Company!";
  }

}
export default new UserContextInfo();