//Alternative notification library
import { Store } from "react-notifications-component";

//Vanilla styles - TODO CUSTOMIZE THEM
import "react-notifications-component/dist/theme.css";

//Animating library
import "animate.css/animate.compat.css"

class GenericNotification {
  notify (notifTitle, notifMessage, notifType) {
    var options = {};
    options = {
      title: notifTitle,
      message: notifMessage,
      type: notifType, //"info" , "warning" , "success" , "danger"
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
        pauseOnHover: true,
        showIcon: true
      }
    };
    return (
      Store.addNotification(options)
    )
  }

}
export default new GenericNotification();