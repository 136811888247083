/*eslint-disable*/
import React from "react";
import { NavLink } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// javascript plugin used to create scrollbars on windows
//import PerfectScrollbar from "perfect-scrollbar";

import * as BackEndConsts from "tools/constants/backEndConstants";
import { updateActiveCompanyLS } from "tools/commonFunctions";
import { dataService } from "tools/dataService/dataService"
import { trackPromise } from "react-promise-tracker";
import UserContextInfo from "tools/userContextInfo";

// reactstrap components
import {
  Nav,
  NavLink as ReactstrapNavLink,
  UncontrolledCollapse
} from "reactstrap";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.i18n.t;
    this.state = {
      kubernetesPlatform: this.props.kubernetesPlatform,
      KubePlatSelected: false,
      showScrollIconUp: false,
      showScrollIconDown: false,
      animateIcon: false
    };
    this.serviceOptionListRef = React.createRef()
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    if (localStorage.getItem('ActiveCompanyData')) {
      let LSActiveCompanyDataObj = JSON.parse(localStorage.getItem('ActiveCompanyData'));
      this.setState({
        ActiveCompanyName: LSActiveCompanyDataObj.CompanyName,
        ActiveCompanyId: LSActiveCompanyDataObj.CompanyId,
        ActiveCompanyActivationStatus: LSActiveCompanyDataObj.CompanyActivationStatus,
      })
    } else console.log("No active company")
    window.addEventListener('resize', this.checkScroll);
    this.checkScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkScroll);
  }

  toggleSelector = () => {
    this.setState({
      KubePlatSelected: !this.state.KubePlatSelected
    })
  }
  toggleCompanySelector = () => {
    this.setState({
      CompanyPlatSelected: !this.state.CompanyPlatSelected
    })
  }

  checkScroll = () => {
    const element = this.serviceOptionListRef.current;
    if (element) {
      const hasVerticalScrollbar = element.scrollHeight > element.clientHeight;
      const isScrolledToBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
      const isScrolledToTop = element.scrollTop === 0;

      this.setState({
        showScrollIconDown: hasVerticalScrollbar && !isScrolledToBottom,
        showScrollIconUp: hasVerticalScrollbar && !isScrolledToTop,
        animateIcon: hasVerticalScrollbar && !isScrolledToBottom
      });

      if (hasVerticalScrollbar && !isScrolledToBottom) {
        setTimeout(() => this.setState({ animateIcon: false }), 3000);
      }
    }
  }

  handleScroll = () => {
    // Call checkScroll when scrolling
    this.checkScroll();
  }

  scrollToTop = () => {
    const element = this.serviceOptionListRef.current;
    if (element) {
      element.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  scrollToBottom = () => {
    const element = this.serviceOptionListRef.current;
    if (element) {
      element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
    }
  }

  handleCompanies = () => {
    let companyArray = UserContextInfo.accountCompanyData()
    if (Array.isArray(companyArray) && companyArray.length > 1) {
      return (
        <>
          <section
            id="CompanyAccordion"
            className="company-accordion"
            onClick={() => {
              this.toggleCompanySelector()
            }}>
            <span>{this.state.ActiveCompanyName}</span>
            <i className={this.state.CompanyPlatSelected ? "icon-up-pike" : "icon-down-pike"}></i>
          </section>
          <UncontrolledCollapse
            toggler="CompanyAccordion">
            {companyArray && companyArray.map((company, key) => {
              if (companyArray === null) return null;
              return (
                <span
                  key={key}
                  id={key}
                  className="company-list-elem"
                  onClick={() => { this.handleCompanySet(company.props.children[0], company.key) }}
                  value={company.props.children[0]}>
                  {company.props.children[0]}
                </span>
              )
            })}
          </UncontrolledCollapse>
        </>
      )
    } else {
      return (
        companyArray[0].props.children[0]
      )
    }
  }

  handleCompanySet(companyName, companyId) {
    trackPromise(
      dataService.mutations.switchCompany(companyId).then(
        (backEndResponseData) => {
          if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            this.setState({
              ActiveCompanyName: companyName,
              ActiveCompanyId: companyId
            })
            updateActiveCompanyLS(backEndResponseData.company)
            this.props.handleActiveCompany(companyId);
          } else return null
        }
      )
    )
  }

  linkOnClick = () => {
    document.documentElement.classList.remove("nav-open");
  };

  render() {
    const { bgColor, routes, rtlActive, kubernetesPlatform, kubernetesPlatformId, kubernetesPlatformName, fallbackPlatformName, handlePlatformServices, toggleSidebar } = this.props;
    const { KubePlatSelected } = this.state;
    const t = this.t;
    const { showScrollIconUp, showScrollIconDown, animateIcon } = this.state;

    const renderKubePlatforms = () => {
      return (
        <ul className="kube-platform-list">
          {kubernetesPlatform.map((platform, key) => (
            <li key={key} className="plat-option" onClick={(event) => {
              event.preventDefault();
              handlePlatformServices(
                platform.id,
                platform.name,
                platform.deploymentstateinfo.codeinfolevel,
                platform.deploymentstateinfo.codeinfo,
                platform.clsaddmon,
                platform.connectionstate
              );
            }}>
              <span className="option-left">{platform.name}</span>
              <span className="option-right">{platform.deploymentstateinfo.codeinfo}</span>
            </li>
          ))}
        </ul>
      );
    };

    const isActiveRoute = (path) => this.activeRoute(path);

    const nonKubernetesRoutes = routes.filter(prop => !(prop.name === "Kubernetes" || prop.redirect || prop.layout !== "/portal" || prop.path.startsWith("/settings/") || (prop.name !== 'Kubernetes' && kubernetesPlatformId === "")));
    const kubernetesRoute = routes.find(prop => prop.name === "Kubernetes");

    return (
      <div className="sidebar" data={bgColor}>
        <div className="sidebar-wrapper" ref="sidebar">
          <div className="logo">
            {this.handleCompanies()}
          </div>
          <Nav tag="ul">
            {kubernetesRoute && (
              <React.Fragment>
                <li className={isActiveRoute(kubernetesRoute.path) + (kubernetesRoute.pro ? " active-pro" : "") + (kubernetesRoute.class || "")}>
                  <NavLink to={kubernetesRoute.layout + kubernetesRoute.path} className="nav-link menu-elem-plat" activeClassName="active" onClick={toggleSidebar}>
                    <i className={kubernetesRoute.icon} />
                    <p>{rtlActive ? kubernetesRoute.rtlName : kubernetesRoute.name}</p>
                    <div id="plat-accordion" className="plat-selector-wrapper" onClick={() => this.toggleSelector()}>
                      <p className={kubernetesPlatformName ? "" : "plat-selector-placeholder"}>
                        {kubernetesPlatformName || fallbackPlatformName}
                      </p>
                      <i className={KubePlatSelected ? "icon-up-pike" : "icon-down-pike"}></i>
                    </div>
                  </NavLink>
                  <UncontrolledCollapse className="plat-option-list" id="PlatOptionList" toggler="#plat-accordion" defaultOpen={KubePlatSelected}>
                    {renderKubePlatforms()}
                  </UncontrolledCollapse>
                </li>
              </React.Fragment>
            )}
            <ul
              ref={this.serviceOptionListRef}
              onScroll={this.handleScroll}
              className="service-option-list" >
              {showScrollIconUp && (
                <i className={`scroll-highlight icon-up-arrow ${animateIcon ? 'bounce-animation' : ''}`} onClick={this.scrollToTop} />
              )}
              {nonKubernetesRoutes.map((prop, key) => (
                <li key={key} className={isActiveRoute(prop.path) + (prop.pro ? " active-pro" : "") + (prop.class || "")}>
                  <NavLink to={prop.layout + prop.path} className="nav-link platform-service" activeClassName="active" onClick={toggleSidebar}>
                    <i className={prop.icon} />
                    <p>{rtlActive ? prop.rtlName : prop.name}</p>
                  </NavLink>
                </li>
              ))}
              {showScrollIconDown && (
                <i className={`scroll-highlight icon-down-arrow ${animateIcon ? 'bounce-animation' : ''}`} onClick={this.scrollToBottom} />
              )}
            </ul>
            <li style={{ display: "none" }} className="active-pro">
              <ReactstrapNavLink href="#">
                <i className="icon-laino-upgrade" />
                <p>{t("common.upgradePlan")}</p>
              </ReactstrapNavLink>
            </li>
          </Nav>
        </div>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  rtlActive: false,
  bgColor: "primary",
  routes: [{}]
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, will be rendered
  // inside the links of this component
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(["primary", "second", "third"]),
  routes: PropTypes.arrayOf(PropTypes.object),
  companyLogo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string
  })
};

export default Sidebar;