import GenericNotification from "components/Notifications/GenericNotification";
import Cookies from "universal-cookie";
import i18n from "localization/i18n";
import { Field, ErrorMessage, getIn } from 'formik';
import { LainoSwitch } from "components/Switch/Switch";

const cookies = new Cookies();

export function handleInputChange(e) {
  e.persist();

  const input = e.target;
  const name = e.target.name;
  const id = input.id;
  let value;
  switch (input.type) {
    case "radio":
      Number.isInteger(input.value)
        ? (value = parseInt(input.value))
        : (value = input.value);
      break;
    case "checkbox":
      value = e.target.checked;
      break;
    default:
      value = input.value;
  }

  let inputResponse = { name, value, id };

  return inputResponse;
};

export function handleChangeDropdown(event) {
  const target = event.target;
  const name = target.name;
  const value = target.value;
  const id = target.id;
  const selectedIndex = target.selectedIndex;
  const childId = target.options[selectedIndex].getAttribute('id');
  const childName = target.options[selectedIndex].getAttribute('value');

  // Ensure that the id is an integer if it contains only digits
  const parsedId = /^\d+$/.test(id) ? parseInt(id, 10) : id;

  let dropdownResponse = { name, value, id: parsedId, childId, childName };

  return dropdownResponse;
};

export function platformStateTag(platState, tagClass) {
  switch (tagClass) {
    case "INFO":
      tagClass = "info";
      break;
    case "WARNING":
      tagClass = "warning";
      break;
    case "SUCCESS":
      tagClass = "success";
      break;
    case "ERROR":
      tagClass = "error";
      break;
    default:
      tagClass = "warning";
      break;
  }
  if (platState) {
    return (
      <>
        <span className={`context-state ${tagClass}`}>{platState}</span>
      </>
    )
  } else return null
};

export function platformNotifMessages(
  notifLevel,
  notifTitle,
  notifInfo,
  notifSubject) {
  let notifType;
  let notifTitleResult;
  let notifInfoResult;

  if (notifSubject !== undefined) {
    //notifTitleResult = notifSubject + " - " + notifTitle;
    notifInfoResult = notifSubject + " - " + notifInfo;
  } else {
    notifTitleResult = notifTitle;
    notifInfoResult = notifInfo;
  }

  switch (notifLevel) {
    case "INFO":
      notifType = "default";
      break;
    case "SUCCESS":
      notifType = "success";
      break;
    case "WARNING":
      notifType = "warning";
      break;
    case "DANGER":
      notifType = "danger";
      break;
    default:
      break;
  }
  if (notifLevel !== undefined && notifLevel) {
    return (
      GenericNotification.notify(notifTitleResult, notifInfoResult, notifType)
    )
  } else return null
};

export function logout() {
  //Clear SPECIFIC keys from the localStorage
  let keysToRemove = [
    "ActiveCompanyData",
    "AccountInfo",
    "KubernetesPlatform",
    "ATk",
    "ActivePlatformData",
    "ActivePlatformServices"
  ]

  keysToRemove.forEach(k =>
    localStorage.removeItem(k)
  )

  //Clear ALL the localStorage keys
  //localStorage.clear()

  //Clear the cookie used for proxy login
  cookies.remove('authLainotik', { path: '/', domain: ".lainotik.io" });
  window.location = `/`;
};

export function updateCompanyLS(
  Creating,
  NewCompany,
  CompanyId) {
  // Get the values directly from localStorage and verify its content
  const accountInfoData = localStorage.getItem('AccountInfo');
  const accountCompanyInfo = accountInfoData ? JSON.parse(accountInfoData) : null;

  // Check if accountCompanyInfo has as valid value
  if (!accountCompanyInfo || !accountCompanyInfo.companies) {
    return;
  }

  if (Creating === true) {

    accountCompanyInfo.companies.push({
      activation: NewCompany.activation,
      id: NewCompany.id,
      name: NewCompany.name,
      security: {
        accountcreate: NewCompany.security.accountcreate,
        companycreate: NewCompany.security.companycreate,
        k8create: NewCompany.security.k8create,
        rolename: NewCompany.security.rolename,
        roleid: NewCompany.security.roleid
      }
    });
  } else {
    let companies = accountCompanyInfo.companies;

    function deleteCompany(companyToDelete) {
      return companyToDelete.id !== CompanyId;
    }
    let filteredCompanies = companies.filter(deleteCompany);

    accountCompanyInfo.companies = filteredCompanies;
  }

  localStorage.setItem('AccountInfo', JSON.stringify(accountCompanyInfo));
};

export function updateActiveCompanyLS(Company) {

  let activeCompanyDataObj = {
    CompanyId: Company.id,
    CompanyName: Company.name,
    CompanyActivationStatus: Company.activation,
    CompanySecurity: {
      RoleId: Company.security.roleid,
      RoleName: Company.security.rolename,
      CompanyCreate: Company.security.companycreate,
      PlatformCreate: Company.security.k8create,
      AccountCreate: Company.security.accountcreate,
    }
  }

  localStorage.setItem('ActiveCompanyData', JSON.stringify(activeCompanyDataObj));
  return true;
};

export function updateActivePlatformLS(
  platformId,
  platformName,
  platformStateLevel,
  platformState,
  platformAccessStatus,
  platformConnectStatus,
  platformHealthCheckMessage
) {
  let activePlatformData = {
    PlatformId: platformId,
    PlatformName: platformName,
    PlatformStatusLevel: platformStateLevel,
    PlatformStatus: platformState,
    PlatformAccessStatus: platformAccessStatus,
    PlatformConnectStatus: platformConnectStatus,
    PlatformHealthCheckClass: (platformAccessStatus === "ACCESIBLE" &&
      platformConnectStatus === "CONNECTED") ? "running" : "notrunning",
    PlatformHealthCheckMessage: platformHealthCheckMessage
  }
  localStorage.setItem('ActivePlatformData', JSON.stringify(activePlatformData));
};

export function handleDate(IsoDate) {
  if (IsoDate !== null) {
    const t = i18n.t;
    const today = new Date();
    const date = new Date(IsoDate);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; //Because with this method the month starts from 0 - January
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    const dateFormatted = `${day}/${String(month).padStart(2, '0')}/${year} ${hours}:${minutes}:${seconds}`;
    const msInOneDay = 1000 * 60 * 60 * 24;
    const periodDiff = Math.round((date - today) / msInOneDay);

    const statusMessages = {
      success: t("kubernetesSettings.accessSetup.authenticationSettings.certificateExpDate.theCertificateOK", { days: periodDiff }),
      warning: t("kubernetesSettings.accessSetup.authenticationSettings.certificateExpDate.warningCertExpire", { days: periodDiff }),
      danger: t("kubernetesSettings.accessSetup.authenticationSettings.certificateExpDate.errorCertExpired", { days: periodDiff })
    };

    let expirationStatus, expirationMessage;
    switch (true) {
      case periodDiff > 30:
        expirationStatus = "success";
        break;
      case periodDiff <= 30:
        expirationStatus = "warning";
        break;
      case periodDiff <= 0:
        expirationStatus = "danger";
        break;
    }
    expirationMessage = statusMessages[expirationStatus];

    return {
      dateFormatted,
      hasExpired: periodDiff <= 0,
      periodDiff,
      expirationStatus,
      expirationMessage
    };
  } else {
    return null;
  }
};

export function handleCopyToClipboard(elementId, textToCopy = '') {
  let text = textToCopy;

  if (!text) {
    let copyElement = document.getElementById(elementId);
    if (copyElement) {
      copyElement.select();
      text = copyElement.value;
    }
  }

  if (text) {
    navigator.clipboard.writeText(text).then(function () {
      // Success notification
      GenericNotification.notify("Texto copiado", "El texto ha sido copiado al portapapeles.", "success");
    }, function () {
      // Error notification
      GenericNotification.notify("Error", "No se pudo copiar el texto al portapapeles.", "danger");
    });
  }
}

export function CustomField({
  onChange: customOnChange,
  shape, // Prop to decide the type of component to render (switch)
  showError = true,
  ...props
}) {
  return (
    <Field name={props.name}>
      {({ field, form }) => {
        const { touched, errors, setFieldValue } = form;
        const isError = getIn(errors, props.name) && getIn(touched, props.name);

        const combinedOnChange = (event) => {
          form.handleChange(event);
          if (customOnChange) {
            customOnChange(event, setFieldValue);
          }
        };

        if (shape === "switch") {
          return (
            <LainoSwitch
              {...props}
              form={form}
              field={field}
              isError={isError}
              onChange={combinedOnChange}
            />
          );
        } else if (shape === "radio") {
          const uniqueId = `${props.name}-${props.value}`;
          return (
            <>
              <input
                {...field}
                {...props}
                id={uniqueId}
                type="radio"
                onChange={(e) => {
                  setFieldValue(props.name, e.target.value);
                  if (customOnChange) {
                    customOnChange(e, setFieldValue);
                  }
                }}
                checked={field.value.toString() === props.value}
                className={`${props.className} ${isError ? 'is-invalid' : ''}`} />
              <label htmlFor={uniqueId} className="custom-control-label">
                {props.label}
              </label>
            </>
          );
        } else {
          // Render textarea for 'inputType' "textarea", otherwise input
          return props.type === "textarea" ? (
            <>
              <textarea
                {...field}
                {...props}
                onChange={combinedOnChange}
                className={`${props.className} ${isError ? 'is-invalid' : ''}`} />
              {showError && <ErrorMessage name={props.name} component="div" className="invalid-feedback" />}
            </>
          ) : (
            <>
              <Field
                {...field}
                {...props}
                onChange={combinedOnChange}
                className={`${props.className} ${isError ? 'is-invalid' : ''}`} />
              {showError && <ErrorMessage name={props.name} component="div" className="invalid-feedback" />}
            </>
          );
        }
      }}
    </Field>
  );
}