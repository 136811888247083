import React from "react";
import * as BackEndConsts from "tools/constants/backEndConstants";
import { handleDate } from "tools/commonFunctions";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

import {
  Col,
  Row
} from "reactstrap";

class AddonStatus extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
  };

  renderAddonInfo() {
    const t = this.t;

    if (this.props.k8Addons
      && this.props.k8Addons.constructor === Array
      && this.props.k8Addons.length !== 0) {
      let addonRequestedCountLabel;
      let addonInitiatedCountLabel;
      let addonOngoingCountLabel;
      let addonCompletedCountLabel;
      let addonRollBackCountLabel;

      let addonRequestedCount = 0;
      let addonInitiatedCount = 0;
      let addonOngoingCount = 0;

      let addonCompletedCount = 0;
      let addonRollBackCount = 0;

      let addonDeployOwner;
      return (
        <>
          {this.props.k8Addons && this.props.k8Addons.map((addon, key) => {

            switch (addon.deploymentstateinfo.code) {
              case BackEndConsts.DEPLOYMENT_ADDON_STATE_REQUESTED:
                addonRequestedCount++
                addonRequestedCountLabel = addon.deploymentstateinfo.codeinfo
                break;
              case BackEndConsts.DEPLOYMENT_ADDON_STATE_INITIATED:
                addonInitiatedCount++
                addonInitiatedCountLabel = addon.deploymentstateinfo.codeinfo
                break;
              case BackEndConsts.DEPLOYMENT_ADDON_STATE_ONGOING:
                addonOngoingCount++
                addonOngoingCountLabel = addon.deploymentstateinfo.codeinfo
                break;
              case BackEndConsts.DEPLOYMENT_ADDON_STATE_COMPLETED:
                addonCompletedCount++
                addonCompletedCountLabel = addon.deploymentstateinfo.codeinfo
                break;
              case BackEndConsts.DEPLOYMENT_ADDON_STATE_ROLLEDBACK:
                addonRollBackCount++
                addonRollBackCountLabel = addon.deploymentstateinfo.codeinfo
                break;
              default:
                break;
            }

            //Deploy owner
            addonDeployOwner = addon.deployby !== null
              ? addon.deployby : t("kubernetesSettings.platformStatusArea.addonStatus.noOneLaunched")

            return true
          })}
          <span className="addon-status-label left-label">
            {addonRequestedCount > 0 &&
              <>
                <span className="addon-msg-state warning">{addonRequestedCountLabel}</span>
                <span className="app-status-msg app-msg-highlighted">{addonRequestedCount}</span>
              </>
            }
            {addonInitiatedCount > 0 &&
              <>
                <span className="addon-msg-state warning">{addonInitiatedCountLabel}</span>
                <span className="app-status-msg app-msg-highlighted">{addonInitiatedCount}</span>
              </>
            }
            {addonOngoingCount > 0 &&
              <>
                <span className="addon-msg-state warning">{addonOngoingCountLabel}</span>
                <span className="app-status-msg app-msg-highlighted">{addonOngoingCount}</span>
              </>
            }
            {addonCompletedCount > 0 &&
              <>
                <span className="addon-msg-state success">{addonCompletedCountLabel}</span>
                <span className="app-status-msg app-msg-highlighted">{addonCompletedCount}</span>
              </>
            }
            {addonRollBackCount > 0 &&
              <>
                <span className="addon-msg-state error">{addonRollBackCountLabel}</span>
                <span className="app-status-msg app-msg-highlighted">{addonRollBackCount}</span>
              </>}
          </span>
          <span className="addon-status-label right-label">
            {t("kubernetesSettings.platformStatusArea.addonStatus.deployedBy")}
            <span className="app-status-msg app-msg-email">
              {addonDeployOwner}
            </span>
          </span>
        </>
      )
    } else return (
      <span className="app-status-msg ">{t("kubernetesSettings.platformStatusArea.addonStatus.noAddonDeployInfo")}</span>
    )
  };

  renderAddonsTableValues = () => {
    if (this.props.k8Addons
      && this.props.k8Addons.constructor === Array
      && this.props.k8Addons.length !== 0) {
      return (
        <>
          {this.props.k8Addons && this.props.k8Addons.map((addon, index) => {
            return (
              <tr key={index}>
                <td className="table-value addon-table-value value-name">
                  {addon.name !== null ? addon.publicname : '-'}
                </td>
                <td className="table-value addon-table-value">
                  {addon.deploymentstateinfo.codeinfo !== null ? addon.deploymentstateinfo.codeinfo : '-'}
                </td>
                <td className="table-value addon-table-value">
                  {addon.deployduration !== null ? addon.deployduration : '-'}
                </td>
                <td className="table-value addon-table-value">
                  {addon.deploydate !== null ? handleDate(addon.deploydate).dateFormatted : '-'}
                </td>
              </tr>
            );
          })}
        </>
      )
    } else {
      return (
        <tr>
          <td className="table-value addon-table-value value-name">-</td>
          <td className="table-value addon-table-value">-</td>
          <td className="table-value addon-table-value">-</td>
          <td className="table-value addon-table-value">-</td>
        </tr>
      )
    }
  }

  render() {
    const t = this.t;

    return (
      <>
        <Row className="subsection-child-header">
          <Col sm="12">
            <h5>{t("kubernetesSettings.platformStatusArea.addonsStatusTitle")}</h5>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <tr className="row-separator messaging-wrapper">
              <td colSpan="4">
                <table className="table-addons-info">
                  <th className="collapsed-row-wrapper header-status-wrapper">
                    {this.renderAddonInfo()}
                  </th>
                </table>
              </td>
            </tr>
            {this.props.k8Addons.length !== 0 &&
              <table className="table-lainotik table-addons">
                <tr>
                  <th className="column-header column-pod">{t("kubernetesSettings.platformStatusArea.addonStatus.tableAddons.addon")}</th>
                  <th className="column-header column-state">{t("kubernetesSettings.platformStatusArea.addonStatus.tableAddons.state")}</th>
                  <th className="column-header column-duration">{t("kubernetesSettings.platformStatusArea.addonStatus.tableAddons.duration")}</th>
                  <th className="column-header column-date">{t("kubernetesSettings.platformStatusArea.addonStatus.tableAddons.date")}</th>
                </tr>
                {this.renderAddonsTableValues()}
              </table>
            }
          </Col>
        </Row>
      </>
    )
  };
};
export default AddonStatus;

AddonStatus.propTypes = {
  k8Addons: PropTypes.array
}