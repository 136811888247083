import React, { useState, useContext } from 'react';
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { handleCopyToClipboard } from "tools/commonFunctions";
import ThemeContext from 'contexts/ThemeContext';

const GenericModal = (props) => {
  const t = props.t;
  const { className } = props;
  const [modal, setModal] = useState(true);

  const toggle = (e) => {
    setModal(!modal);
    props.toggleModal(e);
  };

  const renderExtraText = () => {
    let modalExtraText = props.modalExtraText;

    if (modalExtraText && modalExtraText.length > 0) {
      if (Array.isArray(modalExtraText)) {
        return (
          <>
            {modalExtraText.map((extraText, key) => (
              <>
                <Label
                  htmlFor={key}
                  className="modal-extra-label">
                  {extraText.label}
                </Label>
                <Input
                  key={key}
                  id={props.id + key}
                  className="modal-extra-text"
                  value={extraText.content}
                  readOnly />
                <i
                  className="icon-copy modal-icon"
                  onClick={() => handleCopyToClipboard(props.id + key)}></i>
              </>
            ))}
          </>
        );
      } else {
        return (
          <>
            <Input id={props.id} className="modal-extra-text" value={modalExtraText} readOnly />
            <i className="icon-copy modal-icon" onClick={() => handleCopyToClipboard(props.id)}></i>
          </>
        );
      }
    }
    return null;
  };

  return (
    <Modal
      name={props.name}
      isOpen={modal}
      toggle={toggle}
      className={className}>
      <ModalHeader>{props.modalTitleText}</ModalHeader>
      <ModalBody className="modal-body">
        {props.modalBodyText}
        <span className="modal-body-link">{props.modalBodyLink}</span>
        {renderExtraText()}
      </ModalBody>
      <ModalFooter className="modal-footer">
        <Button
          name={props.name}
          disabled={props.acceptBtnDisabled}
          value={props.modal}
          color={useContext(ThemeContext)}
          onClick={props.acceptOnClick
            ? props.acceptOnClick
            : toggle}>
          {props.modalAcceptBtn}
        </Button>
        {props.modalCancelBtn && props.modalCancelBtn.length > 0 && (
          <Button
            name={props.name}
            disabled={props.cancelBtnDisabled}
            value={props.modal}
            color="secondary"
            onClick={props.cancelOnClick
              ? props.cancelOnClick
              : toggle}>
            {props.modalCancelBtn}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default GenericModal;