import React from "react";
import { CustomInput, Label, UncontrolledTooltip } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { CustomField } from "tools/commonFunctions";

export default class MonitoringServices extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      kubernetesState: props.kubernetesState
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.kubernetesState !== prevProps.kubernetesState) {
      const isDisabled = this.props.kubernetesState !== "READY" && this.props.kubernetesState !== "DOCUMENTATION";
      this.formik.setFieldValue("monitoringDisabled", isDisabled);
    }
  }

  validationSchema = Yup.object().shape({
    lainotikMonitoring: Yup.boolean(),
  });

  render() {
    const { lainotikMonitoring, companyServices } = this.props;
    const initialValues = { lainotikMonitoring };
    const t = this.t;

    return (
      <section className="pre-create-msg-wrapper">
        <Formik
          initialValues={initialValues}
          validationSchema={this.validationSchema}
          onSubmit={(_, { setSubmitting }) => setSubmitting(false)}
          innerRef={(formik) => (this.formik = formik)}>
          {formikProps => (
            <Form>
              {companyServices.length === 0
                ? (<div className="form-group">
                  <CustomField
                    type="checkbox"
                    id={'lainotikmonitoring-' + this.state.kubernetesId}
                    name="lainotikMonitoring"
                    as={CustomInput}
                    className="check-input-ctrl"
                    onChange={(e) => {
                      formikProps.setFieldValue("lainotikMonitoring", e.target.checked);
                      this.props.handleLainotikMonitoringChange(e);
                    }}
                    checked={formikProps.values.lainotikMonitoring}
                    disabled={this.state.monitoringDisabled} />
                  <Label check htmlFor={'lainotikmonitoring-' + this.state.kubernetesId}>
                    {t("kubernetesSettings.monitoringServices.installUseLainotik")}
                  </Label>
                </div>)
                : !lainotikMonitoring ? (
                  <div className="monitoring-msg-wrapper">
                    <p className="cert-info-date">
                      <i className="icon-alert" />
                      {t("kubernetesSettings.monitoringServices.noLainotikMonitoring")}
                    </p>
                  </div>
                ) : (
                  companyServices.map(service => (
                    service.name === "k8platform" && (
                      <div key={service.id}>
                        {service.companyserviceapps.map(app => (
                          <ul key={app.id} className="cluster-list">
                            {app.appclusters.map(cluster => (
                              <li key={cluster.id} className="cluster-elem">
                                <a
                                  className={this.state.monitoringDisabled ? "cluster-icon link-disabled" : "cluster-icon"}
                                  href={cluster.url}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  <i className={cluster.image}></i>
                                </a>
                                <span id={"ClusterTooltip-" + cluster.id} className="cluster-name">
                                  {cluster.name}
                                </span>
                                <UncontrolledTooltip placement="right" target={"ClusterTooltip-" + cluster.id}>
                                  {cluster.tooltip}
                                </UncontrolledTooltip>
                              </li>
                            ))}
                          </ul>
                        ))}
                      </div>
                    )
                  ))
                )}
            </Form>
          )}
        </Formik>
      </section>
    );
  }
}