// External libraries
import React from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { trackPromise } from "react-promise-tracker";
import {
  Container,
  Col,
  Row,
  FormGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Label,
  Button,
  Table,
  Collapse
} from "reactstrap";

// Tools and constants
import {
  updateActiveCompanyLS,
  platformNotifMessages,
  handleInputChange,
  logout,
  CustomField
} from "tools/commonFunctions";
import { dataService } from "tools/dataService/dataService"
import * as BackEndConsts from "tools/constants/backEndConstants";
import UserContextInfo from "tools/userContextInfo";
import History from "tools/history";

// Own components
import GenericModal from "components/Modal/GenericModal";
import CompanyData from "components/Cards/CompanyData";
class CompanySettings extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.i18n.t;

    this.state = {
      userRolePlatCreation: UserContextInfo.userRolePlatformCreation(),
      companyId: UserContextInfo.activeCompanyId(),
      companyActivationStatus: UserContextInfo.activeCompanyActivation(),
      accountId: UserContextInfo.userId(),
      accountEmail: UserContextInfo.userEmail(),
      cardIcon: "icon-laino-company-minilogo",
      accordionOpened: false,
      isSuperUser: false
    }
  };

  async componentDidMount() {
    if (!localStorage.getItem('ActiveCompanyData')) {
      this.handleNoCompanyID()
    } else {
      this.setState({
        isEmailSearched: false,
        submitButtonDisable: true
      })
      this.getData(this.state.companyId);
      console.log('Using data from localStorage - [CompanyId]')
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.searchEmail !== prevState.searchEmail) {
      this.setState({ isEmailSearched: false });
    }
  }

  getData(companyId) {
    const t = this.t;

    if (companyId === null || companyId === "No Company ID!") {
      this.handleNoCompanyID()
    } else {
      trackPromise(
        dataService.queries.getCompanyData(companyId).then(
          (companyresponse) => {
            this.setState({
              userRolePlatCreation: companyresponse.security.k8create,
              companyActivationStatus: companyresponse.activation,
              companyName: companyresponse.name,
              companyAddress: companyresponse.address,
              companyZipCode: companyresponse.zipcode,
              companyCity: companyresponse.city,
              companyCountryId: companyresponse.country !== null ? companyresponse.country.id : "",
              companyCountryName: companyresponse.country !== null ? companyresponse.country.name : "",
              submitButtonCaption: t("common.update"),
              companyAccounts: companyresponse.accountcompanies
            })
            updateActiveCompanyLS(companyresponse)
          }
        ).then(
          this.checkIfSuperUser()
        )
      )
    }
  }

  getSUAccountData() {
    trackPromise(
      dataService.queries.getSUAccount().then(
        (datasuaccount) => {
          if (datasuaccount !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            this.setState({
              lainosuaccount: datasuaccount.su_account
            })
          } else return null;
        }
      )
    )
  }

  checkIfSuperUser = async () => {
    const { isSU, suEmail } = await UserContextInfo.isSuperUser();
    this.setState({ isSuperUser: isSU, superUserEmail: suEmail });
  };

  handleCompanyAccounts() {
    let companyArray = this.state.companyAccounts;

    if (companyArray && companyArray.length > 1) {
      return (
        companyArray.map((accountelem, key) => {
          if (accountelem.account.email === this.state.superUserEmail) return null;
          return (
            <>
              <tr key={key}>
                <td>{accountelem.account.email}</td>
                <td>{accountelem.account.firstname}</td>
                <td>{accountelem.account.lastname}</td>
                <td className="administration-column-set"> {UserContextInfo.userRoleName(accountelem.role.id, this.props.t)} </td>
              </tr>
            </>
          )
        })
      )
    } else return (
      <>
        <tr>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td className="administration-column-set">-</td>
        </tr>
      </>
    )
  }

  handleAccountInvite = (values) => {
    trackPromise(
      dataService.mutations.inviteEmail(
        this.state.companyId,
        values.inviteEmail
      ).then(
        (backEndResponseData) => {
          if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            this.setState({
              emailInvited: true,
              searchEmail: backEndResponseData.account.email,
              accountManagedId: backEndResponseData.account.id,
              emailManagedRoleId: backEndResponseData.role.id,
              emailManagedRoleName: backEndResponseData.role.name,
              searchEmailFirstName: backEndResponseData.account.firstname,
              searchEmailLastName: backEndResponseData.account.lastname,
              inviteEmail: '',
              isEmailSearched: true
            });
            platformNotifMessages(
              backEndResponseData.response.messageinfo.messagelevel,
              backEndResponseData.response.messageinfo.messagetitle,
              backEndResponseData.response.messageinfo.message,
              values.inviteEmail
            );

            this.getData(this.state.companyId)

            if (this.state.accordionOpened === false) {
              this.toggleAccordionSection()
            }
          } else return null
        }
      )
    )
  };

  handleSearchAccount = (values) => {
    trackPromise(
      dataService.queries.getAccountInCompany(
        this.state.companyId,
        values.searchEmail
      ).then(
        (backEndResponseData) => {
          if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            this.setState({
              inviteEmail: '',
              accountManagedId: backEndResponseData.accountcompany.account.id,
              searchEmailFirstName: backEndResponseData.accountcompany.account.firstname,
              searchEmailLastName: backEndResponseData.accountcompany.account.lastname,
              emailManagedRoleId: backEndResponseData.accountcompany.role.id,
              searchEmail: backEndResponseData.accountcompany.account.email,
              isEmailSearched: true
            })
            platformNotifMessages(
              backEndResponseData.response.messageinfo.messagelevel,
              backEndResponseData.response.messageinfo.messagetitle,
              backEndResponseData.response.messageinfo.message,
              values.searchEmail
            );
          } else return null
        }
      )
    )
  }

  handleRoleUpdate = (values) => {
    trackPromise(
      dataService.mutations.updateAccountRole(
        this.state.companyId,
        this.state.accountManagedId,
        values.emailManagedRoleId
      ).then(
        (backEndResponseData) => {
          if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            this.setState({
              emailManagedRoleId: backEndResponseData.accountcompany.role.id,
              inviteEmail: '',
              searchEmail: ''
            })
            platformNotifMessages(
              backEndResponseData.response.messageinfo.messagelevel,
              backEndResponseData.response.messageinfo.messagetitle,
              backEndResponseData.response.messageinfo.message
            )

            this.getData(this.state.companyId)

            if (this.state.accordionOpened === false) {
              this.toggleAccordionSection()
            }
          } else return null
        }
      )
    )
  }

  toggleModal = (e) => {
    e.persist();

    let name = e.target.name;
    let state = e.target.value;

    this.setState({
      [name]: state === "true" ? false : true,
      deleteUserModal: !this.state.deleteUserModal
    });
  }

  deleteUserRequest() {
    const t = this.t;
    let activeCompany = UserContextInfo.activeCompanyName();

    this.setState(prevState => ({
      modalTitleText: t("company.deleteUserModal.deleteUserTitle", { searchEmail: this.state.searchEmail }),
      modalBodyText: t("company.deleteUserModal.deleteUserBody", { searchEmail: this.state.searchEmail, companyName: activeCompany }),
      modalAcceptBtn: t("company.deleteUserModal.deleteUserAcceptBtn", { searchEmail: this.state.searchEmail }),
      modalCancelBtn: t("kubernetesSettings.deleteModal.deleteCancelBtn"),
      deleteUserModal: !prevState.deleteUserModal
    }));
  }

  handleUserDelete() {
    trackPromise(
      dataService.mutations.unbindUser(
        this.state.accountManagedId,
        this.state.companyId
      ).then(
        (backEndResponseData) => {
          if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            platformNotifMessages(
              backEndResponseData.response.messageinfo.messagelevel,
              backEndResponseData.response.messageinfo.messagetitle,
              backEndResponseData.response.messageinfo.message
            );
            this.setState(prevState => ({
              deleteUserModal: !prevState.deleteUserModal,
              inviteEmail: '',
              searchEmail: '',
              isEmailSearched: false
            }));

            this.getData(this.state.companyId)

            if (this.state.accordionOpened === false) {
              this.toggleAccordionSection()
            }
          } else return null
        }
      )
    )
  }

  handleNoCompanyID = () => {
    const t = this.t;

    platformNotifMessages(
      t("common.danger"),
      t("company.missingCompanyId"),
      t("login.tryAgain")
    ),
      setTimeout(() => {
        logout();
      }, 3000)
  }

  handleRequestActivation = () => {
    trackPromise(
      dataService.queries.getCompanyData(
        this.state.companyId
      ).then(
        (companyresponse) => {
          if (companyresponse.address !== null
            && companyresponse.zipcode !== null
            && companyresponse.city !== null
            && companyresponse.country.id !== null) {
            trackPromise(
              dataService.mutations.requestAccountActivation(this.state.companyId).then(
                (backEndResponseData) => {
                  if (backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
                    updateActiveCompanyLS(backEndResponseData.company)
                    platformNotifMessages(
                      backEndResponseData.response.messageinfo.messagelevel,
                      backEndResponseData.response.messageinfo.messagetitle,
                      backEndResponseData.response.messageinfo.message,
                      this.state.companyName
                    );
                    //To check it like the rest of the forced refreshs
                    setTimeout(() => {
                      History.push('/portal/kubernetes');
                    }, 3000);
                  } else return null;
                }
              )
            )
          } else platformNotifMessages(
            "DANGER",
            "Missing Company information",
            "Please, you have to fulfill all the form bellow to request an account activation",
            this.state.companyName
          );
        }
      )
    )
  }

  handleAccountActivation() {
    const t = this.t;

    if (this.state.userRolePlatCreation === true && this.state.companyActivationStatus !== "ACTIVATED") {
      return (
        <section className="section-wrapper centered-section highlighted-section warning">
          <Row>
            <Col md="9" className="block-vert-align-middle">
              <i className="icon-alert" />
              <span className="highlighted-text">
                {t("company.companyActivationFulfill", { companyName: UserContextInfo.activeCompanyName() })}
              </span>
            </Col>
            <Col md="3">
              <Formik
                initialValues={{}}
                onSubmit={this.handleRequestActivation}>
                {() => (
                  <Form>
                    <FormGroup>
                      <Col md="auto" className="elem-submit-btn">
                        <Button
                          type="submit"
                          value="Submit">{t("kubernetesPlatform.newPlatform.requestActivation")}
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </section>
      )
    } else return null
  }

  toggleAccordionSection = () => {
    this.setState({
      accordionOpened: !this.state.accordionOpened
    })
  }

  handleInputChangeLoc = (e) => {
    let inputResponse = handleInputChange(e)

    this.setState({
      [inputResponse.name]: inputResponse.value,
      submitButtonDisable: false
    })
  };

  render() {
    const t = this.t;

    const InviteUserSchema = Yup.object().shape({
      inviteEmail: Yup.string()
        .email(t("login.loginError.incorrectUsername"))
        .required(t("company.inviteUserForm.requiredMessage")),
    });

    const SearchUserSchema = Yup.object().shape({
      searchEmail: Yup.string()
        .email(t("login.loginError.incorrectUsername"))
        .required(t("company.inviteUserForm.requiredMessage")),
    });

    const RoleUpdateSchema = Yup.object().shape({
      emailManagedRoleId: Yup.string()
        .oneOf(['1', '3'], t("company.manageUserRole.optionInvalid"))
        .required(t("company.manageUserRole.requiredMessage"))
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <section className="section-wrapper">
                <h1 className="section-title">
                  <span className="context-root">{t("signup.signupRegisterForm.formSection2")}</span>|
                  <span className="context-info">{this.state.companyName}</span>
                </h1>
              </section>
            </Col>
          </Row>
          {this.handleAccountActivation()}
          <Row>
            <Col md="12">
              <Card className="card-user">
                <CardBody>
                  <div className="author">
                    <div className="block block-one" />
                    <Row>
                      <Col md="2">
                        <div className="avatar">
                          <i className="icon-laino-company-logo" />
                        </div>
                      </Col>
                      <Col md="10">
                        <h2 className="title">{this.state.companyName}</h2>
                        <p className="admin-name">
                          <span>
                            {t("company.youreLoggedasPre")}
                            <span className="email-account">{this.state.accountEmail}</span>
                            {t("company.youreLoggedasMid", {
                              userRole: UserContextInfo.userRoleName(UserContextInfo.userRoleId(), this.props.t)
                            })}
                            {t("company.youreLoggedasPost", {
                              userRole: UserContextInfo.userRoleName(UserContextInfo.userRoleId(), this.props.t)
                            })}
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {this.state.companyName &&
              <CompanyData
                i18n={this.props.i18n}
                cardIcon={this.state.cardIcon}
                cardTitle={t("company.information")}
                companyId={this.state.companyId}
                companyName={this.state.companyName}
                companyAddress={this.state.companyAddress}
                companyZipCode={this.state.companyZipCode}
                companyCity={this.state.companyCity}
                companyCountryName={this.state.companyCountryName}
                companyCountryId={this.state.companyCountryId}
                submitButtonCaption={this.state.submitButtonCaption}
              />
            }
          </Row>
          {UserContextInfo.userRoleAccountCreation() === true &&
            <Row >
              <Col md="12">
                <Card>
                  <CardHeader className="accordion"
                    onClick={() => {
                      this.toggleAccordionSection()
                    }}>
                    <CardTitle tag="h4">
                      <i className="icon-laino-user" />
                      {t("company.accounts")}
                    </CardTitle>
                    <i className={`elem-settings-btn ${this.state.accordionOpened ? "icon-up-pike expanded" : "icon-down-pike"}`}></i>
                  </CardHeader>
                  <Collapse
                    isOpen={this.state.accordionOpened}>
                    <CardBody>
                      <Table id="Table" className="tablesorter" responsive>
                        <thead className="text-primary">
                          <tr className="theader">
                            <th className="row-email">{t("login.usernameLabel")}</th>
                            <th className="row-fname">{t("signup.signupRegisterForm.formFirstName.label")}</th>
                            <th className="row-lname">{t("signup.signupRegisterForm.formLastName.label")}</th>
                            <th className="row-role">{t("company.role")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.handleCompanyAccounts()}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
            </Row>
          }
          {UserContextInfo.userRoleAccountCreation() === true &&
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col md="12">
                  <Row>
                    <Col md="6">
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h4">
                            <i className="icon-laino-user-invite" />
                            {t("company.inviteUserForm.title", { companyName: this.state.companyName })}
                          </CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Formik
                            initialValues={{ inviteEmail: this.state.inviteEmail || '' }}
                            validationSchema={InviteUserSchema}
                            onSubmit={(values) => { this.handleAccountInvite(values) }}>
                            {() => (
                              <Form className="invite-email-wrapper">
                                <Col md="12">
                                  <Label htmlFor="inviteemail" sm="auto">{t("login.usernameLabel")}</Label>
                                  <CustomField
                                    type="email"
                                    id="inviteemail"
                                    name="inviteEmail"
                                    className="form-control"
                                    placeholder={t("company.inviteUserForm.placeHolder")} />
                                </Col>
                                <Col md="12">
                                  <Button className="form-btn align-right" type="submit">{t("company.inviteUserForm.invite")}</Button>
                                </Col>
                              </Form>
                            )}
                          </Formik>
                        </CardBody>
                      </Card>
                    </Col>
                    {UserContextInfo.userRoleAccountCreation() === true &&
                      <Col md="6">
                        <Card>
                          <CardHeader>
                            <CardTitle tag="h4">
                              <i className="icon-laino-user-search" />
                              {t("company.searchUserForm.title", { companyName: this.state.companyName })}
                            </CardTitle>
                          </CardHeader>
                          <CardBody>
                            <Formik
                              initialValues={{ searchEmail: this.state.searchEmail || '' }}
                              validationSchema={SearchUserSchema}
                              onSubmit={(values) => { this.handleSearchAccount(values) }}>
                              {() => (
                                <Form className="invite-email-wrapper">
                                  <Col md="12">
                                    <Label htmlFor="searchemail" sm="auto">{t("login.usernameLabel")}</Label>
                                    <CustomField
                                      type="email"
                                      id="searchemail"
                                      name="searchEmail"
                                      className="form-control"
                                      onChange={this.handleInputChangeLoc}
                                      placeholder={t("company.searchUserForm.placeHolder")} />
                                  </Col>
                                  <Col md="auto">
                                    <Button className="form-btn align-right" type="submit">{t("company.searchUserForm.search")}</Button>
                                  </Col>
                                </Form>
                              )}
                            </Formik>
                          </CardBody>
                        </Card>
                      </Col>
                    }
                  </Row>
                </Col>
              </Row>
              {(UserContextInfo.userRoleAccountCreation() === true || this.state.isSuperUser) &&
                ((this.state.isEmailSearched && this.state.emailManagedRoleId) &&
                  this.state.searchEmail !== this.state.isSuperUser &&
                  this.state.searchEmail !== this.state.accountEmail) &&
                <Row>
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">
                          <i className="icon-laino-user-role" />
                          {t("company.manageUserRole.title", { searchEmail: this.state.searchEmail, companyName: this.state.companyName })}
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Formik
                          initialValues={{
                            emailManagedRoleId: this.state.emailManagedRoleId || ''
                          }}
                          validationSchema={RoleUpdateSchema}
                          onSubmit={(values, { setSubmitting }) => {
                            this.handleRoleUpdate(values);
                            setSubmitting(false);
                          }} >
                          {({ values }) => (
                            <>
                              <Form className="invite-email-wrapper">
                                <Col md="12">
                                  <tr className="row-separator">
                                    <td colSpan="4">
                                      <table className="table-lainotik table-roles">
                                        <tr>
                                          <th className="column-header column-name">{t("kubernetesPlatform.tableCreation.name")}</th>
                                          <th className="column-header column-email">{t("login.usernameLabel")}</th>
                                          <th className="column-header column-role">{t("company.role")}</th>
                                        </tr>
                                        <tr>
                                          <td className="table-value value-name">{this.state.searchEmailFirstName} {this.state.searchEmailLastName}</td>
                                          <td className="table-value">{this.state.searchEmail}</td>
                                          <td className="table-value">
                                            <div className="custom-radio custom-control custom-control-inline">
                                              <CustomField
                                                shape="radio"
                                                type="radio"
                                                className="custom-control-input"
                                                name="emailManagedRoleId"
                                                value="1"
                                                label={t("common.roles.administrator")} />
                                            </div>
                                            <div className="custom-radio custom-control custom-control-inline">
                                              <CustomField
                                                shape="radio"
                                                type="radio"
                                                className="custom-control-input"
                                                name="emailManagedRoleId"
                                                value="3"
                                                label={t("common.roles.user")} />
                                            </div>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </Col>
                                <Col sm="12">
                                  <Row>
                                    <Col>
                                      <Button
                                        className="form-btn"
                                        onClick={() => { this.deleteUserRequest() }}>
                                        {t("common.delete")}
                                      </Button>
                                    </Col>
                                    <Col>
                                      <Button
                                        className="form-btn align-right"
                                        type="submit"
                                        value="Submit">
                                        {t("common.update")}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Form>
                            </>
                          )}
                        </Formik>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              }
            </Container>
          }
        </div>
        {this.state.deleteUserModal ?
          <GenericModal
            name="deleteUserModal"
            toggleModal={(e) => { this.toggleModal(e) }}
            acceptOnClick={() => { this.handleUserDelete() }}
            modalTitleText={this.state.modalTitleText}
            modalBodyText={this.state.modalBodyText}
            modalAcceptBtn={this.state.modalAcceptBtn}
            modalCancelBtn={this.state.modalCancelBtn} /> :
          null
        }
      </>
    )
  };
};
export default CompanySettings