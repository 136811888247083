import UserContextInfo from "tools/userContextInfo";

export default function AuthHeader(clientAuth) {
  const user = JSON.parse(localStorage.getItem('AccountInfo'));

  if (user && user.accesstoken) {
    let userAuth = 'Bearer ' + user.accesstoken;
    let companyAuth = UserContextInfo.activeCompanyId();
    let platformAuth = UserContextInfo.activePlatformId();

    return {
      'Client-Authorization': clientAuth,
      'Authorization': userAuth,
      'Company-Id': companyAuth,
      'K8-Id': platformAuth
    };
  } else return {
    'Client-Authorization': clientAuth,
    'Authorization': '',
    'Company-Id': '',
    'K8-Id': ''
  };
}