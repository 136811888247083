import React from "react";

import { withTranslation } from "react-i18next";

class FixedPlugin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: "dropdown show-dropdown"
    };
  };
  
  handleClick = () => {
    if (this.state.classes === "dropdown show-dropdown") {
      this.setState({ classes: "dropdown show-dropdown show" });
    } else {
      this.setState({ classes: "dropdown show-dropdown" });
    }
  };

  handleLanguageChangeLoc = (language) => {
    this.props.i18n.changeLanguage(language);
    this.setState({}); //The value of the language changes through the languageSelector.js component
  };

  render() {
    const { t } = this.props;
    return (
      <div className="fixed-plugin">
        <div className={this.state.classes}>
          <div onClick={this.handleClick}>
            <i className="icon-laino-theme" />
          </div>
          <ul className="dropdown-menu show">
            <li className="header-title">{t("fixedPlugin.uiTheme")}</li>
            <li className="adjustments-line">
              <div className="badge-colors text-center">
                <span
                  className={
                    this.props.bgColor === "primary"
                      ? "badge filter badge-primary active"
                      : "badge filter badge-primary"
                  }
                  data-color="primary"
                  onClick={() => {
                    this.props.handleBgClick("primary");
                  }}
                />{" "}
                <span
                  className={
                    this.props.bgColor === "blue"
                      ? "badge filter badge-info active"
                      : "badge filter badge-info"
                  }
                  data-color="second"
                  onClick={() => {
                    this.props.handleBgClick("second");
                  }}
                />{" "}
                <span
                  className={
                    this.props.bgColor === "yellow"
                      ? "badge filter badge-warning active"
                      : "badge filter badge-warning"
                  }
                  data-color="third"
                  onClick={() => {
                    this.props.handleBgClick("third");
                  }}
                />{" "}
              </div>
            </li>
            <li className="adjustments-line text-center color-change">
              <span className="color-label">{t("fixedPlugin.lightMode")}</span>{" "}
              <span
                className="badge light-badge mr-2"
                onClick={() => this.props.activateMode("light")}
              />{" "}
              <span
                className="badge dark-badge ml-2"
                onClick={() => this.props.activateMode("dark")}
              />{" "}
              <span className="color-label">{t("fixedPlugin.darkMode")}</span>{" "}
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default withTranslation()(FixedPlugin);
