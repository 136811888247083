import React from "react";

import {
  Container
} from "reactstrap";

import i18n from "localization/i18n";

import EmailRequestPage from "layouts/Onboarding/ForgotPassword/ForgotPass_EmailRequest";
import EmailSentPage from "layouts/Onboarding/ForgotPassword/ForgotPass_EmailSent";
export default class ForgotPassword extends React.Component {
  constructor(props) {
    super();
    this.state = {
      emailSent: false,
    }
  };

  componentDidMount() {
    i18n.changeLanguage(i18n.language || 'en');
  }

  handlePasswordReset(newAccountEmail) {
    this.setState({
      emailSent: true,
      accountEmail: newAccountEmail
    })
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <Container className="h-100vh">
          {(!this.state.emailSent) ?
            <EmailRequestPage
            t={t}
              handlePasswordReset={(e) => { this.handlePasswordReset(e) }} /> :
            <EmailSentPage
            t={t}
              accountEmail={this.state.accountEmail} />}
        </Container>
      </>
    )
  }
}