import React from "react";
import { withTranslation } from "react-i18next";
import i18n from "localization/i18n";
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from "reactstrap";

class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageLabel: this.getLanguageName(i18n.language),
      dropdownOpened: false,
    };
    this.dropdownRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    const dropdownMenu = this.dropdownRef.current;
    if (dropdownMenu && !dropdownMenu.contains(event.target)) {
      this.setState({ dropdownOpened: false });
    }
  }

  handleLanguageChange = (language) => {
    this.props.handleLanguageChange(language);
    this.setState({
      languageLabel: this.getLanguageName(language),
      dropdownOpened: false,
    });
  };

  getLanguageName = (code) => {
    const languageMap = {
      en: "en",
      es: "es",
      eus: "eus",
    };

    return languageMap[code] || "en";
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpened: !prevState.dropdownOpened,
    }));
  };

  render() {
    const { t } = this.props;
    const { languageLabel, dropdownOpened } = this.state;

    const languageOptions = [
      { value: "en", label: t("language-selector.options.english") },
      { value: "es", label: t("language-selector.options.spanish") },
      { value: "eus", label: t("language-selector.options.basque") },
    ];

    const tooltipMessage = `${languageLabel} (${i18n.t('language-selector.options.tooltip.open-dropdown')})`;

    return (
      <>
        <DropdownToggle
          ref={this.dropdownRef}
          className="lang-select-dropdown-wrapper"
          caret
          color="default"
          data-toggle="dropdown"
          nav
          id="language-selector"
          onClick={this.toggleDropdown}>
          <i className="icon-language d-none d-lg-block" />
          <p className="lang-select-currentlang">
            <span className="sr-only">{t('language-selector.label')}</span>
            {languageLabel}
          </p>
          <i className={dropdownOpened ? "icon-up-pike" : "icon-down-pike"}></i>
        </DropdownToggle>
        <UncontrolledTooltip placement="right" target="language-selector">
          {tooltipMessage}
        </UncontrolledTooltip>
        <div ref={this.dropdownRef}>
          <DropdownMenu
            id="dropDownLangSelector"
            className="dropdown-navbar drop-down-lang-selector"
            right
            tag="ul"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            {languageOptions.map(({ value, label }) => (
              <DropdownItem
                key={value}
                onClick={() => this.handleLanguageChange(value)}
                className={i18n.language === value ? "lang-in-use" : ""}
              >
                {i18n.language === value
                  ? (<> {label} <i className="icon-confirm" /> </>)
                  : (label)}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </div>
      </>
    );
  }
}

export default withTranslation()(LanguageSelector);
