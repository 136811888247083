import React from "react";
import ReactDOM from "react-dom";
import i18n from './localization/i18n';
import { I18nextProvider } from 'react-i18next';
import History from "tools/history";
import {
  Router,
  Switch,
  Route
} from "react-router-dom";

//Alternative notification library
import { ReactNotifications } from 'react-notifications-component'

import { GRAPH_QL_API_URL, SHOW_SIGNUP } from "./tools/config";

import Login from "layouts/Onboarding/Login";
import SignUp from "layouts/Onboarding/SignUp/SignUp";
import ForgotPassword from "layouts/Onboarding/ForgotPassword/ForgotPassword";
import ResetPassword from "layouts/Onboarding/ForgotPassword/ResetPassword";
import AdminLayout from "layouts/Admin/Admin";

import "assets/scss/paasplatform-react.scss";
import "assets/css/nucleo-icons.css";

//Apollo and related stuff imports
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink
} from "@apollo/client";

import { LainoSpinner } from "components/Spinner/Spinner"

const cache = new InMemoryCache();

const link = new HttpLink({
  uri: { GRAPH_QL_API_URL }
})

const client = new ApolloClient({
  cache,
  link
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <I18nextProvider i18n={i18n}>
      <Router history={History}>
        <ReactNotifications />
        <Switch>
          <Route path="/portal" render={props => <AdminLayout {...props} />} />
          <Route path="/forgotpassword" render={props => <ForgotPassword {...props} />} />
          <Route path="/resetpassword" render={props => <ResetPassword {...props} />} />
          {SHOW_SIGNUP === true &&
            <Route path="/signup" render={props => <SignUp {...props} />} />
          }
          <Route exact path="/" render={props => <Login {...props} />} />
          {/* 👇️ only match this when no other routes match */}
          <Route path="*" render={props => <Login {...props} />} />
        </Switch>
        <LainoSpinner />
      </Router>
    </I18nextProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
