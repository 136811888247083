import React from "react";
import UserContextInfo from "tools/userContextInfo";

import { handleChangeDropdown } from "tools/commonFunctions";

import {
  Row,
  Col,
  Button,
  Collapse
} from "reactstrap";

export default class StorageSetup extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;

    this.state = {
      kStorageClassesObject: [],
      accordionOpened: false,
      submitButtonDisable: true
    }
  };

  async componentDidMount() {
    if (Array.isArray(this.props.k8Storages) && this.props.k8Storages.length !== 0) {
      this.setState({
        stepOk: true
      });
      this.props.handleStepValidation("STORAGE", true);
    } else {
      this.setState({
        stepOk: false
      });
      this.props.handleStepValidation("STORAGE", false);
    }
  };

  toggleStorageSection = () => {
    this.setState(prevState => ({ accordionOpened: !prevState.accordionOpened }));
  };

  handleChangeDropdown = (e) => {
    let dropdownResponse = handleChangeDropdown(e)

    this.setState({
      [`${dropdownResponse.name}Id`]: dropdownResponse.childId,
      submitButtonDisable: false
    });
  };

  renderStorageClassTableValues = () => {
    const t = this.t;

    return (
      <table className="table-lainotik table-storageclass">
        <tr>
          <th className="column-header column-name">{t("kubernetesPlatform.tableCreation.name")}</th>
          <th className="column-header column-reclaim">{t("kubernetesSettings.storagesSetup.tableStorageClass.reclaim")}</th>
          <th className="column-header column-binding">{t("kubernetesSettings.storagesSetup.tableStorageClass.binding")}</th>
          <th className="column-header column-expansion">{t("kubernetesSettings.storagesSetup.tableStorageClass.expansion")}</th>
          <th className="column-header column-provisioner">{t("kubernetesSettings.storagesSetup.tableStorageClass.provisioner")}</th>
        </tr>
        {Array.isArray(this.props.k8Storages) && this.props.k8Storages.length !== 0
          ? this.props.k8Storages.map((storageClass) => {
            return (
              <tr key={storageClass.id}>
                <td className="table-value node-table-value value-name">{storageClass.publicname}</td>
                <td className="table-value node-table-value">{storageClass.reclaimpolicy}</td>
                <td className="table-value node-table-value">{storageClass.volumebindingmode}</td>
                <td className="table-value node-table-value">{String(storageClass.allowvolumeexpansion)}</td>
                <td className="table-value node-table-value">{storageClass.provisioner}</td>
              </tr>
            );
          })
          : <tr>
            <td className="table-value node-table-value value-name">-</td>
            <td className="table-value node-table-value">-</td>
            <td className="table-value node-table-value">-</td>
            <td className="table-value node-table-value">-</td>
            <td className="table-value node-table-value">-</td>
          </tr>
        }
      </table>
    )
  };

  render() {
    const t = this.t;

    return (
      <>
        <Row className="sub-section-header"
          id={'storage-' + this.props.kubernetesPlatform.id}
          onClick={(e) => {
            this.toggleStorageSection(e)
          }}>
          <Col sm="6">
            <h4>{t("kubernetesSettings.overviewSettings.storage")}</h4>
          </Col>
          <Col sm="6" className="sub-section-btn">
            <i
              className={`elem-settings-btn ${this.state.accordionOpened ? "icon-up-pike expanded" : "icon-down-pike"}`}>
            </i>
          </Col>
        </Row>
        <Collapse
          isOpen={this.state.accordionOpened}
          className="sub-section-body storage-sub-section">
          <Row>
            <Col sm="12">
              <tr className="row-separator">
                <td colSpan="4">
                  {this.renderStorageClassTableValues()}
                </td>
              </tr>
            </Col>
          </Row>
          <Row>
            <Col sm="6" className="block-vert-align-middle">
              <i name="csrModal" className="icon-info info-context" />
              <span className="app-status-label">{t("kubernetesSettings.storagesSetup.availableStorageClasses")}</span>
            </Col>
            {!this.props.kubeDemo &&
              UserContextInfo.userRolePlatformCreation()
              && UserContextInfo.activeCompanyActivation() === "ACTIVATED" &&
              <Col sm="6" className="block-vert-align-middle">
                <Button
                  className="form-btn align-right"
                  onClick={(e) => { this.props.handleStorageRefresh(e) }}>
                  {t("common.refresh")}</Button>
              </Col>
            }
          </Row>
        </Collapse>
      </>
    )
  };
};
