import React from "react";
import { Link } from "react-router-dom";
import {
  platformNotifMessages,
  CustomField
} from "tools/commonFunctions";
import * as BackEndConsts from "tools/constants/backEndConstants";
import { dataService } from "tools/dataService/dataService"
import { trackPromise } from "react-promise-tracker";
import {
  Row,
  Col,
  Label,
  Button
} from "reactstrap";
import { withTranslation } from "react-i18next";
import SimpleFooter from "components/Footer/SimpleFooter";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

class EmailRequestPage extends React.Component {
  constructor(props) {
    super();
    this.state = {}
  }

  handleFormSubmit = (values) => {
    trackPromise(
      dataService.mutations.forgotPassword(
        values.accountEmail
      ).then((dataforgotpasswordData) => {
        if (dataforgotpasswordData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
          platformNotifMessages(
            dataforgotpasswordData.messagelevel,
            dataforgotpasswordData.messagetitle,
            dataforgotpasswordData.message,
            values.accountEmail
          );
          // Continue just if the password has been successfully reset
          this.props.handlePasswordReset(values.accountEmail);
        } else return null;
      })
    );
  };

  render() {
    const { t } = this.props;

    const validationSchema = Yup.object().shape({
      accountEmail: Yup.string()
        .email(t("login.loginError.incorrectUsername"))
        .required(t("login.loginError.requiredUsername"))
    });

    return (
      <>
        <Row className="justify-content-center align-items-center">
          <Col md="6">
            <div className="content signup-form-wrapper">
              <img
                className="lainotik-logo"
                alt="Lainotik logo"
                src={require('assets/img/condensed-pink.svg').default} />
            </div>
          </Col>
        </Row>
        <div className="signup-form-wrapper">
          <Row className="justify-content-center align-items-center">
            <Col fluid="sm" className="section-header">
              <section className="section-title">
                <h1>{t("forgotPassword.title")}</h1>
              </section>
              <section className="section-intro">
                <p>{t("forgotPassword.recoveryExplanation")}</p>
              </section>
              <span className="onboarding-nav-link">
                <Link to="/">{t("signup.signupConfirmPage.backToLogin")}</Link>
              </span>
            </Col>
          </Row>
          <Formik
            initialValues={{
              accountEmail: this.state.accountEmail
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => this.handleFormSubmit(values)}>
            {({ ...formikProps }) => (
              <Form>
                <Row className="justify-content-center align-items-center">
                  <Col sm="6">
                    <fieldsection>
                      <Label htmlFor="accountemail" sm="auto">{t("login.usernameLabel")}</Label>
                      <CustomField
                        type="email"
                        id="accountemail"
                        name="accountEmail"
                        sm="auto"
                        className="form-control"
                        placeholder={t("login.usernamePlaceholder")}
                        form={formikProps} />
                    </fieldsection>
                  </Col>
                </Row>
                <Row className="justify-content-center align-items-center">
                  <Col sm="6">
                    <Button
                      className="form-btn align-right"
                      type="submit"
                      value="Submit">{t("forgotPassword.resetPasswordButton")}</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          <SimpleFooter />
        </div>
      </>
    )
  }
}
export default withTranslation()(EmailRequestPage);