import React from "react";

import {
  Row,
  Col,
  Collapse
} from "reactstrap";

import i18next from 'i18next';

import MonitoringStatus from "./monitoringStatus";
import AddonStatus from "./addonStatus";

class PlatformStatusArea extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;

    this.state = {
      platStatusAccordionOpened: this.props.platStatusAccordionOpened
    }
  };

  toggleStatusSection = () => {
    this.setState({
      platStatusAccordionOpened: !this.state.platStatusAccordionOpened
    })
  }

  render() {
    const t = this.t;
    const currentLanguage = i18next.language;

    return (
      <>
        <Row className="sub-section-header"
          id={'status-' + this.props.kubernetesPlatform.id}
          onClick={(e) => {
            this.toggleStatusSection(e)
          }}
        >
          <Col sm="6">
            <h4>
              {currentLanguage === 'es'
                ? `${t('kubernetesSettings.platformStatusArea.setup')} ${t('kubernetesSettings.platformStatusArea.addons')}`
                : `${t('kubernetesSettings.platformStatusArea.addons')}`}
              {this.props.kubernetesPlatform.lainotikmonitoring !== false
                ? ` ${t('kubernetesSettings.platformStatusArea.monitoring')} `
                : ' '}
              {currentLanguage !== 'es' && t('kubernetesSettings.platformStatusArea.setup')}
            </h4>
          </Col>
          <Col sm="6" className="sub-section-btn">
            <i
              className={`elem-settings-btn ${this.state.platStatusAccordionOpened ? "icon-up-pike expanded" : "icon-down-pike"}`}>
            </i>
          </Col>
        </Row>
        <Collapse
          isOpen={this.state.platStatusAccordionOpened}
          className="sub-section-body">
          {
            this.props.k8Addons &&
            <AddonStatus
              t={t}
              k8Addons={this.props.k8Addons}
              kubernetesStateCode={this.props.kubernetesStateCode} />
          }
          {
            this.props.appNotifications &&
            this.props.kubernetesPlatform.lainotikmonitoring !== false &&
            <MonitoringStatus
              kubernetesPlatform={this.props.kubernetesPlatform}
              kubernetesStateCode={this.props.kubernetesStateCode}
              appNotifications={this.props.appNotifications}
              monitoringDeployCode={this.props.monitoringDeployCode}
              monitoringDeployCodeInfo={this.props.monitoringDeployCodeInfo}
              monitoringDeployDate={this.props.monitoringDeployDate}
              monitoringDeployDuration={this.props.monitoringDeployDuration}
              monitoringDeployBy={this.props.monitoringDeployBy} />
          }
        </Collapse>
      </>
    )
  };
};
export default PlatformStatusArea;