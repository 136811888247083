import React from "react";
import PropTypes from 'prop-types';
import * as BackEndConsts from "tools/constants/backEndConstants";
import UserContextInfo from "tools/userContextInfo";
import {
  handleDate,
  handleChangeDropdown,
  platformNotifMessages,
  CustomField
} from "tools/commonFunctions";
import { dataService } from "tools/dataService/dataService"
import { trackPromise } from "react-promise-tracker";
import { Row, Col, Button, Collapse, UncontrolledTooltip, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
export default class NodesSetup extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      accordionOpened: false
    }
  };

  componentDidMount() {
    if ((this.props.k8Nodes
      && this.props.k8Nodes.constructor === Array
      && this.props.k8Nodes.length !== 0)
      && this.props.kubeDefaultLabelId !== null) {

      const defaultLabel = this.props.k8Labels.find(label => label.default);
      if (defaultLabel) {
        this.setState({
          stepOk: true,
          nodeDefaultLabelName: defaultLabel.name,
          nodeDefaultLabelId: defaultLabel.id
        });
      }

      this.setState({
        stepOk: true,
        nodeDefaultLabelName: this.props.kubeDefaultLabelName
      });
      this.props.handleStepValidation("NODES", true);
    } else {
      this.setState({
        stepOk: false
      });
      this.props.handleStepValidation("NODES", false);
    }
  }

  toggleNodesSection = (e) => {
    this.setState({
      accordionOpened: !this.state.accordionOpened
    })
  };

  renderLabels = () => {
    const t = this.t;

    return (
      <div>
        <CustomField
          as="select"
          name="nodeDefaultLabelId"
          className="dropdown-wrapper form-control"
          onChange={(e) => {
            this.handleChangeDropdown(e);
          }}>
          {
            this.state.nodeDefaultLabelId
              ? <option
                key={this.state.nodeDefaultLabelId}
                value={this.state.nodeDefaultLabelId}>
                {this.state.nodeDefaultLabelName}
              </option>
              : <option
                value="">
                {t("kubernetesSettings.nodesSetup.nodeLabelDropdown.placeholder")}
              </option>
          }
          {
            this.props.k8Labels.map((defaultLabel) => (
              this.state.nodeDefaultLabelId !== defaultLabel.id
                ? <option
                  key={defaultLabel.id}
                  value={defaultLabel.id}>
                  {defaultLabel.name}
                </option>
                : null
            ))
          }
        </CustomField>
      </div>
    )
  }

  renderNodesTableValues = () => {
    const t = this.t;

    return (
      <table className="table-lainotik table-nodes">
        <tr>
          <th className="column-header column-name">{t("kubernetesPlatform.tableCreation.name")}</th>
          <th className="column-header column-label">{t("kubernetesSettings.nodesSetup.tableNodes.label")}</th>
          <th className="column-header column-podslimit">{t("kubernetesSettings.nodesSetup.tableNodes.podsLimit")}</th>
          <th className="column-header column-cpu">{t("kubernetesSettings.nodesSetup.tableNodes.vCores")}</th>
          <th className="column-header column-mem">{t("kubernetesSettings.nodesSetup.tableNodes.mem")}</th>
          <th className="column-header column-percentcpu">{t("kubernetesSettings.nodesSetup.tableNodes.%CPU")}</th>
          <th className="column-header column-percentmem">{t("kubernetesSettings.nodesSetup.tableNodes.%Mem")}</th>
          <th className="column-header column-creation">{t("kubernetesSettings.nodesSetup.tableNodes.creation")}</th>
        </tr>
        {this.props.k8Nodes.constructor === Array
          && this.props.k8Nodes.length !== 0
          ? this.props.k8Nodes.map((node) => {
            return (
              <tr key={node.id}>
                <td
                  id={"nodespecs-" + node.id}
                  className="table-value node-table-value value-label">{node.name}
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"nodespecs-" + node.id}>
                    <ul className="nodespecs">
                      <li><span className="tooltip-label">{t("kubernetesPlatform.tableCreation.name")}:</span> {node.name}</li>
                      <li><span className="tooltip-label">{t("kubernetesSettings.nodesSetup.nodeSpecs.architec")}:</span> {node.architecture}</li>
                      <li><span className="tooltip-label">{t("kubernetesSettings.nodesSetup.nodeSpecs.os")}:</span> {node.os}</li>
                      <li><span className="tooltip-label">{t("kubernetesSettings.nodesSetup.nodeSpecs.osImage")}:</span> {node.osimage}</li>
                      <li><span className="tooltip-label">{t("kubernetesSettings.nodesSetup.nodeSpecs.kernel")}:</span> {node.kernel}</li>
                      <li><span className="tooltip-label">{t("kubernetesSettings.nodesSetup.nodeSpecs.kubelet")}:</span> {node.kubelet}</li>
                      <li><span className="tooltip-label">{t("kubernetesSettings.nodesSetup.nodeSpecs.kubeproxy")}:</span> {node.kubeproxy}</li>
                    </ul></UncontrolledTooltip>
                </td>
                <td className="table-value node-table-value">{node.label}</td>
                <td className="table-value node-table-value">{node.maxpods}</td>
                <td className="table-value node-table-value">{node.cores}</td>
                <td className="table-value node-table-value">{node.memory}</td>
                <td className="table-value node-table-value">{node.usedcores}</td>
                <td className="table-value node-table-value">{node.usedmemory}</td>
                <td className="table-value node-table-value">{handleDate(node.creation).dateFormatted}</td>
              </tr>
            );
          })
          : <tr>
            <td className="table-value node-table-value value-name">-</td>
            <td className="table-value node-table-value">-</td>
            <td className="table-value node-table-value">-</td>
            <td className="table-value node-table-value">-</td>
            <td className="table-value node-table-value">-</td>
            <td className="table-value node-table-value">-</td>
            <td className="table-value node-table-value">-</td>
            <td className="table-value node-table-value">-</td>
          </tr>
        }
      </table>
    )
  };

  renderLabels = (formikProps) => {
    const t = this.t;

    return (
      <div>
        <CustomField
          as="select"
          id={'nodedefaultlabelid-' + this.props.kubeId}
          name="nodeDefaultLabelId"
          className="dropdown-wrapper form-control"
          onChange={(e) => {
            this.handleChangeDropdown(e);
          }}
          form={formikProps} >
          {
            this.state.nodeDefaultLabelId
              ? <option
                key={this.state.nodeDefaultLabelId}
                value={this.state.nodeDefaultLabelId}>
                {this.state.nodeDefaultLabelName}
              </option>
              : <option
                value="">
                {t("kubernetesSettings.nodesSetup.nodeLabelDropdown.placeholder")}
              </option>
          }
          {
            this.props.k8Labels.map((defaultLabel) => (
              this.state.nodeDefaultLabelId !== defaultLabel.id
                ? <option
                  key={defaultLabel.id}
                  value={defaultLabel.id}>
                  {defaultLabel.name}
                </option>
                : null
            ))
          }
        </CustomField>
      </div>
    )
  }

  renderLabelSection = (formikProps) => {
    const t = this.t;

    return (
      !this.props.kubeDemo
        && UserContextInfo.userRolePlatformCreation()
        && UserContextInfo.activeCompanyActivation() === "ACTIVATED"
        && this.props.kubernetesStateCode !== BackEndConsts.DEPLOYMENT_PLATFORM_STATE_READY
        ? <section className="highlighted-section">
          <Row>
            <Col sm="auto" className="block-vert-align-middle">
              <Label htmlFor={'nodedefaultlabelid-' + this.props.kubeId} sm="auto">{t("kubernetesSettings.nodesSetup.labelSection.label")}</Label>
              {this.renderLabels(formikProps)}
            </Col>
          </Row>
          <Row>
            <Col sm="6" className="block-vert-align-middle">
              <i name="csrModal" className="icon-alert" />
              <span className="app-status-label">{t("kubernetesSettings.nodesSetup.labelSection.warningSetOnce")}</span>
            </Col>
            <Col sm="6">
              <Button
                className="form-btn align-right"
                type="submit"
                value="Submit">
                {t("kubernetesSettings.nodesSetup.labelSection.set")}</Button>
            </Col>
          </Row>
        </section>
        : <section className="highlighted-section">
          <Row>
            <Col sm="auto" className="block-vert-align-middle">
              {this.state.nodeDefaultLabelName
                ? <i className="icon-info info-context" />
                : <i className="icon-alert" />}
              <span>
                {t("kubernetesSettings.nodesSetup.labelSection.defaultAssigned")} {this.state.nodeDefaultLabelName
                  ? <span className="highlighted-text upper info">{this.state.nodeDefaultLabelName}</span>
                  : <span className="highlighted-text upper warning">{t("kubernetesSettings.nodesSetup.labelSection.noLabelSet")}</span>}
              </span>
            </Col>
          </Row>
        </section>
    )
  }

  handleChangeDropdown = (e) => {
    let dropdownResponse = handleChangeDropdown(e)

    this.setState({
      nodeDefaultLabelId: dropdownResponse.childId,
      nodeDefaultLabelName: dropdownResponse.value,
      submitButtonDisable: false
    });
  };

  handleDefaultNodeLabel = (values) => {
    trackPromise(
      dataService.mutations.newDefaultLabelValue(
        this.props.kubeId,
        values.nodeDefaultLabelId
      ).then(
        (backEndResponseData) => {
          if (backEndResponseData
            && backEndResponseData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
            this.setState({
              StepOk: true
            })
            this.props.handleStepValidation("NODES", true);
            platformNotifMessages(
              backEndResponseData.response.messageinfo.messagelevel,
              backEndResponseData.response.messageinfo.messagetitle,
              backEndResponseData.response.messageinfo.message
            )
          } else {
            this.props.handleStepValidation("NODES", false);
            this.setState({
              StepOk: false
            })
          }
        }
      )
    )
  }

  render() {
    const t = this.t;
    const validationSchema = Yup.object().shape({
      nodeDefaultLabelId: Yup.string()
        .required(t("kubernetesSettings.nodesSetup.nodeLabelDropdown.placeholder"))
    });

    return (
      <>
        <Row className="sub-section-header"
          id={'nodes-' + this.props.kubeId}
          onClick={(e) => {
            this.toggleNodesSection(e)
          }}>
          <Col sm="6">
            <h4>{t("kubernetesSettings.overviewSettings.nodes")}</h4>
          </Col>
          <Col sm="6" className="sub-section-btn">
            <i className={`elem-settings-btn ${this.state.accordionOpened ? "icon-up-pike expanded" : "icon-down-pike"}`}></i>
          </Col>
        </Row>
        <Collapse
          isOpen={this.state.accordionOpened}
          className="sub-section-body">
          <Formik
            initialValues={{
              nodeDefaultLabelId: this.state.nodeDefaultLabelId || ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => { this.handleDefaultNodeLabel(values) }}>
            {({ ...formikProps }) => (
              <Form>
                {this.props.kubernetesStateCode && this.renderLabelSection(formikProps)}
              </Form>
            )}
          </Formik>
          <Row>
            <Col sm="12">
              <tr className="row-separator">
                <td colSpan="5">
                  {this.renderNodesTableValues()}
                </td>
              </tr>
            </Col>
          </Row>
          <Row>
            <Col sm="6" className="block-vert-align-middle">
              <i name="csrModal" className="icon-info info-context" />
              <span className="app-status-label">{t("kubernetesSettings.nodesSetup.nodesLainotikTip")}</span>
            </Col>
            {!this.props.kubeDemo &&
              UserContextInfo.userRolePlatformCreation()
              && UserContextInfo.activeCompanyActivation() === "ACTIVATED" &&
              <Col sm="6" className="block-vert-align-middle">
                <Button
                  className="form-btn align-right"
                  onClick={(e) => { this.props.handleNodesRefresh(e) }}>
                  {t("common.refresh")}</Button>
              </Col>
            }
          </Row>
        </Collapse>
      </>
    )
  }
};

NodesSetup.propTypes = {
  k8Nodes: PropTypes.array
}