import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { Spinner } from "reactstrap";

export const LainoSpinner = () => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div className="spinner-overlay">
        <Spinner
            type="grow" //default: 'border'
            color="primary"
            size="lg"
            className="laino-spinner"
            children="Loading..." //default: 'Loading...'
          />
      </div>
    )
  );
};