import React from "react";
import {
  logout,
  updateActiveCompanyLS,
  updateActivePlatformLS
} from "tools/commonFunctions";
import UserContextInfo from "tools/userContextInfo";
import { dataService } from "tools/dataService/dataService"

//Library to track the fetch Backend calls
import { trackPromise } from 'react-promise-tracker';

import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

// javascript plugin used to create scrollbars on windows
//import PerfectScrollbar from "perfect-scrollbar";

//Multilanguage library (configuration component & Language selector element)
import i18n from "localization/i18n";
import LanguageSelector from "tools/languageSelector";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import FullFooter from "components/Footer/FullFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import { routes } from "routes.js";

// Lainotik logo for light and dark themes
import Logo from "assets/img/horizontal-white.png";
import LogoDark from "assets/img/horizontal-dark.png";
import userContextInfo from "tools/userContextInfo";
import PlatformService from "sections/services/subSections/PlatformService";

import { MobileOnlyView } from 'react-device-detect';
import ThemeContext from "contexts/ThemeContext";

//let ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      kubernetesPlatform: [],
      activePlatformServices: [],
      kubernetesPlatformName: "",
      fallbackPlatformName: i18n.t("kubernetesPlatform.selectPlatform"),
      kubernetesPlatformId: "",
      kubernetesState: "",
      kubernetesStateLevel: "",
      theme: localStorage.getItem('Theme') ? JSON.parse(localStorage.getItem('Theme')) : "dark",
      backgroundColor: localStorage.getItem('ThemeColour') ? JSON.parse(localStorage.getItem('ThemeColour')) : "primary",
      sidebarOpened:
        document.documentElement.className.indexOf("nav-open") !== -1,
    }

    this.handlePlatformServices = this.handlePlatformServices.bind(this);
  };

  async componentDidMount() {
    // Listener for language changes
    i18n.on('languageChanged', this.handleLanguageChange);

    this.activateMode(this.state.theme);

    //Get the data of the current-selected platform for the sidebar tree
    if (!localStorage.getItem('ActivePlatformData') || localStorage.getItem('ActivePlatformData') === "[]") {
      console.log('No Platforms created');
      localStorage.removeItem('ActivePlatformData');
    } else {
      let activePlatformDataObj = JSON.parse(localStorage.getItem('ActivePlatformData'));
      this.setState({
        kubernetesPlatformId: activePlatformDataObj.PlatformId,
        kubernetesPlatformName: activePlatformDataObj.PlatformName,
        kubernetesStateLevel: activePlatformDataObj.PlatformStatusLevel,
        kubernetesState: activePlatformDataObj.PlatformStatus,
        kubeAccessStatus: activePlatformDataObj.PlatformAccessStatus,
        kubeConnectStatus: activePlatformDataObj.PlatformConnectStatus,
        kubeHealthCheckClass: activePlatformDataObj.PlatformHealthClass,
        kubeHealthCheckMessage: activePlatformDataObj.PlatformHealthCheckMessage
      })
    }

    /*if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }*/

    if (!localStorage.getItem('ActiveCompanyData')) {
      console.log('No Company data found - Admin.js')
      logout();
    } else {
      this.setState({
        companyId: UserContextInfo.activeCompanyId(),
      })

      let companyId = UserContextInfo.activeCompanyId();
      //Call to get full platform data for this company
      trackPromise(
        dataService.queries.getKubernetesData(companyId).then(
          (datakubernetes) => {
            this.setState({
              kubernetesPlatform: datakubernetes
            });
            updateActiveCompanyLS(datakubernetes[0].company);
          }
        )
      );

      //Save the services of the active platform
      var activeK8sInfo = userContextInfo.activePlatformId()
      trackPromise(
        dataService.queries.getPlatformServicesData(activeK8sInfo).then(
          (dataservices) => {
            this.setState({ activePlatformServices: dataservices });
            localStorage.setItem('ActivePlatformServices', JSON.stringify(dataservices));
          }
        )
      )
    }
  }

  componentWillUnmount() {
    i18n.off('languageChanged', this.handleLanguageChange);
  }

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };

  getRoutes = routes => {
    const platformServices = this.state.activePlatformServices;
    const { t } = this.props;

    platformServices.map((service) => {
      if (service.name !== "k8platform" &&
        routes.findIndex(routeElem => routeElem.name === service.name) === -1) {
        return routes.push(
          {
            id: service.id,
            path: "/" + service.name,
            name: service.name,
            class: "",
            icon: service.image,
            component: PlatformService,
            layout: "/portal"
          },
        )
      } else return null;
    })
    return routes.map((prop, index) => {
      if (prop.layout === null ||
        location.pathname === "/portal" ||
        location.pathname === "/portal/") {
        return <Redirect to="/" />
      } else return (
        <Route
          path={prop.layout + prop.path}
          render={() =>
            <ThemeContext.Provider value={this.state.backgroundColor}>
              <prop.component
                i18n={i18n}
                handlePlatformServices={this.handlePlatformServices}
                activePlatformServices={this.state.activePlatformServices}
                kubernetesPlatform={this.state.kubernetesPlatform}
                kubernetesPlatformName={this.state.kubernetesPlatformName}
                kubernetesPlatformId={this.state.kubernetesPlatformId}
                kubernetesState={this.state.kubernetesState}
                kubernetesStateLevel={this.state.kubernetesStateLevel}
                kubeAccessStatus={this.state.kubeAccessStatus}
                kubeConnectStatus={this.state.kubeConnectStatus}
                kubeHealthCheckClass={this.state.kubeHealthCheckClass}
                kubeHealthCheckMessage={this.state.kubeHealthCheckMessage}
                serviceId={prop.id}
                serviceName={prop.name}
                serviceImage={prop.icon}
                bgColor={this.state.backgroundColor}
                hexColor={this.state.hexThemeColor} />
            </ThemeContext.Provider>}
          key={index}
          name={prop.name} />
      );
    },
      routes = []
    );
  };

  handleLanguageChange = (lng) => {
    const { t } = this.props;
    this.setState({
      fallbackPlatformName: i18n.t("kubernetesPlatform.selectPlatform"),
    });
  }

  handleBgClick = color => {
    let hexColor;
    switch (color) {
      case "primary":
        hexColor = "#f5365c";
        break;
      case "second":
        hexColor = "#007bff";
        break;
      case "third":
        hexColor = "#eab000";
        break;
      default:
        break;
    }

    this.setState({
      backgroundColor: color,
      hexThemeColor: hexColor
    });
    localStorage.setItem('ThemeColour', JSON.stringify(color))
  };

  activateMode = mode => {
    switch (mode) {
      case "light":
        document.body.classList.add("white-content");
        this.setState({ theme: "light" });
        localStorage.setItem('Theme', JSON.stringify("light"))
        break;
      default:
        document.body.classList.remove("white-content");
        this.setState({ theme: "dark" });
        localStorage.setItem('Theme', JSON.stringify("dark"))
        break;
    }
    this.handleBgClick(this.state.backgroundColor);
  };

  handlePlatformServices = (
    platformId,
    platformName,
    platformStateLevel,
    platformState,
    platformAccessStatus,
    platformConnectStatus,
    platformHealthClass,
    platformHealthCheckMessage) => {
    this.setState({
      kubernetesPlatformId: platformId,
      kubernetesPlatformName: platformName,
      kubernetesState: platformStateLevel,
      kubernetesStateLevel: platformState,
      kubeAccessStatus: platformAccessStatus,
      kubeConnectStatus: platformConnectStatus,
      kubeHealthCheckClass: platformHealthClass,
      kubeHealthCheckMessage: platformHealthCheckMessage
    })

    updateActivePlatformLS(
      platformId,
      platformName,
      platformStateLevel,
      platformState,
      platformAccessStatus,
      platformConnectStatus,
      platformHealthCheckMessage);

    //Save on the DB the last active platform
    trackPromise(
      dataService.mutations.switchPlatform(platformId)
    )

    //Save the services of the active platform
    trackPromise(
      dataService.queries.getPlatformServicesData(platformId).then(
        (dataservices) => {
          this.setState({ activePlatformServices: dataservices });
          localStorage.setItem('ActivePlatformServices', JSON.stringify(dataservices));
          window.location.reload(true);
        }
      )
    )
  };

  handleActiveCompany = (activeCompanyId) => {
    trackPromise(
      dataService.queries.getKubernetesData(activeCompanyId).then(
        (datakubernetes) => {
          //When switching to a new company by default the first platform [0] is set as active
          this.setState({
            kubernetesPlatform: datakubernetes,
            kubernetesPlatformName: datakubernetes[0].name,
            kubernetesPlatformId: datakubernetes[0].id
          });
          let defaultActivePlatData = {
            PlatformId: datakubernetes[0].id,
            PlatformName: datakubernetes[0].name,
            PlatformStatusLevel: datakubernetes[0].deploymentstateinfo.codeinfolevel,
            PlatformStatus: datakubernetes[0].deploymentstateinfo.codeinfo,
            PlatformAccessStatus: datakubernetes[0].clsaddmon,
            PlatformConnectStatus: datakubernetes[0].connectionstate,
            PlatformHealthCheckClass: (datakubernetes[0].clsaddmon === "ACCESIBLE" &&
              datakubernetes[0].connectionstate === "CONNECTED") ? "running" : "notrunning"
          }
          localStorage.setItem('ActivePlatformData', JSON.stringify(defaultActivePlatData))

          //TODO review it to not require this window refresh when changing the company
          window.location.reload(true);
        }
      )
    )
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="wrapper">
          <MobileOnlyView viewClassName='modal-backdrop show'>
            <section className="mobile-warning-wrapper">
              <span className="mobile-warning-text">
                {i18n.t("common.responsiveWarning")}
              </span>
            </section>
          </MobileOnlyView>
          <Sidebar
            {...this.props}
            routes={routes}
            kubernetesPlatform={this.state.kubernetesPlatform}
            kubernetesPlatformName={this.state.kubernetesPlatformName}
            kubernetesPlatformId={this.state.kubernetesPlatformId}
            bgColor={this.state.backgroundColor}
            toggleSidebar={this.toggleSidebar}
            handlePlatformServices={this.handlePlatformServices}
            handleActiveCompany={this.handleActiveCompany}
            companyId={this.state.companyId}
            fallbackPlatformName={this.state.fallbackPlatformName}
            i18n={i18n} />
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <AdminNavbar
              {...this.props}
              routes={routes}
              logo={{
                outterLink: "#",
                text: i18n.t("common.lainotikPlatform"),
                imgSrc: this.state.theme === "dark" ? Logo : LogoDark
              }}
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={this.state.sidebarOpened}
              companyId={this.state.companyId}
              i18n={i18n}
              LanguageSelector={LanguageSelector}
              handleLanguageChange={this.handleLanguageChange} />
            <Switch>
              {this.getRoutes(routes)}
            </Switch>
            {// Don't want the Footer to be rendered on map page ??
              this.props.location.pathname.indexOf("maps") !== -1 ? null : (
                <FullFooter i18n={i18n} fluid />
              )}
          </div>
        </div>
        <FixedPlugin
          i18n={i18n}
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
          activateMode={this.activateMode}
          LanguageSelector={LanguageSelector} />
      </>
    );
  }
}
export default Admin;
