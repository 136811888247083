/* eslint-disable no-restricted-globals */
import axios from "axios";
import { GRAPH_QL_API_URL, REJECT_UNAUTHORIZED } from "./config.js";
import AuthHeader from "tools/authHeader";
import {
  platformNotifMessages,
  logout
} from "tools/commonFunctions";
import i18n from "localization/i18n";
const https = require('https-browserify')

const LAINOTIK_CLIENTID = "8c431ee6-0702-44b2-b73b-a29b1597ba59"

let authToken = localStorage.getItem('ATk');

export default class httpRequest {

  constructor() {
    this.instance = axios.create({
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Client-Authorization': ''
      },
      httpsAgent: new https.Agent({
        rejectUnauthorized: REJECT_UNAUTHORIZED
      })
    });
    this.getClientToken();
    this.interceptor();
  }

  getAuthToken() {
    return localStorage.getItem('ATk');
  }

  async getClientToken() {

    if (!this.getAuthToken()) {
      try {
        let GET_TOKEN = {
          "query": `mutation {
            getClientToken(
              id: "${LAINOTIK_CLIENTID}"
            )
            {
              backresponse {
                code
                error
                message
                messageinfo {
                  messagelevel
                  messagetitle
                  message
                }
              }
              clientaccesstoken {
                accesstoken
                expirationdate
                token_type
              }
            }
          }`
        }
        let backresponse = await this.instance.post(GRAPH_QL_API_URL, GET_TOKEN);
        let data = backresponse.data.data.getClientToken;
        authToken = data.clientaccesstoken.token_type + " " + data.clientaccesstoken.accesstoken;
        localStorage.setItem('ATk', authToken);

      } catch (error) {
        console.log("Error on the request: " + error.message)
        platformNotifMessages(
          "DANGER",
          i18n.t("common.error"),
          error.message
        )
        setTimeout(() => {
          logout();
        }, 3000);
      }
    }
  }

  interceptor() {
    this.instance.interceptors.response.use(function (response) {
      switch (response.status) {
        case 200:
          if (response.data.data === null && response.data.errors[0].message === "Not Authorised!") {
            platformNotifMessages(
              "DANGER",
              response.data.errors[0].message,
              i18n.t("common.notAuthorised")
            );
            setTimeout(() => {
              logout();
            }, 3000);
          } else console.log("OK 200")
          break;
        default:
          break;
      } return response;

    }, function (error) {
      const response = error.response || {};
      switch (response.status) {
        case 400:
          console.log("BAD REQUEST")
          break;
        case 401:
          //console.log("ERROR 401")
          break;
        case 403:
          //console.log("ERROR 403")
          break;
        case 302:
          //console.log("ERROR 302")
          //window.location = response.data;
          break;
        default:
          return Promise.reject(error);
      }
    });
  }

  async httpPostRequestGraphQL(sendJsonData) {
    return this.instance.post(GRAPH_QL_API_URL, sendJsonData, { headers: AuthHeader(this.getAuthToken()) });
  }
}