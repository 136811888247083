import React from "react";

import {
  Row,
  Col
} from "reactstrap";

export default class OverviewSettings extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
  };

  render() {
    const t = this.t;
    return (
      <Row className="overview-wrapper">
        <Col md="2" className="setup-step">
          <span
            className={`step-number ${this.props.step1Validated ?
              'success' : ''}`}>{t("common.numbers.1")}</span>
          <span
            className={`step-name ${this.props.step1Validated ?
              'success' : ''}`}>{t("kubernetesSettings.overviewSettings.access")}</span>
        </Col>
        <Col md="3" className="setup-separator block-vert-align-middle" />
        <Col md="2" className="setup-step">
          <span
            className={`step-number ${this.props.step2Validated ?
              'success' : ''}`}>{t("common.numbers.2")}</span>
          <span
            className={`step-name ${this.props.step2Validated ?
              'success' : ''}`}>{t("kubernetesSettings.overviewSettings.nodes")}</span>
        </Col>
        <Col md="3" className="setup-separator block-vert-align-middle" />
        <Col md="2" className="setup-step">
          <span
            className={`step-number ${this.props.step3Validated ?
              'success' : ''}`}>{t("common.numbers.3")}</span>
          <span
            className={`step-name ${this.props.step3Validated ?
              'success' : ''}`}>{t("kubernetesSettings.overviewSettings.storage")}</span>
        </Col>
      </Row>
    )
  };
};