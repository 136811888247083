import React from "react";
import { Link } from "react-router-dom";
import * as BackEndConsts from "tools/constants/backEndConstants";
import { dataService } from "tools/dataService/dataService"
import { trackPromise } from "react-promise-tracker";
import {
  handleInputChange,
  platformNotifMessages,
  CustomField
} from "tools/commonFunctions";

import {
  Row,
  Col,
  Card,
  Label,
  Button
} from "reactstrap";

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { withTranslation } from "react-i18next";

import SimpleFooter from "components/Footer/SimpleFooter";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.i18n.t;

    this.state = {
      isPasswordShown: false
    }
  };

  handleInputChangeLoc = (e) => {
    let inputResponse = handleInputChange(e)

    this.setState({
      [inputResponse.name]: inputResponse.value
    })
  };

  handleFormSubmit = (values) => {
    trackPromise(
      dataService.mutations.register(
        values.accountFirstName,
        values.accountLastName,
        values.accountEmail,
        values.accountPassword,
        values.companyCompany
      ).then((createaccountData) => {
        if (createaccountData !== BackEndConsts.BACKEND_RESPONSE_ERROR) {
          platformNotifMessages(
            createaccountData.messagelevel,
            createaccountData.messagetitle,
            createaccountData.message,
            values.accountEmail
          );
          // Continue just if the creation has been successful
          this.props.handleAccountCreation(values.accountEmail);
        } else {
          return null;
        }
      })
    );
  };

  togglePasswordVisibility = () => {
    this.setState({ isPasswordShown: !this.state.isPasswordShown });
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      accountFirstName: Yup.string()
        .required(this.t("signup.signupRegisterForm.formFirstName.requiredMessage"))
        .matches(/^[A-zÀ-ú]+[\s|-]?[A-zÀ-ú]+[\s|-]?[A-zÀ-ú]+$/, this.t("signup.signupRegisterForm.formFirstName.errorMessage")),
      accountLastName: Yup.string()
        .required(this.t("signup.signupRegisterForm.formLastName.requiredMessage"))
        .matches(/^[A-zÀ-ú]+[\s|-]?[A-zÀ-ú]+[\s|-]?[A-zÀ-ú]+$/, this.t("signup.signupRegisterForm.formFirstName.errorMessage")),
      accountEmail: Yup.string()
        .required(this.t("login.loginError.requiredUsername"))
        .email(this.t("signup.signupRegisterForm.formEmail.errorMessage")),
      accountPassword: Yup.string()
        .required(this.t("signup.signupRegisterForm.formPassword.requiredMessage"))
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).\S{8,}$/, this.t("login.loginError.incorrectPassword")),
      companyCompany: Yup.string()
        .required(this.t("signup.signupRegisterForm.formCompany.requiredMessage"))
    });
  }

  render() {
    const t = this.t;

    return (
      <>
        <Row className="justify-content-center align-items-center">
          <Col md="6">
            <div className="content signup-form-wrapper">
              <img
                className="lainotik-logo"
                alt="Lainotik logo"
                src={require('assets/img/condensed-pink.svg').default} />
            </div>
          </Col>
        </Row>
        <div className="signup-form-wrapper">
          <Row className="justify-content-center align-items-center">
            <Col fluid="sm" className="section-header">
              <section className="section-title">
                <h1>{t("signup.signupRegisterForm.title")}</h1>
              </section>
              {/*<section className="section-intro">
                <p dangerouslySetInnerHTML={{ __html: t("signup.signupRegisterForm.introFirstPart", { platform: `<span class="bold">${t("signup.signupRegisterForm.introSecondPart")}</span>` }) }} />
                <p><span className="bold">{t("signup.signupRegisterForm.introThirdPart")}</span> {t("signup.signupRegisterForm.introFourthPart")}</p>
                <p>{t("signup.signupRegisterForm.introFivethPart")} <span className="bold">{t("signup.signupRegisterForm.introSixthPart")}</span>!</p>
              </section>*/}
              <span className="onboarding-nav-link">
                {t("signup.signupRegisterForm.accountQuestion")} <Link to="/">{t("login.loginButton")}</Link>
              </span>
            </Col>
          </Row>
          <Card>
            <Formik
              initialValues={{
                accountFirstName: '',
                accountLastName: '',
                accountEmail: '',
                accountPassword: '',
                companyCompany: ''
              }}
              validationSchema={this.getValidationSchema()}
              onSubmit={(values) => this.handleFormSubmit(values)}>
              {({ ...formikProps }) => (
                <Form>
                  <Row className="justify-content-center align-items-center">
                    <Col sm="12">
                      <fieldset className="form-section">
                        <legend className="subsection-title">{t("signup.signupRegisterForm.formSection1")}</legend>
                        <Label htmlFor="accountfirstname" sm="auto">{t("signup.signupRegisterForm.formFirstName.label")}</Label>
                        <CustomField
                          type="text"
                          id="accountfirstname"
                          name="accountFirstName"
                          className="form-control"
                          placeholder={t("signup.signupRegisterForm.formFirstName.placeHolder")}
                          form={formikProps} />

                        <Label htmlFor="accountlastname" sm="auto">{t("signup.signupRegisterForm.formLastName.label")}</Label>
                        <CustomField
                          type="text"
                          id="accountlastname"
                          name="accountLastName"
                          className="form-control"
                          placeholder={t("signup.signupRegisterForm.formLastName.placeHolder")}
                          form={formikProps} />
                      </fieldset>
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-items-center">
                    <Col sm="12">
                      <fieldset className="form-section">
                        <Label htmlFor="accountemail" sm="auto">{t("login.usernameLabel")}</Label>
                        <CustomField
                          type="email"
                          id="accountemail"
                          name="accountEmail"
                          className="form-control"
                          placeholder={t("login.usernamePlaceholder")}
                          form={formikProps} />
                        <div className="form-password-wrapper">
                          <Label htmlFor="accountpassword" sm="auto">{t("login.passwordLabel")}</Label>
                          <Button className="form-btn-show-password" onClick={(e) => { this.togglePasswordVisibility(e) }}>
                            <i className={this.state.isPasswordShown ? "icon-eye-closed" : "icon-eye"}></i>
                            <div>{this.state.isPasswordShown ? t("common.passwordIcon.hide") : t("common.passwordIcon.show")}</div>
                          </Button>
                        </div>
                        <CustomField
                          type={this.state.isPasswordShown ? "text" : "password"}
                          id="accountpassword"
                          name="accountPassword"
                          className="login-pass form-control"
                          sm="auto"
                          placeholder={t("signup.signupRegisterForm.formPassword.placeHolder")}
                          form={formikProps} />
                      </fieldset>
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-items-center">
                    <Col sm="12">
                      <fieldset className="form-section">
                        <legend className="subsection-title">{t("signup.signupRegisterForm.formSection2")}</legend>
                        <Label htmlFor="companycompany" sm="auto">{t("signup.signupRegisterForm.formCompany.label")}</Label>
                        <CustomField
                          type="text"
                          id="companycompany"
                          name="companyCompany"
                          className="form-control"
                          sm="auto"
                          placeholder={t("signup.signupRegisterForm.formCompany.placeHolder")}
                          form={formikProps} />
                      </fieldset>
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-items-center">
                    <Col sm="12">
                      <Button
                        className="form-btn align-right"
                        type="submit"
                        value="Submit">{t("signup.signupButton")}</Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Card>
          <SimpleFooter />
        </div>
      </>
    )
  }
}
export default withTranslation()(RegisterPage);