import HttpRequest from "tools/httpRequest";
import * as BackEndConsts from "tools/constants/backEndConstants";
import { platformNotifMessages } from "tools/commonFunctions";
import { createQueriesLainotik } from './queriesLainotik';
import { createMutationsLainotik } from './mutationsLainotik';
import i18n from "localization/i18n";

const handleBackEndErrorMessage = (backEndResponse, expectedSuccessCode = BackEndConsts.RESPONSE_SUCCESS) => {
  // Check if the backend response is under the element "response"
  const isResponseFormat = backEndResponse.hasOwnProperty('response');

  const responsePayload = isResponseFormat ? backEndResponse.response : backEndResponse;

  if (responsePayload.code !== expectedSuccessCode) { // Constant with the integer value 200 or 201 when Auth0 interacts; that's expectedSuccessCode second arg for
    let backEndResponseData = BackEndConsts.BACKEND_RESPONSE_ERROR; // Constant with the string value "Error"

    const messageInfo = responsePayload.messageinfo;

    if (messageInfo === null || !messageInfo) {
      platformNotifMessages(
        "DANGER",
        responsePayload.error,
        responsePayload.message
      )
    } else {
      platformNotifMessages(
        messageInfo.messagelevel,
        messageInfo.messagetitle,
        messageInfo.message
      )
    }
    return backEndResponseData;
  } else {
    return backEndResponse;
  }
};

const handleBackEndInternalError = (error) => {
  const resMessage =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();
  platformNotifMessages(
    "DANGER",
    i18n.t("common.commDataErrorTitle"),
    resMessage);
  let backEndResponseData = BackEndConsts.BACKEND_RESPONSE_ERROR; // Constant with the string value "Error"
  return backEndResponseData;
};

const dataServiceFunctions = {
  handleBackEndErrorMessage,
  handleBackEndInternalError
};

const httpClient = new HttpRequest();
const queriesLainotik = createQueriesLainotik(
  httpClient,
  dataServiceFunctions,
  i18n);
const mutationsLainotik = createMutationsLainotik(
  httpClient,
  dataServiceFunctions,
  i18n,
  BackEndConsts);

export const dataService = {
  queries: queriesLainotik,
  mutations: mutationsLainotik
};
